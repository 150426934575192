import { makeStyles } from '@material-ui/core'

const styleLogin = makeStyles( (theme) => ({
	root: {
		background: '#0c0f18',
		width: '100vw',
		height: '100vh',
		display: 'flex',
		alignItems: 'center'
	},

	iconLabs: {
		width: 73,

		'& img': {
			maxWidth: '100%'
		}
	},

	label: {
		'&.name_labs':{
			'& span': {
				fontFamily: 'courier !important'
			}		
		},

		'&.forgot_pass' : {
			cursor: 'pointer',
			'&:hover': {
				textDecoration: 'underline',
			},
		},

		color: '#ff314a'
	},
}))

export default styleLogin