import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
	fullWidth: {
		backgroundColor:"#000",
		maxWidth: 1970,
		padding: 0,
	},

	btnFullscreen: {
		position: 'fixed',
		background: '#b01010',
		color: '#fff',
		right: 25,
		bottom: 25,

		'&:hover': {
			background: '#960d0d',
			color: '#fff',
		}
	},

	containerDash: {
		backgroundColor:"#000",
		height:'100%',
		[theme.breakpoints.up('lg')]: {
			padding: 50,
			paddingTop: 10,
		},
	},

	boxSquare: {
		[theme.breakpoints.up('md')]: {
			// height: 449,

			'& > div': {
				height: '100%',
			}
		}
	},

	paddingBox: {

		[theme.breakpoints.down('sm')]: {
			padding: 10
		},


		[theme.breakpoints.up('md')]: {
			padding: 25
		},
	},

	count: {
		color: '#7f828f',
		fontSize: '2vw'
	}
}))

export default useStyle