import React, { useEffect, useState } from 'react'
import { apiLabs } from '../../../Services/api'

import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@material-ui/core'

import { BackButton, SubmitButton, TitleTable } from '../../Custom'
import useStyles from '../styles'
import { toast } from 'react-toastify'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const FormUser = ({ id }) => {

	const style = useStyles()

	const [showPassword, setShowPassword] = useState(false)

	const [checkeds, setCheckeds] = useState({
		loading: false,
		menus: {}
	})

	const [data, setData] = useState({
		id: 0,
		user: '',
		email: '',
		menus: [],
		password: '',
	})

	const [menus, setMenus] = useState([])


	function handleValues(e) {
		const { name, value } = e.target
		setData({ ...data, [name]: value })
	}

	function handlePermission(e) {
		const checked = e.target.checked
		const { value } = e.target

		const arrChe = checkeds.menus

		const arr = data.menus

		if (checked) {
			if (data.menus.indexOf(value) === -1) {
				arr.push(value)
			}
		} else {
			if (data.menus.indexOf(value) !== -1) {
				arr.splice(data.menus.indexOf(value), 1)
			}
		}

		arrChe['menu-' + value] = checked
		setCheckeds({ ...checkeds, menus: arrChe })
		setData({ ...data, menus: arr })

	}

	function getCheckeds() {
		const arr = checkeds.menus
		if (Object.keys(arr).length > 0) {
			data.menus.map((m) => {
				arr['menu-' + m] = true
			})
		}
		setCheckeds({ loading: true, menus: arr })
	}

	async function getMenus() {
		try {
			const res = await apiLabs.get('menu')

			const menus = res.data.menus

			const arr = new Object()

			menus.map((m) => {
				arr['menu-' + m.id] = false
			})

			setCheckeds({ loading: false, menus: arr })

			setMenus(menus)
		} catch (error) {
			return null
		}
	}

	async function getUser() {
		if (id) {
			const res = await apiLabs.get(`user/${id}`)

			const menus = res.data.menus === "" ? [] : res.data.menus.split(',')

			setData({
				id: res.data.id,
				user: res.data.user,
				email: res.data.email,
				menus: menus,
				password: '',
			})

		}
	}

	async function createEditUser(e) {
		e.preventDefault()

		let strMenus = ''

		data.menus.map((m, i) => {
			if (i < 1) {
				strMenus += m
			} else {
				strMenus += ',' + m
			}
		})

		const obj = {
			id: data.id,
			user: data.user,
			email: data.email,
			menus: strMenus,
			password: data.password
		}


		if (!id) {
			delete obj.id
		}

		if (data.password === "") {
			delete obj.password
		}

		if (id) {
			try {
				await apiLabs.put(`user/${id}`, obj)
				toast.success('Usuário atualizado com sucesso.')
			} catch (error) {
				toast.error('Falha ao editar usuário.')
			}
		} else {
			try {
				await apiLabs.post(`user`, obj)
				toast.success('Usuário cadastrado com sucesso.')
			} catch (error) {
				toast.error('Usuário já cadastrado.')
			}
		}

	}

	useEffect(() => {
		console.log(data)
		
	}, [data])

	useEffect(() => {
		getMenus()
	}, [])

	useEffect(() => {
		getUser()
	}, [id])

	useEffect(() => {
		if (!checkeds.loading) {
			if (data.menus.length > 0) {
				getCheckeds()
			}
		}
	}, [checkeds, data.menus])


	return (
		<form onSubmit={createEditUser}>
			<Grid container spacing={3}>

				<Grid item xs={12}>
					<Box display="flex" justifyContent="center" position="relative">
						<TitleTable>{id ? 'Editar' : 'Novo'} Usuário</TitleTable>
					</Box>
				</Grid>

				<Grid item xs={12}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel htmlFor="user">Usuário</InputLabel>
						<OutlinedInput
							id="user"
							value={data.user}
							name="user"
							label="Usuário"
							onChange={handleValues}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel htmlFor="email">E-mail</InputLabel>
						<OutlinedInput
							id="email"
							value={data.email}
							name="email"
							label="E-mail"
							onChange={handleValues}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel htmlFor="password">Senha</InputLabel>
						<OutlinedInput
							id="password"
							name="password"
							type={showPassword ? 'text' : 'password'}
							onChange={handleValues}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={() => setShowPassword(!showPassword)}
										edge="end"
									>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							}
							label="Senha"
						/>
					</FormControl>

				</Grid>

				<Grid container item xs={12}>
					<FormControl component="fieldset">
						<FormLabel component="legend">Permissões de acesso</FormLabel>
						<FormGroup >
							{menus ? menus.map((menu) => (
								<FormControlLabel
									key={`menu-${menu.name}`}
									control={<Checkbox
										checked={checkeds.menus['menu-' + menu.id]}
										name={menu.name}
										value={menu.id}
										onChange={handlePermission} />
									}
									label={menu.name}
								/>
							)) : null}

							{/* <CheckBoxMenus menus={menus} handlePermission={handlePermission} menusUsers={data.menus} id={id} /> */}
						</FormGroup>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<Box display="flex" justifyContent="flex-end">
						<BackButton component="a" href="/configuracao/usuarios" className={style.m10}>
							cancelar
						</BackButton>
						<SubmitButton type="submit" >{id ? 'Salvar' : 'Cadastrar'}</SubmitButton>
					</Box>
				</Grid>

			</Grid>

		</form>
	)
}

export default FormUser