import React, { useState, useEffect } from 'react';

import { Container, Grid, Hidden, IconButton } from '@material-ui/core'
import useStyle from './style';
import Dashboard from '../../Components/Dashboard';
import { Fullscreen } from '@material-ui/icons';
import history from '../../Services/history';
import { apiLabs, apiAttedance } from '../../Services/api';
import { store } from '../../store';
import Denied from '../../Components/Denied';
import Chart from '../../Components/Chart';


function SacV2(props) {

  const { location: { idPage } } = props

  async function verifyAuth() {
    const { profile } = store.getState().user
    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      return setAuth('authorized')
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }

  const [fullscreen, setFullscreen] = useState(false)
  const [auth, setAuth] = useState(null)

  const [emails, setEmail] = useState([])
  const [whatsface, setWhatsFace] = useState([])
  const [telefone, setTelefone] = useState([])
  const [pedidos, setPedidos] = useState([])

  const [categories, setCategories] = useState([])
  const [data, setData] = useState([])

  const getAttendance = async () => {
    try {
      const atendimento = await apiAttedance.get('atendimento_sete_dias')
      setData(atendimento.data)
      setCategories(atendimento.data.emailsDate)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setEmail([
      {
        name: 'Quantidade de emails',
        data: data.emailsQTD
      },
      {
        name: 'Emails finalizados',
        data: data.emailsFinalizados
      },
      {
        name: 'Emails em progresso',
        data: data.emailsEmProgresso
      }
    ])
  }, [data])

  useEffect(() => {
    setWhatsFace([
      {
        name: 'Quantidade de whats/face',
        data: data.whatsFace
      },
      {
        name: 'Whatsface finalizados',
        data: data.whatsFaceFinalizados,
      },
      {
        name: 'Whatsface em progresso',
        data: data.whatsFaceEmProgresso
      }
    ])
  }, [data])

  useEffect(() => {
    setPedidos([
      {
        name: "Pedidos analizados",
        data: data.pedidosAnalizados
      },
      {
        name: 'Pedidos em Analize',
        data: data.pedidosEmAnalise
      }
    ])
  }, [data])

  useEffect(() => {
    setTelefone([
      {
        name: 'Telefone Pedidos',
        data: data.telefonesPerdidos
      },
      {
        name: 'Telefone Atendidos',
        data: data.telefonesAtendidos
      }
    ])
  }, [data])

  useEffect(() => {
    getAttendance()
  }, [])

  useEffect(() => {
    verifyAuth()
  }, [])

  function requestFull() {
    document.documentElement.requestFullscreen()
  }

  window.addEventListener('resize', function () {
    if (window.screenTop !== 0) {
      setFullscreen(true)
    } else {
      setFullscreen(false)
    }
  })

  const style = useStyle()

  return (
    <>
      <Dashboard title="Sac-v2" fullscreen={fullscreen}>
        {auth === 'denied' && <Denied />}
        {auth === 'authorized' &&
          <>
            <Container className={`${style.fullWidth}`} >
              <Container maxWidth="xl" className={style.container}>

                <Grid className={style.containerGrafics}>
                  <Chart
                    series={emails}
                    categories={categories}
                    title="Emails"
                    charttype="email"
                  />
                  <Chart
                    series={whatsface}
                    categories={categories}
                    title="Whats/face"
                    charttype="whatsface"
                  />
                  <Chart
                    series={pedidos}
                    categories={categories}
                    title="Pedidos"
                    charttype="pedidos"
                  />
                  <Chart
                    series={telefone}
                    categories={categories}
                    title="Telefones"
                    charttype="telefones"
                  />
                </Grid>

              </Container>
            </Container>

            <Hidden mdDown>
              {!fullscreen &&
                <IconButton onClick={requestFull} className={style.btnFullscreen}>
                  <Fullscreen />
                </IconButton>
              }
            </Hidden>

          </>
        }

      </Dashboard>


    </>
  )
}

export default SacV2;
