import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { apiLabs } from '../../../Services/api';

import { Box, FormControl, FormHelperText, Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import { BackButton, SubmitButton } from '../../Custom';
import { toast } from 'react-toastify';

function FormMenu({ data, handleModal, getMenus, setData }) {

	const [menu, setMenu] = useState({
		id: data.id,
		name: data.name,
		slug: data.slug,
	})

	function handlValues(e) {
		const { name, value } = e.target
		setMenu({ ...menu, [name]: value })
	}


	async function requestMenu(e) {
		e.preventDefault()

		const obj = {
			name: menu.name,
			slug: menu.slug.replace(/\s/g, '-').replace(/ç/g, 'c').toLowerCase()
		}

		if (menu.id) {
			try {
				await apiLabs.put(`menu/${menu.id}`, obj)
				toast.success('Menu Alterado com sucesso!')
				getMenus()
			} catch (error) {
				toast.error('Falha ao alterar menu!')
			}
		} else {
			try {
				await apiLabs.post('menu', obj)
				toast.success(`Menu ${menu.name} cadastrado com sucesso!`)
				getMenus()
				setData({
					id: 0,
					nome: '',
					slug: ''
				})
			} catch (error) {
				toast.error('Falha ao cadastrar menu!')
			}
		}
	}

	return (
		<form onSubmit={requestMenu}>
			<Grid container spacing={2}>

				<Grid item xs={12}>
					<h2>{menu.id ? 'Editar' : 'Cadastrar'} Menu</h2>
				</Grid>

				<Grid item xs={12}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel htmlFor="name">Nome</InputLabel>
						<OutlinedInput
							id="name"
							name="name"
							value={menu.name}
							label="Nome"
							onChange={handlValues}
						/>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel htmlFor="slug">Slug</InputLabel>
						<OutlinedInput
							id="slug"
							name="slug"
							value={menu.slug}
							label="Slug"
							onChange={handlValues}
						/>
						<FormHelperText>Sem Acentos e caracteres especiais.</FormHelperText>
					</FormControl>
				</Grid>

				<Grid item xs={12}>
					<Box display="flex" justifyContent="space-between">
						{handleModal && <BackButton size="large" onClick={handleModal}>Cancelar</BackButton>}
						<SubmitButton size="large" bg='#3fd46b' hover='#2cd25d' type="submit">{menu.id ? 'salvar' : 'cadastrar'}</SubmitButton>
					</Box>
				</Grid>
			</Grid>
		</form>
	)
}

FormMenu.propTypes = {
	data: PropTypes.object,
	handleModal: PropTypes.func,
	getMenus: PropTypes.func,
}

export default FormMenu;

