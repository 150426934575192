import { apiMillennium } from '../../api'

const HourYesterday = () => {
	const res = apiMillennium.get('/faturados_ontem')
	return res
}

const HourWeek = async () => {
	const res = await apiMillennium.get('/faturados_semana')
	return res
}

const TotalWeek = async () => {
	const res = await apiMillennium.get('/faturados_total_semana')
	return res
}

const TotalYesterday = async () => {
	const res = await apiMillennium.get('/faturados_total_ontem')
	return res
}


export { HourYesterday, HourWeek, TotalWeek, TotalYesterday }