import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import iconLabsLogin from '../../Assets/img/icon_santolabs_login_v1.png'

const useStyles = makeStyles((theme) => ({
	containerLoading: {
		background: '#0c0f18',
		width: '100%',
		height: '100vh',
	},

	boxImgLoading: {
		width: 50,
		// position: 'relative',

		'& img': {
			maxWidth: '100%'
		}
	},

	progress: {
		color: '#e32c41'
	
	},

}))

function LoadingPage() {

	const style = useStyles()

	return (
		<Box display="flex" justifyContent="center" alignItems="center" className={style.containerLoading}>
			<Box className={style.boxImgLoading}>
				{/* <img src={iconLabsLogin} alt="santolabs" /> */}
				<CircularProgress className={style.progress} />
			</Box>
		</Box>
	);
}

export default LoadingPage;