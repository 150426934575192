import React, { useState, useEffect } from 'react';

import socketIOClient from 'socket.io-client';

import BoxSquare from '../../Components/Boxes/BoxSquare';
import BoxHistory from '../../Components/Boxes/BoxHistory';
import ProgressBar from '../../Components/ProgressBar';

import AwaitHandling from '../../Services/Api/apisExpedition/AwaitHandling';
import AwaitPayment from '../../Services/Api/apisExpedition/AwaitPayment';
import AwaitAnalisys from '../../Services/Api/apisExpedition/AwaitAnalisys';
import AwaitBoarding from '../../Services/Api/apisExpedition/AwaitBoarding';

import BoardingNow from '../../Services/Api/apisExpedition/BoardingNow';
import Goal from '../../Services/Api/apisExpedition/Goal';
import { HourWeek, HourYesterday, TotalYesterday, TotalWeek } from '../../Services/Api/apisExpedition/Handling'

import { Box, Container, Grid, Hidden, IconButton, TextField, Typography } from '@material-ui/core'
import useStyle from './style';
import Dashboard from '../../Components/Dashboard';
import { Fullscreen } from '@material-ui/icons';
import history from '../../Services/history';
import { apiLabs } from '../../Services/api';
import { store } from '../../store';
import Denied from '../../Components/Denied';
import { HandlingNow, HandlingGoalBilling } from '../../Services/Api/apisExpedition/HandlingGoalNow';
import BoxRanking from '../../Components/Boxes/BoxRanking';

const confetti = require('canvas-confetti');
const confetti1 = confetti.create(document.getElementById('confetti'), { resize: true })
const confetti2 = confetti.create(document.getElementById('confetti2'), { resize: true })



function ExpeditionV2(props) {

  const { location: { idPage } } = props

  async function verifyAuth() {
    const { profile } = store.getState().user
    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      return setAuth('authorized')
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }

  const [auth, setAuth] = useState(null)

  const type_user = store.getState().user.profile.name


  useEffect(() => {
    if (auth === 'authorized') {

      const socket = socketIOClient('https://santolabs.com.br')

      socket.on('receivedMessage', (msg) => {

        if (msg === 'Meta Atualizada') {
          getGoal()
        }

        if (msg === 'Faturados Agora Atualizado') {
          getHandlingNow()
          getHourYesterday()
          setHourNow(new Date().getHours())
        }

        if (msg === 'Embarcados agora Atualizado') {
          getBoardingNow()
        }

        if (msg === 'Ag Faturamento Atualizado') {
          getAwaitHandling()
        }

        if (msg === 'Ag Embarque Atualizado') {
          getAwaitBoarding()
        }

        if (msg === 'Ag Pagamento Atualizado') {
          getAwaitPayment()
        }

        if (msg === 'Ag analise Atualizado') {
          getAwaitAnalisys()
        }

      })
    }
  }, [auth])

  const [hourNow, setHourNow] = useState(new Date().getHours())

  const [fullscreen, setFullscreen] = useState(false)

  const [goalSucess, setGoalSucess] = useState('')

  const [time, setTime] = useState({
    min: 1,
    seg: 59
  })

  const [goalProgress, setGoalProgress] = useState({
    total: 0,
    faturados: 0,
  })
  const [goalPrice, setGoalPrice] = useState({
    price: ''
  })

  const [goal, setGoal] = useState({
    total: '',
    label: '',
    goalsucess: '',
    dateFrame: '',
  })

  const [prevision, setPrevision] = useState({
    analisys: 0,
    payment: 0
  })

  const [awaitHandling, setAwaitHandling] = useState({
    count: 0,
    update: '',
  })

  const [handlingNow, setHandlingNow] = useState({
    count: 0,
    update: '',
  })

  const [boardingNow, setBoardingNow] = useState({
    count: 0,
    update: '',
  })

  const [awaitPayment, setAwaitPayment] = useState({
    count: 0,
    update: '',
  })

  const [awaitBoarding, setAwaitBoarding] = useState({
    count: 0,
    update: '',
  })

  const [awaitAnalisys, setAwaitAnalisys] = useState({
    count: 0,
    update: '',
  })


  const [hourWeek, setHourWeek] = useState(0)
  const [hourYesterday, setHourYesterday] = useState(0)
  const [totalYesterday, setTotalYesterday] = useState(0)
  const [totalWeek, setTotalWeek] = useState(0)
  const [porcentHour, setPorcentHour] = useState(0)
  const [porcentWeek, setPorcentWeek] = useState(0)


  const totalFormat = goalProgress.total && goalProgress.total.replace('.', '')
  const billed = goalProgress.faturados > 0 ? goalProgress.faturados : 0
  const goalPercent = parseInt(totalFormat) > 0 ? (Number(billed).toFixed(0) * 100) / parseInt(totalFormat) : 0

  const getHourWeek = async () => {
    const res = await HourWeek()
    if (res.data.length > 0) {
      setHourWeek(res.data[0].faturados)
    }
  }

  const getHourYesterday = async () => {
    const res = await HourYesterday()
    if (res.data.length > 0) {
      setHourYesterday(res.data[0].faturados)
    }
  }

  const getTotalYesterday = async () => {
    const res = await TotalYesterday()
    if (res.data.length > 0) {
      setTotalYesterday(res.data[0].faturados)
    }
  }

  const getTotalWeek = async () => {
    const res = await TotalWeek()
    if (res.data.length > 0) {
      setTotalWeek(res.data[0].faturados)
    }
  }

  const getAwaitHandling = async () => {
    const res = await AwaitHandling()
    if (parseInt(awaitHandling.count) !== res.data[0].ag_faturamento) {
      setAwaitHandling({ count: res.data[0].ag_faturamento, update: res.data[0].update })
    }
  }

  const getHandlingNow = async () => {
    const res = await HandlingNow()
    if (parseInt(handlingNow.count) !== res.data[0].faturados_agora) {
      setHandlingNow({ count: res.data[0].faturados_agora, update: res.data[0].update })
    }
  }

  const getBoardingNow = async () => {
    const res = await BoardingNow()
    if (parseInt(boardingNow.count) !== res.data[0].embarcados_agora) {
      setBoardingNow({ count: res.data[0].embarcados_agora, update: res.data[0].update })
    }
  }

  const getAwaitPayment = async () => {
    const res = await AwaitPayment()
    if (parseInt(awaitPayment.count) !== res.data[0].ag_pagamento) {
      setAwaitPayment({ count: res.data[0].ag_pagamento, update: res.data[0].update })
    }
  }

  const getAwaitAnalisys = async () => {
    const res = await AwaitAnalisys()
    if (parseInt(awaitAnalisys.count) !== res.data[0].ag_analise) {
      setAwaitAnalisys({ count: res.data[0].ag_analise, update: res.data[0].update })
    }
  }

  const getAwaitBoarding = async () => {
    const res = await AwaitBoarding()
    if (parseInt(awaitBoarding.count) !== res.data[0].ag_embarque) {
      setAwaitBoarding({ count: res.data[0].ag_embarque, update: res.data[0].update })
    }
  }

  const getGoal = async () => {
    const res = await Goal('get')
    setGoal({ ...goal, total: res.data[0].meta_faturados, label: res.data[0].meta_faturados })
  }

  function removeSession() {
    if (parseInt(goal.total) !== 0 && goal.total !== '') {
      sessionStorage.removeItem('goalSucess')
    }
  }

  function getPrevisionPayment() {
    setPrevision({ ...prevision, payment: Math.ceil(awaitPayment.count * 0.5) })
  }

  function getPrevisionAnalisys() {
    setPrevision({ ...prevision, analisys: Math.ceil(awaitAnalisys.count * 0.97) })
  }

  async function handleGoal(e) {
    const value = e.target.value
    sessionStorage.removeItem('goalSucess')
    setGoal({ ...goal, label: value })
  }

  async function getCalculatePercent() {
    const res = await HandlingNow()
    setGoalProgress({
      ...goalProgress,
      total: res.data[0].meta_faturamento,
      faturados: res.data[0].v_faturados
    })
  }

  function createPorcents() {
    if (parseInt(hourYesterday) !== 0 && parseInt(handlingNow.count) !== 0) {
      const porcent = Math.ceil(((hourYesterday - handlingNow.count) / hourYesterday) * 100)
      setPorcentHour(porcent)
    }

    if (parseInt(hourWeek) !== 0 && parseInt(handlingNow.count) !== 0) {
      const week = Math.ceil(((hourWeek - handlingNow.count) / hourWeek) * 100)
      setPorcentWeek(week)
    }
  }

  function verifyGoal() {

    if (goalPercent >= 100) {
      if (sessionStorage.getItem('goalPorcent') === null) {
        setGoal({ ...goal, dateFrame: (Date.now() + (120000)) })
        setTimeout(() => {
          sessionStorage.setItem('goalPorcent', true)
        }, 10000)
      }

    }

    if (parseInt(handlingNow.count) !== 0 && parseInt(goal.total) !== 0 && goal.total !== '') {
      if (parseInt(goal.total) - parseInt(handlingNow.count) <= 0) {
        if (sessionStorage.getItem('goalSucess') === null) {
          setGoal({ ...goal, dateFrame: (Date.now() + (120000)) })
          setTimeout(() => {
            sessionStorage.setItem('goalSucess', true)
          }, 10000)
        }
      }
    }
  }

  async function handleGoalHanding(e) {
    if (e.target.value.length > 0) {
      const res = await HandlingGoalBilling(e.target.value)
      setGoalProgress({
        ...goalProgress,
        total: res.data.meta_faturamento === 'NaN' ? 0 : res.data.meta_faturamento
      })
    }
    setGoalPrice({
      price: ''
    })

  }

  function formatPrice(e) {
    var valor = e.target.value;

    valor = valor + "";
    valor = parseInt(valor.replace(/[\D]+/g, ""));
    valor = valor + "";
    valor = valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }

    e.target.value = valor;
    setGoalPrice({
      ...goalPrice,
      price: e.target.value === 'NaN' ? '' : e.target.value
    })
    if (valor == "NaN") e.target.value = "";
  }

  function handleDefaultPrice(e) {
    e.target.value = goalProgress.total
    setGoalPrice({
      ...goalPrice,
      price: e.target.value
    })
  }

  function initFrame() {
    if (goal.dateFrame !== '' || goalPercent >= 100) {
      frame()
    }
  }

  function activePulse() {
    if (parseInt(goal.total) !== 0 && parseInt(handlingNow.count) !== 0) {
      const lack = parseInt(goal.total) - parseInt(handlingNow.count)
      if (lack <= 0) {
        setGoalSucess('boxGoalSucess')
        setInterval(() => {
          setGoalSucess('')
        }, 120000)
        clearInterval(setGoalSucess(''))
      } else {
        setGoalSucess('')
      }
    }
  }

  function frame() {

    const colors = ['#bb0000', '#f8d588'];

    confetti1({
      particleCount: 2,
      angle: 30,
      spread: 100,
      origin: { x: 0 },
      colors: colors
    });

    confetti2({
      particleCount: 2,
      angle: 150,
      spread: 100,
      origin: { x: 1 },
      colors: colors
    });

    if (sessionStorage.getItem('goalSucess') === null || sessionStorage.getItem('goalPercent') === null) {
      if (Date.now() < goal.dateFrame) {
        requestAnimationFrame(frame);
      }
    }
  };

  useEffect(() => {
    if (auth === 'authorized') { createPorcents() }
  }, [handlingNow.count, hourWeek, hourYesterday])

  useEffect(() => {
    if (auth === 'authorized') {
      verifyGoal()
      activePulse()
    }
  }, [handlingNow.count, goal.total])

  useEffect(() => {
    if (auth === 'authorized') { removeSession() }
  }, [goal.total])

  useEffect(() => {
    if (auth === 'authorized') { initFrame() }
  }, [goal.dateFrame])

  useEffect(() => {
    if (auth === 'authorized') { getPrevisionAnalisys() }
  }, [awaitAnalisys.count])

  useEffect(() => {
    if (auth === 'authorized') { getPrevisionPayment() }
  }, [awaitPayment.count])

  useEffect(() => {
    setInterval(() => {
      getCalculatePercent()
    }, 50000)
  }, [])

  useEffect(() => {
    if (auth === 'authorized') {
      verifyGoal()
    }
  }, [goalPercent])

  // useEffect(() => {

  // 	if (parseInt(goalPercent) >= 100) {
  // 		console.log(parseInt(goalPercent))
  // 		if (!sessionStorage.getItem('goalPorcent')) {
  // 			const somPlay = document.getElementById('audio')
  // 			if (somPlay !== undefined) {
  // 				somPlay.play()
  // 					.then(() => {
  // 						 console.log("audio played auto");
  // 					})
  // 					.catch((err) => {

  // 						console.log("playback prevented");
  // 						return err
  // 					});
  // 			}
  // 		}
  // 	}
  // }, [goalPercent])

  useEffect(() => {
    if (auth === 'authorized') {
      getGoal();
      getHandlingNow()
      getAwaitHandling()
      getAwaitPayment()
      getAwaitBoarding()
      getAwaitAnalisys()
      getBoardingNow()
      getHourWeek()
      getHourYesterday()
      getTotalWeek()
      getTotalYesterday()
      getCalculatePercent()
    }

  }, [auth])

  useEffect(() => {
    verifyAuth()
  }, [])


  function requestFull() {
    document.documentElement.requestFullscreen()
  }

  window.addEventListener('resize', function () {
    if (window.screenTop !== 0) {
      setFullscreen(true)
    } else {
      setFullscreen(false)
    }
  })

  const style = useStyle()

  return (
    <>
      <span id="confetti"></span>
      <span id="confetti2"></span>
      <Dashboard title="Expedição-v2" fullscreen={fullscreen}>
        {auth === 'denied' && <Denied />}
        {auth === 'authorized' &&
          <>
            <Container className={`${style.fullWidth} ${style.containerDash}`}>
              <Box style={{ height: 0, width: 0 }}>
                <audio
                  style={{ display: 'none' }}
                  id="audio"
                  src='http://santolabs.com.br/static/media/songGoal.mp3'></audio>
              </Box>
              <Grid container>
                <Grid container item xs={12} md={12}>
                  <Grid item xs={12} md={3} className={`${style.paddingBox} ${style.boxSquare}`}>
                    <BoxSquare
                      title={"Meta Pedidos"}
                      quantity={goal.label}
                      goal={true}
                      bg={'orange'}
                      handlingNow={handlingNow.count}
                      handleGoal={handleGoal}
                      goalSucess={goalSucess}
                    />
                  </Grid>
                  <Grid item xs={6} md={3} className={`${style.paddingBox} ${style.boxSquare}`}>
                    <BoxSquare title={"Ag. Faturamento"} quantity={awaitHandling.count} color={'#3fd46b'} prevision={prevision} />
                  </Grid>
                  <Grid item xs={6} md={3} className={`${style.paddingBox} ${style.boxSquare}`}>
                    <BoxSquare title={"Faturados HOJE"} quantity={handlingNow.count} bg={'#3fd46b'} />
                  </Grid>
                  <Grid item xs={12} md={3} className={`${style.paddingBox} `}>
                    <BoxHistory
                      hourNow={hourNow}
                      hourWeek={hourWeek}
                      hourYesterday={hourYesterday}
                      totalYesterday={totalYesterday}
                      totalWeek={totalWeek}
                      porcentHour={porcentHour}
                      porcentWeek={porcentWeek}
                      handlingNow={handlingNow.count}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className={`${style.paddingBox} ${style.boxSquare}`}>
                    <Box className={`${style.progressBar}`}>
                      <Typography className={`${style.progressBarTitle}`}>Meta de Faturamento</Typography>

                      <ProgressBar
                        goalPercent={goalPercent}
                      />

                      <Box display='flex' justifyContent='flex-end' alignItems='center' width='100%'>
                        <TextField
                          id="price"
                          className={`${style.inputGoal}`}
                          variant="outlined"
                          value={goalPrice.price}
                          onChange={e => setGoalPrice({ price: e.target.value })}
                          onBlur={handleGoalHanding}
                          onKeyUp={formatPrice}
                          onClick={handleDefaultPrice}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6} className={`${style.paddingBox} ${style.boxSquare}`}>
                    <Box className={`${style.progressBar}`}>
                      <BoxRanking />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Container>

            <Hidden mdDown>
              {!fullscreen &&
                <IconButton onClick={requestFull} className={style.btnFullscreen}>
                  <Fullscreen />
                </IconButton>
              }
            </Hidden>

          </>
        }

      </Dashboard>


    </>
  )
}

export default ExpeditionV2;
