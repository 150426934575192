import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({

    containerPrint: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '100vw',
        width: '100vw'
    },

    containerOrders:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    logo:{
        width: '450px',
        marginBottom: '30px',

        '& img':{
            display: 'block',
            width: '100%',
        }
    },

    boxOrder:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& :nth-child(1)':{
            fontSize: '50px',
            marginBottom:'-35px',
            fontWeight: 'bold'
        },

        '& :nth-child(2)':{
            fontSize: '75px',
            fontWeight: 'bold'
        }
    },

    boxPdv:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        '& :nth-child(1)':{
            fontSize: '80px',
            fontWeight: 'bold'
        },

        '& :nth-child(2)':{
            fontSize: '150px',
            marginTop:'-70px',
            fontWeight: 'bold',
        }
    },    

    infoHeader:{
        textAlign: 'center',

        '& :nth-child(1)': {
            fontWeight: 'bold',
            fontSize: '36px',
            marginTop: '-50px',
            marginBottom: '20px',
        },

        '& :nth-child(2), & :nth-child(3), & :nth-child(4)': {
            fontSize: '28px',
            fontWeight: 'bold'
        },

        '& :nth-child(4)': {
            marginBottom: '30px',
        }
    },

    infoFooter:{
        textAlign: 'center',
        position: 'relative',
        bottom: '-20px',

        '& :nth-child(1), & :nth-child(2)': {
            fontSize: '30px',
            fontWeight: 'bold'
        }
    }


}))

export default useStyle