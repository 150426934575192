import { Box, Container, Grid, OutlinedInput, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import BoxRanking from '../../../Components/Boxes/BoxRanking';
import { InputCustom, SubmitButton } from '../../../Components/Custom';
import Dashboard from '../../../Components/Dashboard';
import Denied from '../../../Components/Denied';
import { apiEmbalei, apiLabs } from '../../../Services/api';
import history from '../../../Services/history';
import { store } from '../../../store';
import stylePagesEmbalei from '../style';

function Search(props) {

  const style = stylePagesEmbalei()

  const { location: { idPage } } = props

  const [auth, setAuth] = useState(null)

  const [order, setOrder] = useState('')
  const [orders, setOrders] = useState([])

  async function verifyAuth() {
    const { profile } = store.getState().user
    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      return setAuth('authorized')
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }


  async function getOrders(e) {
    e.preventDefault()

    try {
      const res = await apiEmbalei.get(`orders/${order}`)
      setOrders(res.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    verifyAuth()
  }, [])

  return (
    <Dashboard title="Pesquisa Embalei" className={style.root}>
      {auth === 'denied' && <Denied />}
      {auth === 'authorized' &&
        <>
          <Container maxWidth='md'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography className={style.titlePage}>Buscar operador por pedido</Typography>

                <form onSubmit={getOrders}>
                  <Box className={style.boxFilter}>
                    <InputCustom variant='outlined' className='input-search'>
                      <OutlinedInput
                        onChange={e => setOrder(e.target.value)}
                        placeholder='Digite aqui o número do pedido'
                      />
                    </InputCustom>

                    <SubmitButton className='no-margin' type='submit' >buscar</SubmitButton>
                  </Box>
                </form>
              </Grid>

              <Grid item xs={12}>

                <table className={style.tableEmbalei} border={0} cellSpacing={0}>
                  <thead>
                    <tr>
                      <th align='left'>Cod Pedido</th>
                      <th align='left'>Total itens</th>
                      <th align='left'>Operador</th>
                      <th align='left'>Data</th>
                    </tr>
                  </thead>

                  <tbody>
                    {orders.map((o, i) => (
                      <tr key={`order-${o.id}`}>
                        <td>VTX-{o.num_pedido}</td>
                        <td>{o.total_itens}</td>
                        <td>{o.user.username}</td>
                        <td>{o.beep_date}</td>
                      </tr>
                    ))}
                  </tbody>

                </table>

              </Grid>
            </Grid>
          </Container>
        </>
      }

    </Dashboard>
  )
}

export default Search;
