import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },

  grafic: {
    flex: '1 1 550px',
    // maxWidth: 750,


    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#faf2f2',
    borderRadius: 5,
    boxShadow: '1px 1px 5px 1px #dfdada ',
    margin: 15,

    '& button': {
      padding: 10,
      marginBottom: 10,
      background: '#c9b15f',
      color: '#fff',
      fontWeight: 'bold',
      boxShadow: '1px 1px 5px 1px #dfdada',

      '&:hover': {
        background: '#c3b174'
      }
    },


    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },

  title: {
    textAlign: 'left',
    width: '100%',
    padding: '10px 10px 10px 15px',
    background: '#f0eeee',

    '& p': {
      fontSize: 24,
      fontWeight: 'bold',
    }
  },

  chart: {
    width: '100%',
  },

  modal: {
    maxWidth: 1200,
    width: "100%",
    borderRadius: 5,

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',

    [theme.breakpoints.down('sm')]: {
      width: '95%',
    },

  },

  date: {
    margin: 10,
    padding: 10,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      margin: 0,
      alignItems: 'flex-end',
    },
  },

  search: {
    display: 'flex',
    alignItems: 'center',

    '& .MuiFormControl-root': {
      margin: '10px 15px',
      width: '100%',

      '& label': {
        fontSize: 24,
        fontWeight: 'bold',
        paddingBottom: 10,


      },

      '& input[type="date"]': {
        marginLeft: 10,
        fontSize: 20,
        fontWeight: 'bold',
        width: 250,
      }
    },

    '& > button': {
      width: '100%',
      background: '#63c900',
      color: '#fff',
      fontSize: 20,
      fontWeight: 'bold',

      '&:hover': {
        background: '#6fde03',
      }
    },

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      width: '100%',

      '& .MuiFormControl-root': {
        margin: '10px 0',
        width: '100%',
      },
    },
  },

  close: {
    '& button': {
      padding: '5px 10px',
      margin: '10px 20px 10px 0',
      alignSelf: 'end',
      color: '#fff',
      fontWeight: 'bold',
      background: '#df2323',
      fontSize: 11,

      '&:hover': {
        background: '#e13a3a',
      }
    }
  },

}))

export default useStyle
