import React, { useState } from 'react';

import { Box, Container, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';

import Dashboard from '../../Components/Dashboard';
import { BackButton, IconDelete, IconUpdate, SubmitButton, TableCellHead, TitleTable } from '../../Components/Custom';
import FormMenu from '../../Components/Forms/FormMenu';
import { apiLabs } from '../../Services/api';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { store } from '../../store';
import { Redirect } from 'react-router-dom';
import history from '../../Services/history';
import Denied from '../../Components/Denied';
import LoadingPage from '../../Components/LoadingPage';

function Menu(props) {
	const { location: { idPage } } = props

	async function verifyAuth() {
		const { profile } = store.getState().user
		const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
		if (res.data.message === "User not authorized") {
			return setAuth('denied')
		}
		if (res.data.message === "User authorized") {
			return setAuth('autorized')
		}
		return history.push({ pathname: '/', state: { from: props.location } })
	}

	const [auth, setAuth] = useState(null)

	const [modalMenu, setModalMenu] = useState(false)

	const [modalDelete, setModalDelete] = useState(false)

	const [menus, setMenus] = useState([])

	const [data, setData] = useState({
		id: 0,
		nome: '',
		slug: ''
	})

	function handleModal() {
		if (modalMenu) {
			setData({
				id: 0,
				nome: '',
				slug: ''
			})
		}
		setModalMenu(!modalMenu)
	}

	function handleModalDelete(id) {

		if (modalDelete) {
			setData({
				id: 0,
				nome: '',
				slug: ''
			})
		} else {
			const menuSelect = menus.find(menu => menu.id === id)
			setData({ id: menuSelect.id, name: menuSelect.name, slug: menuSelect.slug })
		}

		setModalDelete(!modalDelete)

	}

	function editMenu(id) {
		const menuSelect = menus.find(menu => menu.id === id)
		setData({ id: menuSelect.id, name: menuSelect.name, slug: menuSelect.slug })
		handleModal()
	}

	async function getMenus() {
		const res = await apiLabs.get('menu')
		setMenus(res.data.menus)
	}

	async function deleteMenu(id) {
		try {
			await apiLabs.delete(`menu/${data.id}`)
			toast.success(`Menu ${data.name} excluido com sucesso`)
		} catch (error) {
			toast.error(`Falha ao excluir ${data.name}`)
		}

	}

	useEffect(() => {
		getMenus()
	}, [auth])

	useEffect(() => {
		verifyAuth()
	}, [])

	return (
		<>
			<Dashboard title="Menu">
				{auth === 'denied' && <Denied />}

				{auth === 'autorized' && <Container maxWidth="md">
					<Box display="flex" justifyContent="space-between" paddingBottom={3} paddingTop={3} >
						<TitleTable>Lista de Menus</TitleTable>

						<SubmitButton startIcon={<Add />} size="large" onClick={handleModal}>
							Adicionar Menu
					</SubmitButton>
					</Box>

					<Paper style={{ borderRadius: 5, overflow: 'hidden' }}>
						<TableContainer>
							<Table stickyHeader >
								<TableHead>
									<TableRow>
										<TableCellHead>ID</TableCellHead>
										<TableCellHead>Nome</TableCellHead>
										<Hidden mdDown>
											<TableCellHead>SLUG</TableCellHead>
										</Hidden>
										<TableCellHead align="center">Opções</TableCellHead>
									</TableRow>
								</TableHead>

								<TableBody>
									{menus.map(menu => (
										<TableRow key={`menu-${menu.id}`} >
											<TableCell width={80}>{menu.id}</TableCell>
											<TableCell>{menu.name}</TableCell>
											<Hidden mdDown>
												<TableCell>{menu.slug}</TableCell>
											</Hidden>
											<TableCell width={150}>
												<IconUpdate onClick={() => editMenu(menu.id)}>
													<Edit />
												</IconUpdate>

												<IconDelete onClick={() => handleModalDelete(menu.id)}>
													<Delete />
												</IconDelete>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>

				</Container>
				}
			</Dashboard>

			<Dialog
				open={modalMenu}
				onClose={e => setModalMenu(!modalMenu)}
			>
				<DialogContent style={{ padding: 20 }}>
					<FormMenu data={data} setData={setData} handleModal={handleModal} getMenus={getMenus} />
				</DialogContent>
			</Dialog>

			<Dialog open={modalDelete} onClose={handleModalDelete}>
				<DialogTitle>Excluir Menu</DialogTitle>

				<DialogContent>
					Deseja realmente excluir o menu {data.name} ?
						</DialogContent>

				<DialogActions>
					<BackButton onClick={() => handleModalDelete()}>cancelar</BackButton>
					<SubmitButton onClick={() => deleteMenu(data.id)}>Excluir</SubmitButton>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default Menu;