import React from 'react';
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
// import Skeleton from '@material-ui/lab/Skeleton';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box, Typography } from '@material-ui/core';
import useStyleBox from '../style';
import { memo } from 'react';
import Goal from '../../../Services/Api/apisExpedition/Goal';

function BoxSquare(props) {
	const { title, quantity, goal, goalSucess, handlingNow, handleGoal, bg, color, prevision } = props

	const styleProps = {
		bg: bg,
		color: color,
	}

	const style = useStyleBox(styleProps);
	const locationUrl = useLocation().pathname
	function createHtmlLack() {
		if (goal) {
			const lack = quantity - handlingNow
			if (lack <= 0) {
				return (
					<Typography className={style.lack}>
						<span>Meta Batida!</span>
					</Typography>
				)
			} else {
				return (
					<Typography className={style.lack}>
						<span>faltam:</span>
						<span>{lack}</span>
					</Typography>
				)
			}
		}
	}

	return (
		<Box className={`${style.root} ${goal ? style.boxGoal : style.boxSquare} ${goalSucess}`}>
			<Typography className={style.title}>{title}</Typography>
			<Box className={style.values}>
				{!goal
					? quantity !== null ?
						<Box>
							<Typography className={style.quantity}>{quantity}</Typography>
							{title === 'Ag. Faturamento' &&
								<Box display='flex' justifyContent='center' alignItems='center' className={`${style.previsionBox}`} >
									{locationUrl === '/dashboard/expedicao-v2' &&
										<>
											<Typography>Previsão: </Typography>
											<Typography className={style.prevision}>(+{prevision.payment + prevision.analisys})</Typography>
										</>
									}
								</Box>}

						</Box> : <CircularProgress className={style.progress} />
					: <input type="text" value={quantity} className={`${style.inputGoal} ${style.quantity}`} onChange={handleGoal} onBlur={e => Goal('put', e.target.value)} />
				}
				{createHtmlLack()}
			</Box>
		</Box>
	)
}

const configStyle = {
	bg: '#fff',
	color: '#271e1e'
}

BoxSquare.propTypes = {
	title: PropTypes.string,
	quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	handlingNow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	goal: PropTypes.bool,
	handleGoal: PropTypes.func,
	goalSucess: PropTypes.string,
	prevision: PropTypes.object,
}

BoxSquare.defaultProps = configStyle

export default memo(BoxSquare)