import React, { useState, useEffect } from 'react'

import { Container } from '@material-ui/core'

import Dashboard from '../../Components/Dashboard'
import FormUser from '../../Components/Forms/FormUser'
import Denied from '../../Components/Denied'
import { store } from '../../store'
import { apiLabs } from '../../Services/api'
import history from '../../Services/history'

const PageFormUser = ({ match, ...props }) => {

	const { location: { idPage } } = props

	async function verifyAuth() {
		const { profile } = store.getState().user
		const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
		if (res.data.message === "User not authorized") {
			return setAuth('denied')
		}
		if (res.data.message === "User authorized") {
			return setAuth('authorized')
		}
		return history.push({ pathname: '/', state: { from: props.location } })
	}

	const [auth, setAuth] = useState(null)

	const [id, setId] = useState(false)

	function getId() {
		if (match.params.id) {
			setId(match.params.id)
		}
	}

	useEffect(() => {
		if (auth === 'authorized') {
			getId()
		}
	}, [auth])

	useEffect(() => {
		verifyAuth()
	}, [])

	return (
		<Dashboard title="Formulário usuário">

			{ auth === 'denied' && <Denied /> }

			{ auth === 'authorized' &&
				<Container maxWidth="sm">
					<FormUser id={id} />
				</Container>
			}
		</Dashboard>
	)
}

export default PageFormUser