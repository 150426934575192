import React from 'react';
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core';
import useStyleBox from '../style';
import { memo } from 'react';

function BoxAnalisys(props) {
	const { titles, quantitys, bg, color, prevision } = props

	const styleProps = {
		bg: bg,
		color: color,
	}

	const style = useStyleBox(styleProps);

	return (
		<Box className={`${style.root} ${style.boxAnalisys}`}>
			<Box className={style.boxAwaits}>
				<Typography className={style.title}>{titles[0]}</Typography>
				<Box className={style.values} display='flex' justifyContent='center' alignItems='center'>
					<Typography className={style.quantity}>{quantitys[0]}</Typography>
					<Typography className={style.prevision}>({prevision.analisys})</Typography>
				</Box>
			</Box>
			<Box className={style.boxAwaits}>
				<Typography className={style.title}>{titles[1]}</Typography>
				<Box className={style.values} display='flex' justifyContent='center' alignItems='center' >
					<Typography className={style.quantity}>{quantitys[1]}</Typography>
					<Typography className={style.prevision}>({prevision.payment})</Typography>
				</Box>
			</Box>

			<Box className={style.boxPrevision}>
				<Typography className="text">Previsão:</Typography>
				<Typography className="value">{prevision.payment + prevision.analisys}</Typography>
			</Box>
		</Box>
	)
}

const configStyle = {
	bg: '#fff',
	color: '#271e1e'
}

BoxAnalisys.propTypes = {
	titles: PropTypes.array,
	quantitys: PropTypes.array,
}

BoxAnalisys.defaultProps = configStyle

export default memo(BoxAnalisys)