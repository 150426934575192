import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga';
import createStore from './createStore';

import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';
import persistReducers from './persistReducers';

import thunk from 'redux-thunk'
import { connectRouter, routerMiddleware } from 'connected-react-router';
import history from '../Services/history';

const sagaMiddleware = createSagaMiddleware(); //{ sagaMonitor }

const middlewares = [sagaMiddleware, thunk, routerMiddleware(history) ];

const store = createStore(connectRouter(history)(persistReducers(rootReducer)), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };