import { makeStyles } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'
import { he } from 'date-fns/locale'

const useStyle = makeStyles((theme) => ({

    fullWidth: {
        backgroundColor: "#fafafa",
        maxWidth: 1970,
        padding: 0,
    },

    btnFullscreen: {
        position: 'fixed',
        background: '#b01010',
        color: '#fff',
        right: 25,
        bottom: 25,

        '&:hover': {
            background: '#960d0d',
            color: '#fff',
        }
    },


    attendanceContainer: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        flexWrap: 'wrap',
        padding: 5,
    },

    attendanceContent: {
        display: "flex",
        alignItems: "center",
        flexDirection: 'column',
        maxWidth: 540,
        height: 310,
        width: '100%',
        background: '#010e67',
        borderRadius: 5,
        margin: 5,
        padding: 5,

        '& .MuiListItemIcon-root': {
            width: 50
        },

        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
        },
    },

    textField: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(2),
        width: 200,
    },


    Total: {
        fontSize: 42,
        fontWeight: 'bold',
    },

    box: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-around',
        flexDirection: 'column',
        flex: '1 1 240px',
        height: '100%',
        background: '#fff',
        borderRadius: 5,
        padding: 25,
        margin: 25,
        boxShadow: '0px 0px 10px 0px #70707033',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },



        '& span': {
            border: '1px solid #cecece',
            width: '100%',
            borderRadius: 5,
            margin: '10px 0',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },

            [theme.breakpoints.down('sm')]: {
                display: 'block',
                margin: 25
            },



        },
    },

    boxContent: {
        maxHeight: 190,
        minHeight: 190,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        margin: 10,
        textAlign: 'center',
    },


    title: {
        textAlign: 'center',
        color: 'blue',
        fontSize: 20,
        fontWeight: 'bold',
    },

    content: {
        color: 'blue',
        fontSize: 80,
        fontWeight: "bold",
    },

    colorBoxBackgroundGreen: {
        background: 'rgb(63, 212, 107)'
    },
    colorTextWhite: {
        color: '#fff !important',
        fontSize: 20,
        fontWeight: 'bold',
    },

    contentTextWhite:{
        color: '#fff !important',
        fontSize: 80,
        fontWeight: "bold",
    }
}))

export default useStyle