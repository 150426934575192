import { makeStyles } from "@material-ui/core";

const styleManagerSku = makeStyles((theme) => ({
  root: {
    padding: '30px 20px',

    '& .input_registration_date': {

      '& .MuiOutlinedInput-root': {
        borderRadius: 10,

        '&.Mui-focused': {
          '& fieldset': {
            borderColor: '#000'
          }
        }
      },

      '& label.Mui-focused': {
        color: '#000'
      }
    },

    '& .color-vtex': {
      color: 'rgb(247, 25, 99)'
    },

    '& .color-blue': {
      color: 'rgb(0 110 185)'
    },

    '& .MuiDialog-paperWidthSm': {
      margin: 0,
      // height: '100%',
      maxHeight: '100% !important',
    }
  },

  boxBtnFilters: {
    '& button': {
      marginLeft: 10,
    }
  },

  inittial_table: {
    marginTop: 50,
  },

  inputPerPage: {
    width: 100,
  },

  imgProduct: {
    maxWidth: 120,
    height: 120,
    '& img': {
      maxWidth: '100%',
      height: '100%'
    },

    [theme.breakpoints.down('xs')]: {
      '& img': {
        width: 40,
      }
    }
  },

  rowProduct: {
    '& td, td p': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      }
    }
  },

  linkProduct: {
    color: '#000',
    textDecoration: 'none',

    [theme.breakpoints.up('sm')]: {
      pointerEvent: 'none'
    }
  },

  modalProduct: {
    maxWidth: 600,

    '& .box-modal': {

      '& p': {
        margin: '5px 0',
        textTransform: 'capitalize'
      },

      '&--name': {
        fontWeight: 'bold',
        fontSize: '5vw',
      },

      '&--registration': {
        fontSize: '3.5vw',
        textTransform: 'lowercase !important',
      }
    }
  },

  boxTableProducts: {
    [theme.breakpoints.down('xs')]: {
      width: '89vw',
      overflow: 'hidden',

      '& .th-cod': {
        minWidth: 110,
      },

      '& .th-date': {
        minWidth: 150,
      },

      '& .th-name': {
        minWidth: 225,
      },
    }
  },
}))

export default styleManagerSku
