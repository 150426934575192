import React, { useState, useEffect } from 'react';

import { Box, Button, Container, Grid, Hidden, IconButton, TextField, Typography } from '@material-ui/core'
import useStyle from './style';
import Dashboard from '../../Components/Dashboard';
import { Fullscreen } from '@material-ui/icons';
import history from '../../Services/history';
import { apiLabs, apiAttedance } from '../../Services/api';
import { store } from '../../store';
import Denied from '../../Components/Denied';

import { toast } from 'react-toastify'

function Attendance(props) {

  const { location: { idPage } } = props

  async function verifyAuth() {
    const { profile } = store.getState().user
    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      return setAuth('authorized')
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }

  const [fullscreen, setFullscreen] = useState(false)
  const [auth, setAuth] = useState(null)
  const [form, setForm] = useState({
    emails_qtd: '',
    emails_pendent_date: "",
    emails_pendent_time: "",
    emails_in_progress: '',
    emails_finished: '',
    whatsface_qtd: '',
    whatsface_pedent_date: "",
    whatsface_pedent_time: "",
    whatsface_in_progress: '',
    whatsface_finished: '',
    orders_analisys: '',
    orders_analyzed: '',
    phones_answers: '',
    phones_lost: ''
  })

  function handleChange({ target }) {
    const { name, value } = target

    setForm({
      ...form, [name]: value
    })
  }

  const clearForm = () => {
    setForm({
      emails_qtd: '',
      emails_pendent_date: "",
      emails_pendent_time: "",
      emails_in_progress: '',
      emails_finished: '',
      whatsface_qtd: '',
      whatsface_pedent_date: "",
      whatsface_in_progress: '',
      whatsface_finished: '',
      whatsface_pedent_time: "",
      orders_analisys: '',
      orders_analyzed: '',
      phones_answers: '',
      phones_lost: ''
    })
  }


  async function handleSubmit(e) {
    e.preventDefault()
    if (auth === 'authorized') {
      try {
        await apiAttedance.post('/novo', form)
        toast.success('Dados cadrastados com sucesso')
        clearForm()
      } catch (error) {
        console.log(error)
      }

    }
  }

  useEffect(() => {
    verifyAuth()
  }, [])


  function requestFull() {
    document.documentElement.requestFullscreen()
  }

  window.addEventListener('resize', function () {
    if (window.screenTop !== 0) {
      setFullscreen(true)
    } else {
      setFullscreen(false)
    }
  })

  const style = useStyle()

  return (
    <>
      <Dashboard title="Atendimento" fullscreen={fullscreen}>
        {auth === 'denied' && <Denied />}
        {auth === 'authorized' &&
          <>
            <Container className={`${style.fullWidth} ${style.containerAttendance}`} >
              <form onSubmit={handleSubmit}>
                <Grid container item xs={12} className={`${style.container}`}>
                  {/* <Typography className={style.title}>Adicionar Fechamento do dia</Typography> */}
                  <Grid item xs={12} className={`${style.content}`}>
                    <Typography className={`${style.contentTitle}`}>Emails</Typography>
                    <Box className={`${style.contentBox}`}>
                      <TextField
                        name='emails_qtd'
                        value={form.emails_qtd}
                        placeholder="Quantidade de emails"
                        onChange={handleChange}
                        variant="outlined"
                        required
                      />
                      <Box display="flex" maxWidth={310} width="100%">
                        <TextField
                          name='emails_pendent_date'
                          value={form.emails_pendent_date}
                          type="date"
                          onChange={handleChange}
                          variant="outlined"
                          className={style.date}
                          required
                        />
                        <TextField
                          id="time"
                          name="emails_pendent_time"
                          type="time"
                          value={form.emails_pendent_time}
                          className={style.time}
                          variant="outlined"
                          onChange={handleChange}
                          required
                        />
                      </Box>

                    </Box>
                    <Box className={`${style.contentBox}`}>
                      <TextField
                        name='emails_in_progress'
                        value={form.emails_in_progress}
                        onChange={handleChange}
                        placeholder="Emails em andamento"
                        variant="outlined"
                        required
                      />
                      <TextField
                        name='emails_finished'
                        value={form.emails_finished}
                        onChange={handleChange}
                        placeholder="Emails finaliados"
                        variant="outlined"
                        required
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={`${style.content}`}>
                    <Typography className={`${style.contentTitle}`}>Whatsapp/Face</Typography>
                    <Box className={`${style.contentBox}`}>
                      <TextField
                        name='whatsface_qtd'
                        value={form.whatsface_qtd}
                        onChange={handleChange}
                        placeholder="Quantidade de whatsapp/fabebook"
                        variant="outlined"
                        required
                      />
                      <Box display="flex" maxWidth={310} width="100%">
                        <TextField
                          name='whatsface_pedent_date'
                          value={form.whatsface_pedent_date}
                          type="date"
                          onChange={handleChange}
                          variant="outlined"
                          className={style.date}
                          required
                        />
                        <TextField
                          id="time"
                          name="whatsface_pedent_time"
                          value={form.whatsface_pedent_time}
                          type="time"
                          className={style.time}
                          variant="outlined"
                          onChange={handleChange}
                          required
                        />
                      </Box>
                    </Box>
                    <Box className={`${style.contentBox}`}>
                      <TextField
                        name='whatsface_in_progress'
                        value={form.whatsface_in_progress}
                        onChange={handleChange}
                        placeholder="Whatsapp/Facebook em andamento"
                        variant="outlined"
                        required
                      />
                      <TextField
                        name='whatsface_finished'
                        value={form.whatsface_finished}
                        onChange={handleChange}
                        placeholder="Whatsapp/Facebook finaliados"
                        variant="outlined"
                        required
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={`${style.content}`}>
                    <Typography className={`${style.contentTitle}`}>Konduto</Typography>
                    <Box className={`${style.contentBox}`}>
                      <TextField
                        name='orders_analisys'
                        value={form.orders_analisys}
                        onChange={handleChange}
                        placeholder="Pedidos em analise"
                        variant="outlined"
                        required
                      />
                      <TextField
                        name='orders_analyzed'
                        value={form.orders_analyzed}
                        onChange={handleChange}
                        placeholder="Pedidos analizados"
                        variant="outlined"
                        required
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={`${style.content}`}>
                    <Typography className={`${style.contentTitle}`}>Telefone</Typography>
                    <Box className={`${style.contentBox}`}>
                      <TextField
                        name='phones_answers'
                        value={form.phones_answers}
                        onChange={handleChange}
                        placeholder="Atendidos"
                        variant="outlined"
                        required
                      />
                      <TextField
                        name='phones_lost'
                        value={form.phones_lost}
                        onChange={handleChange}
                        placeholder="Perdidos"
                        variant="outlined"
                        required
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12} className={`${style.content}`}>
                    <Button className={`${style.button}`} type="submit" >Salvar</Button>
                  </Grid>
                </Grid>
              </form>
            </Container>

            <Hidden mdDown>
              {!fullscreen &&
                <IconButton onClick={requestFull} className={style.btnFullscreen}>
                  <Fullscreen />
                </IconButton>
              }
            </Hidden>

          </>
        }

      </Dashboard>


    </>
  )
}

export default Attendance;
