import React, { useState, useEffect } from "react"
import $ from 'jquery'

import { Box, Container, Dialog, DialogActions, DialogContent, Grid, Hidden, IconButton, InputLabel, MenuItem, OutlinedInput, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core"
import { ArrowDropDown, ArrowDropUp, Close, Delete, SearchOutlined } from "@material-ui/icons"

import Dashboard from "../../Components/Dashboard"
import Denied from "../../Components/Denied"
import { InputCustom, SubmitButton, TableCellHead } from "../../Components/Custom"
import not_image from '../../Assets/img/not_image.png'

import { apiLabs, apiManagerSku } from "../../Services/api"
import history from "../../Services/history"
import { store } from "../../store"

import { format, parse } from "date-fns"

import styleManagerSku from "./style"
import InputsFilter from "../../Components/InputsFilter"
import { toDate } from "date-fns/esm"

const ManagerSku = (props) => {
  const { location: { idPage } } = props
  const { name } = store.getState().user.profile
  const [orderBy, setOrderBy] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [btnFilter, setBtnFilter] = useState(false)
  const [productModal, setProductModal] = useState(null)
  const [auth, setAuth] = useState(null)
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState({
    options: [
      {
        type: 'sel',
        condition: '=',
        value: ''
      }
    ]
  })
  const [timeSearch, setTimeSearch] = useState()

  const [products, setProducts] = useState({
    count: 0,
    page: 1,
    pages: 0,
    limit: 10,
    nextUrlPage: null,
    prevUrlPage: null,
    items: [],
    notFound: false
  })

  const style = styleManagerSku()

  async function verifyAuth() {
    const { profile } = store.getState().user
    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      return setAuth('authorized')
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }

  function handleSearch(e) {
    const value = e.target.value
    clearTimeout(timeSearch)
    if (value.length > 3) {
      setTimeSearch(
        setTimeout(() => {
          setSearch(value)
        }, 1500)
      )
    }

  }

  function handleFilter(e, i) {
    const array = filter.options
    const { value, name } = e.target
    array[i][name] = value

    if (name === 'type') {
      array[i].condition = '='
      array[i].value = ''

      if (value === 'registration_date') {
        array[i].value = new Date()
      }

      setSearch('')
    }

    setFilter({
      ...filter,
      options: array
    })
  }

  function handleDate(e, i) {
    const array = filter.options
    array[i].value = e

    setFilter({
      ...filter,
      options: array
    })
  }

  function addFilter() {
    const array = filter.options
    array.push({
      type: 'sel',
      condition: '=',
      value: ''
    })

    setFilter({
      ...filter,
      options: array
    })
  }

  function removeFilter(index) {
    const array = filter.options
    array.splice(index, 1)

    setFilter({
      ...filter,
      options: array
    })
  }

  function handleOrdination(type) {
    if (orderBy === null) {
      setOrderBy(`&order=${type} DESC`)
    } else if (orderBy.indexOf(`DESC`) !== -1) {
      setOrderBy(`&order=${type} ASC`)
    } else if (orderBy.indexOf(`ASC`) !== -1) {
      setOrderBy(`&order=${type} DESC`)
    }
  }

  async function searchProduct() {
    try {
      const p = await apiManagerSku.get(`product/search?text=${search}&limit=${products.limit}&page=${products.page}${orderBy !== null ? orderBy : ''}`)
      setProducts({
        count: p.data.count,
        page: parseInt(p.data.page),
        pages: p.data.pages,
        limit: p.data.limit,
        items: p.data.items
      })
    } catch (error) {
      setProducts({
        count: 0,
        page: 1,
        pages: 0,
        limit: 10,
        nextUrlPage: null,
        prevUrlPage: null,
        items: [],
        notFound: true
      })
    }
  }

  async function filterProduct() {
    setSearch('')
    const qr = []

    filter.options.map((f) => {

      if (f.type === 'sel') {
        return
      }

      if (f.type === 'image_url') {
        if (f.value === 'sim') {
          return qr.push("image_url != 'not_image.png'")
        } else if(f.value === 'foradelinha') {
          return qr.push("image_url LIKE '%foradelinha%'")
        }  else {
          return qr.push("image_url = 'not_image.png'")
        }
      }

      if (f.type === 'sku_id') {
        if (f.value === 'inativo') {
          return qr.push("sku_id = 0")
        } else {
          return qr.push("sku_id != 0")
        }
      }

      if (f.type === 'registration_date') {
        return qr.push(`DATE(${f.type}) ${f.condition} '${format(f.value, 'yyyy-MM-dd')}'`)
      }

      if (f.type === 'stock_vtex' || f.type === 'stock_blue') {
        if (f.condition === '<>') {
          const values = f.value.split('-')
          if (values.length > 1) {
            return qr.push(`${f.type} >= ${values[0]} AND ${f.type} <= ${values[1]}`)
          } else {
            return qr.push(`${f.type} >= 0`)
          }

        } else {
          return qr.push(`${f.type} ${f.condition} ${f.value}`)
        }
      }

      return qr.push(`${f.type} ${f.condition} '${f.value}'`)
    })

    try {
      const p = await apiManagerSku.get(`product/filter?qr=${qr.join(' AND ')}&limit=${products.limit}&page=${products.page}${orderBy !== null ? orderBy : ''}`)
      setProducts({
        count: p.data.count,
        page: parseInt(p.data.page),
        pages: p.data.pages,
        limit: p.data.limit,
        items: p.data.items
      })
    } catch (error) {
      setProducts({
        count: 0,
        page: 1,
        pages: 0,
        limit: 10,
        nextUrlPage: null,
        prevUrlPage: null,
        items: [],
        notFound: true
      })
    }
  }

  function handleModal(p) {
    setProductModal(p)
    setOpenModal(!openModal)
  }

  function handleLimit(e) {
    const limit = e.target.value
    setProducts({
      ...products,
      limit
    })
  }

  function handleNext(e) {
    $('html, body').animate(
      {
        scrollTop: $('#table').offset().top
      },
      'slow'
    )

    setProducts({
      ...products,
      page: parseInt(products.page + 1)
    })
  }

  function handlePrev() {
    $('html, body').animate(
      {
        scrollTop: $('#table').offset().top
      },
      'slow'
    )

    setProducts({
      ...products,
      page: parseInt(products.page - 1)
    })
  }

  function handlePage() {
    if (search !== '') {
      return searchProduct()
    }

    if (filter.options[0].type !== 'sel') {
      return filterProduct()
    }
  }

  function clearFilter() {
    if (filter.options[0].type !== 'sel') {
      setFilter({
        options: [
          {
            type: 'sel',
            condition: '=',
            value: ''
          }
        ]
      })

      setProducts({
        count: 0,
        page: 1,
        pages: 0,
        limit: 10,
        nextUrlPage: null,
        prevUrlPage: null,
        items: [],
        notFound: false
      })
    }
  }

  useEffect(() => {
    handlePage()
  }, [products.page, products.limit])

  useEffect(() => {
    if (orderBy !== null) {
      handlePage()
    }
  }, [orderBy])

  useEffect(() => {
    if (search) {
      searchProduct(search)
    }
  }, [search])

  useEffect(() => {
    if (filter.options[0].type !== 'sel' && filter.options[0].value !== '') {
      setBtnFilter(true)
    } else {
      setBtnFilter(false)
    }
  }, [filter])

  useEffect(() => {
    verifyAuth()
  }, [])

  return (
    <Dashboard title="Separa Cascas">
      {auth === 'denied' && <Denied />}
      {auth === 'authorized' &&
        <>
          <Container maxWidth="xl" className={style.root}>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputCustom fullWidth variant="outlined">
                  <OutlinedInput
                    defaultValue={search}
                    onChange={handleSearch}
                    placeholder={`Olá ${name}, o que voce procura ?`}
                    startAdornment={<SearchOutlined />}
                  />
                </InputCustom>
              </Grid>

              <Grid item xs={12}>
                <Typography align='center'>OU</Typography>
              </Grid>


              {filter.options.map((f, i) => (
                <Grid container item xs={12} key={i} spacing={2}>
                  <Grid item xs={11} sm={4} md={3} lg={2}>
                    <InputCustom variant="outlined" fullWidth>
                      <InputLabel id="type_filter">Tipo de filtro</InputLabel>
                      <Select
                        labelId="type_filter"
                        id="type_filter"
                        label="Tipo de filtro"
                        value={f.type}
                        name={'type'}
                        onChange={e => handleFilter(e, i)}
                      >
                        <MenuItem value={'sel'} disabled>Selecione um filtro</MenuItem>
                        <MenuItem value={'image_url'}>Imagem do produto</MenuItem>
                        <MenuItem value={'registration_date'}>Data de Cadastro</MenuItem>
                        <MenuItem value={'stock_vtex'}>Estoque VTEX</MenuItem>
                        <MenuItem value={'stock_blue'}>Estoque Bluesoft</MenuItem>
                        <MenuItem value={'sku_id'}>SKU</MenuItem>
                        <MenuItem value={'status_blue'}>Status Bluesoft</MenuItem>
                        <MenuItem value={'status_vtex'}>Status VTEX</MenuItem>
                      </Select>
                    </InputCustom>
                  </Grid>

                  <Hidden smUp>
                    {i > 0 &&
                      <Grid item xs={1} sm={1}>
                        <IconButton onClick={e => removeFilter(i)}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    }
                  </Hidden>

                  <InputsFilter handleFilter={handleFilter} handleDate={handleDate} type={f.type} index={i} value={f.value} condition={f.condition} />

                  <Hidden xsDown>
                    {i > 0 &&
                      <Grid item xs={1} sm={1}>
                        <IconButton onClick={e => removeFilter(i)}>
                          <Delete />
                        </IconButton>
                      </Grid>
                    }
                  </Hidden>


                </Grid>
              ))}

              <Grid item xs={12}>
                <Box className={style.boxBtnFilters} display='flex' justifyContent='space-between'>
                  <Box>
                    <SubmitButton bg='#f45252' hover='#d81717' onClick={clearFilter}>limpar</SubmitButton>
                  </Box>
                  <Box display='flex'>
                    <SubmitButton bg='#f39c12c4' hover='#f39c12' onClick={addFilter}>Adicionar filtro</SubmitButton>
                    <SubmitButton bg='#2ecc71cf' hover='#2ecc71' onClick={filterProduct} disabled={!btnFilter}>Filtrar</SubmitButton>
                  </Box>
                </Box>
              </Grid>


              <Grid item xs={12} className={style.inittial_table}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                  <Typography>{products.count} produtos encontrados</Typography>
                  <InputCustom variant="outlined" className={style.inputPerPage}>
                    <InputLabel id="limit">Por página</InputLabel>
                    <Select
                      labelId="limit"
                      id="limit"
                      value={products.limit}
                      onChange={handleLimit}
                      label="Por página"
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </InputCustom>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className={style.boxTableProducts}>
                  <Paper style={{ borderRadius: 5, overflow: 'hidden' }} id="table">
                    <TableContainer>
                      <Table stickyHeader >
                        <TableHead>
                          <TableRow>
                            <TableCellHead className={'th-foto'}>Foto</TableCellHead>
                            <TableCellHead className={'th-cod'}>Cód. produto</TableCellHead>
                            <TableCellHead className={'th-name'}>
                              <p>
                                Nome <span className={'btn_ordination'} onClick={e => handleOrdination('name')}><ArrowDropUp /> <ArrowDropDown /></span>
                              </p>
                            </TableCellHead>
                            <TableCellHead className={'th-stock'}>
                              <p>
                                Estoque <span className={'btn_ordination'} onClick={e => handleOrdination('stock_vtex')} ><ArrowDropUp /> <ArrowDropDown /></span>
                              </p>
                            </TableCellHead>
                            <TableCellHead className={'th-status'}>
                              <p>
                                Status <span className={'btn_ordination'} onClick={e => handleOrdination('status_vtex')}><ArrowDropUp /> <ArrowDropDown /></span>
                              </p>
                            </TableCellHead>
                            <TableCellHead className={'th-sku'}>SKU</TableCellHead>
                            <TableCellHead className={'th-date'}>
                              <p>
                                Data cadastro <span className={'btn_ordination'} onClick={e => handleOrdination('registration_date')}><ArrowDropUp /> <ArrowDropDown /></span>
                              </p>
                            </TableCellHead>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {products.items.map(p => (
                            <TableRow key={`product-${p.id}`} className={style.rowProduct} >
                              <TableCell className={style.imgProduct}>
                                <img src={p.image_url !== 'not_image.png' ? p.image_url : not_image} alt="" />
                              </TableCell>
                              <TableCell>{p.product_key}</TableCell>
                              <TableCell>
                                <Hidden smUp>
                                  <a href="#product" className={style.linkProduct} onClick={e => handleModal(p)} >
                                    {p.name}
                                  </a>
                                </Hidden>
                                <Hidden xsDown>
                                  {p.name}
                                </Hidden>
                              </TableCell>
                              <TableCell>
                                <Typography className={`color-vtex`}>{p.stock_vtex}</Typography>
                                <Typography className={`color-blue`}>{p.stock_blue}</Typography>
                              </TableCell>
                              <TableCell>
                                <Typography className={`color-vtex`}>{p.status_vtex}</Typography>
                                <Typography className={`color-blue`}>{p.status_blue}</Typography>
                              </TableCell>
                              <TableCell>{parseInt(p.sku_id) !== 0 ? p.sku_id : <Close />}</TableCell>
                              <TableCell>{format(new Date(p.registration_date) , 'dd/MM/yyyy')}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display='flex' justifyContent={products.page > 1 ? 'space-between' : 'flex-end'}>
                  {products.page > 1 &&
                    <SubmitButton bg='#f39c12c4' hover='#f39c12' onClick={handlePrev}>anterior</SubmitButton>
                  }

                  {products.page < products.pages &&
                    <SubmitButton bg='#f39c12c4' hover='#f39c12' onClick={handleNext}>próximo</SubmitButton>
                  }

                </Box>
              </Grid>

              {products.notFound &&
                <Grid item xs={12}>
                  <Typography align='center'>Nenhum produto encontrado!</Typography>
                </Grid>
              }
            </Grid>
          </Container>

          <Dialog
            open={openModal}
            onClose={e => setOpenModal(!openModal)}
            className={style.root}
          >
            <DialogContent className={style.modalProduct}>
              {productModal !== null &&
                <Box display='flex' flexDirection='column' alignItems='center' className='box-modal'>
                  <Box display='flex' justifyContent='center' className='box-modal--image' >
                    <img src={productModal.image_url !== 'not_image.png' ? productModal.image_url : not_image} alt="" />
                  </Box>

                  <Typography align="center" className='box-modal--name'>{productModal.name}</Typography>

                  <Typography className='box-modal--ref-id'>Cod. Interno {productModal.product_key}</Typography>
                  <Typography className={`color-vtex box-modal--sku`}>SKU {productModal.sku_id}</Typography>

                  <Typography className={`color-vtex box-modal--status-vtex`}>{productModal.status_vtex} na VTEX </Typography>
                  <Typography className={`color-vtex box-modal--stock-vtex`}>Estoque VTEX {productModal.stock_vtex}</Typography>

                  <Typography className={`color-blue box-modal--status-blue`}>{productModal.status_blue} na Bluesoft </Typography>
                  <Typography className={`color-blue box-modal--stock-blue`}>Estoque Bluesoft {productModal.stock_blue}</Typography>

                  <Typography className={`box-modal--registration`}>Produto cadastrado em {format(new Date(productModal.registration_date), 'dd/MM/yyy')}</Typography>

                </Box>
              }
            </DialogContent>
            <DialogActions>
              <SubmitButton onClick={e => setOpenModal(!openModal)} bg='#f39c12c4' hover='#f39c12'>Fechar</SubmitButton>
            </DialogActions>
          </Dialog>
        </>
      }
    </Dashboard >
  )

}

export default ManagerSku
