import { makeStyles } from "@material-ui/core";

const styleConfigFullGoals = makeStyles((theme) => ({
  root: {

  },

  titleForm: {
    fontSize: 28,
    fontWeight: 'bold',
    textAlign: 'center',
    margin: '15px 0'
  },

}))

export default styleConfigFullGoals
