import React, { useState, useEffect } from 'react';
import 'date-fns';
import { ptBR } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { DateTime } from "luxon";

import FilterList from '@material-ui/icons/FilterList';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import BoxSquare from '../../Components/Boxes/BoxSquare';

import SentMail from '../../Services/Api/apisAbandonedCart/SentMail';
import OrderCanceled from '../../Services/Api/apisAbandonedCart/OrderCanceled';
import OrderPendding from '../../Services/Api/apisAbandonedCart/OrderPendding';
import OrderApproved from '../../Services/Api/apisAbandonedCart/OrderApproved';
import RecoverValue from '../../Services/Api/apisAbandonedCart/RecoverValue';





import { Box, Container, Grid, Hidden, IconButton } from '@material-ui/core'
import useStyle from './style';
import Dashboard from '../../Components/Dashboard';
import { Fullscreen } from '@material-ui/icons';
import history from '../../Services/history';
import { apiLabs } from '../../Services/api';
import { store } from '../../store';
import Denied from '../../Components/Denied';
import TextField from '@material-ui/core/TextField';

function AbandonedCart(props) {
    const { location: { idPage } } = props
    // data atual
    const dt = DateTime.local();
    // data atual - 10 dias
    const dtOld = dt.minus({ days: 30 });
    const [date, setDate] = useState({
        de: dtOld.toISODate(),
        ate: dt.toISODate()
    });

    const [sentMail, setSentMail] = useState({
        count: null,
    })

    const [orderCanceled, setorderCanceled] = useState({
        count: null,
    })

    const [orderPendding, setorderPendding] = useState({
        count: null,
    })

    const [orderApproved, setorderApproved] = useState({
        count: null,
    })

    const [orderValue, setorderValue] = useState({
        count: null,
    })

    const [auth, setAuth] = useState(null)

    async function verifyAuth() {
        const { profile } = store.getState().user
        const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
        if (res.data.message === "User not authorized") {
            return setAuth('denied')
        }
        if (res.data.message === "User authorized") {
            return setAuth('authorized')
        }
        return history.push({ pathname: '/', state: { from: props.location } })
    }

    useEffect(() => {
        console.log(orderPendding)
        verifyAuth()
    }, [])


    function moeda(num) {
        var intl = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL"
        });
        var valor = num;
        var moeda = intl.format((valor / 100));
        return moeda
    }


    const handleChange = (e) => {
        const { name, value } = e.target
        setDate({ ...date, [name]: value })
    }


    const filterDashboard = async () => {

        setSentMail({ ...sentMail, count: null })
        setorderCanceled({ ...setorderCanceled, count: null })
        setorderPendding({ ...setorderPendding, count: null })
        setorderApproved({ ...setorderApproved, count: null })
        setorderValue({ ...setorderValue, count: null })


        const res = await SentMail(date)
        const canceled = await OrderCanceled(date)
        const pendding = await OrderPendding(date)
        const approved = await OrderApproved(date)
        const value = await RecoverValue(date)

        setSentMail({ ...sentMail, count: res.data.qtd })
        setorderCanceled({ ...setorderCanceled, count: canceled.data.canceled })
        setorderPendding({ ...setorderPendding, count: pendding.data.pendding })
        setorderApproved({ ...setorderApproved, count: approved.data.approved })
        setorderValue({ ...setorderValue, count: value.data.recoverValue })


    }

    useEffect(() => {
        filterDashboard()
    }, [])

    const style = useStyle()

    return (
        <>
            <Dashboard title="Carrinho Abandonado" >
                {auth === 'denied' && <Denied />}
                {auth === 'authorized' &&
                    <>
                        <Container maxWidth='xl'>

                            <Grid container>
                                <Grid container item xs={12} md={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>

                                        <Box component="div" m={1} className={`${style.paddingBox} ${style.div} ${style.boxSquare}`}>
                                            <Box component="div" >

                                                <TextField className={style.inputMargin} id="outlined-basic" label="Data de início" InputLabelProps={{
                                                    shrink: true,
                                                }} variant="outlined" type="date" name='de' value={date.de} onChange={handleChange} />

                                                <TextField id="outlined-basic" label="Data de Termino" InputLabelProps={{
                                                    shrink: true,
                                                }} variant="outlined" type="date" name='ate' value={date.ate} onChange={handleChange} />

                                            </Box>
                                            <Button
                                                variant="contained"
                                                onClick={filterDashboard}
                                                className={`${style.ButtonFilter}`}
                                                startIcon={<FilterList />}
                                            >
                                                Filtrar
                                        </Button>
                                        </Box>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid container item xs={12} md={12}>

                                    <Grid item xs={12} md={3} className={`${style.paddingBox} ${style.boxSquare}`}>
                                        <BoxSquare title={"Emails Enviados"} quantity={sentMail.count} color={'#3c78ff'} />
                                    </Grid>

                                    <Grid item xs={12} md={3} className={`${style.paddingBox} ${style.boxSquare}`}>
                                        <BoxSquare title={"Pedidos Cancelados"} quantity={orderCanceled.count} color={'#b01010'} />
                                    </Grid>

                                    <Grid item xs={12} md={3} className={`${style.paddingBox} ${style.boxSquare}`}>
                                        <BoxSquare title={"Pedidos Pendentes"} quantity={orderPendding.count} color={'orange'} />
                                    </Grid>

                                    <Grid item xs={12} md={3} className={`${style.paddingBox} ${style.boxSquare}`}>
                                        <BoxSquare title={"Pedidos Recuperados"} quantity={orderApproved.count} color={'green'} />
                                    </Grid>

                                    <Grid item xs={12} md={6} className={`${style.paddingBox} ${style.boxSquare}`}>

                                        {orderValue.count != null ? (
                                            <BoxSquare title={"Valor Recuperado"} quantity={moeda(orderValue.count)} color={'green'} />
                                        ) : (
                                                <BoxSquare title={"Valor Recuperado"} quantity={orderValue.count} color={'green'} />
                                            )}

                                    </Grid>

                                </Grid>

                            </Grid>
                        </Container>



                    </>
                }

            </Dashboard>

        </>
    )
}

export default AbandonedCart;