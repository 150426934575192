import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
    fullWidth: {
        backgroundColor: "#fafafa",
        maxWidth: 1970,
        padding: 0,
    },

    btnFullscreen: {
        position: 'fixed',
        background: '#b01010',
        color: '#fff',
        right: 25,
        bottom: 25,

        '&:hover': {
            background: '#960d0d',
            color: '#fff',
        }
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        height: 450,
        width: 450,
    },

    modalDelete: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    paperDelete: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        height: 290,
        width: 550,
    },

    containerEbook: {
        margin: '0 auto',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    containerTable: {
        maxWidth: 1600,
        width: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        padding: 10,
    },

    containeForm: {
    },

    table: {
        minWidth: 750,
        width: '100%',

        '& .MuiTableHead-root': {
            background: '#b01010',
        },

        '& .MuiTableBody-root > .MuiTableRow-root:nth-child(odd)': {
            background: '#f8eded'
        },

        '& .MuiTableBody-root > .MuiTableRow-root:nth-child(even)': {
            background: '#fff'
        },

        '& span': {
            display: 'flex',
            maxWidth: 500,
            width: '100%',
            lineBreak: 'anywhere',
        }
    },

    text: {
        fontSize: 18,
        fontWeight: 600,
        whiteSpace: 'wrap',
    },

    textHeader: {
        fontWeight: 'bold',
        fontSize: 20,
        color: '#fff'
    },

    filterSearch: {
        width: '100%',
        paddingTop: 20,
        display: 'flex',
        justifyContent: 'center',

        '& .MuiButton-contained': {
            width: 150,
            margin: 5,
            fontSize: 15,
            fontWeight: 'bold',
            color: '#fff',
            background: '#c03030',

            '&:hover': {
                background: '#b01010'
            }
        },
    },

    search: {
        maxWidth: 450,
        width: '100%',
        margin: 5,
    },

    select: {
        maxWidth: 300,
        width: '100%',
        margin: 5,
    },

    form: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 5,

        '& > .MuiFormControl-root.MuiTextField-root': {
            width: '100%',
            margin: 8,
        },

        '& .MuiSelect-outlined.MuiSelect-outlined': {
            width: 200,
        },

    },

    formFiles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 200,

        // '& :nth-child(1)': {

        // },

        // '& :nth-child(2)': {

        // }
    },

    buttons: {
        display: 'flex',

        '& button': {
            width: '100%',
            margin: '0 3px',
            height: 50,
            fontSize: 20,
            fontWeight: 'bold',
            color: '#fff',
        },
        '& button:nth-child(1)': {
            background: '#5de587',
            '&:hover': {
                background: '#4cc46f',
            },
        },

        '& button:nth-child(2)': {
            background: '#bc2222',
            '&:hover': {
                background: '#cd4e4e',
            },
        },




    },

    edit: {
        fontSize: 28,
        color: '#b01010',

        '&:hover': {
            background: '#fafafa',
            borderRadius: '50%',
            padding: 2,
        },
        '&:nth-child(even):hover': {
            background: '#f8eded'
        }
    },

    delete: {
        fontSize: 28,
        color: '#b01010',

        '&:hover': {
            background: '#fafafa',
            borderRadius: '50%',
            padding: 2,
        },
        '&:nth-child(even):hover': {
            background: '#f8eded'
        }
    },

    imageEbook: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 8,
    },

    previewImg: {
        width: 120,
        "& img": {
            width: '100%',
            display: 'block',
        }
    },

    error: {
        width: '100%',
        fontSize: 30,
        fontWeight: 'bold',
        padding: 50,
        textAlign: 'center'
    },

    deleteModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 40,

        '& > h2:nth-child(1)': {
            fontSize: 30,
            paddingBottom: 25,
        },

        '& > h2:nth-child(2)': {
            fontSize: 25,
            paddingBottom: 25,
        },
    },

    buttonsDelete: {
        width: '100%',
        display: 'flex',

        '& button': {
            fontSize: 20,
            fontWeight: 'bold',
            margin: 10,
            padding: '15px 0',
            borderRadius: 5,
            border: 'none',
            outline: 'none',
            color: '#fff',

            '&:nth-child(1)': {
                width: '100%',
                background: '#b01010',

                '&:hover': {
                    background: '#9b2626',
                }
            },

            '&:nth-child(2)': {
                width: '100%',
                background: '#9eb827',

                '&:hover': {
                    background: '#a19837'
                }
            }
        }
    },

    selectQuantaty: {
        flex: 1,
        alingSelf: 'center',
        marginBottom: 20,
        maxWidth: 120,
        width: '100%',
    },

    pagination: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',

        '& button': {
            width: 120,
            padding: 10,
            borderRadius: 5,
            border: 'none',
            outline: 'none',
            fontSize: 16,
            fontWeight: 'bold',
            color: '#fff',
            background: '#b01010',
            marginTop: 10,

            '&:hover':{
                background: '#9f3636'
            }
        }
    },

    currentPage:{
        width: '100%',
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
        padding: '10px 0',
    }


}))

export default useStyle