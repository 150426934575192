import React, { useState } from 'react';

import iconLabsLogin from '../../Assets/img/icon_santolabs_login_v1.png'
import { InputLabs, Santolabs, SubmitButton } from '../../Components/Custom';
import { Box, Container, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from '@material-ui/core';

import styleLogin from './style';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import * as Actions from '../../store/modules/Auth/actions'
import { connect, useDispatch } from 'react-redux';

const Login = ({ signInRequest }) => {

	const style = styleLogin()
	const dispatch = useDispatch()

	const [showPassword, setShowPassword] = useState(false)
	const [data, setData] = useState({
		user: '',
		password: ''
	})

	const handleValues = (e) => {
		const { name, value } = e.target
		setData({ ...data, [name]: value })
	}

	async function signIn(e) {
		e.preventDefault()
		dispatch(signInRequest( data.user, data.password ))
	}


	return (
		<>
			<Container maxWidth={false} className={style.root}>

				<Container maxWidth="lg">
					<form onSubmit={signIn}>
						<Grid container justify="center" >
							<Grid container item xs={12} sm={7} md={5} lg={4} spacing={2}>

								<Grid item xs={12}>
									<Box display="flex" alignItems="center" flexDirection="column">
										<Box className={style.iconLabs}>
											<img src={iconLabsLogin} alt="" />
										</Box>
										<Santolabs fontSize={50} >
											<span>santo</span><span>Labs</span><span>^</span>
										</Santolabs>
									</Box>
								</Grid>

								<Grid item xs={12}>
									<InputLabs variant="outlined" fullWidth>
										<InputLabel htmlFor="user">Usuário</InputLabel>
										<OutlinedInput
											id="user"
											label="Usuário"
											name='user'
											onChange={handleValues}
										/>
									</InputLabs>
								</Grid>

								<Grid item xs={12}>
									<InputLabs variant="outlined" fullWidth>
										<InputLabel htmlFor="password">Senha</InputLabel>
										<OutlinedInput
											id="password"
											type={showPassword ? 'text' : 'password'}
											name="password"
											onChange={handleValues}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														onClick={e => setShowPassword(!showPassword)}
														edge="end"
													>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
											label="Senha"
										/>
									</InputLabs>
								</Grid>

								{/* <Grid item xs={12}>
									<Typography align="right">
										<span className={`${style.label} forgot_pass`} >Esqueceu a senha?</span>
									</Typography>
								</Grid> */}

								<Grid item xs={12}>
									<SubmitButton size="large" type="submit" fullWidth>entrar</SubmitButton>
								</Grid>

							</Grid>
						</Grid>
					</form>

				</Container>

			</Container>
		</>
	)
}

export default connect(
	null,
	Actions
)(Login);