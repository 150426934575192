import axios from 'axios'

const apiMillennium = axios.create({
	baseURL: 'https://api.teste.santolabs.com.br/api/dasboard/millennium'
})

const apiLabs = axios.create({
	baseURL: 'https://api.teste.santolabs.com.br/api/dasboard/'
})

const apiManagerSku = axios.create({
  baseURL: 'https://api.teste.santolabs.com.br/api/econsulta/'
})

const apiEmbalei = axios.create({
  baseURL: 'https://api.teste.santolabs.com.br/api/embalei/'
})

const apiAttedance = axios.create({
  baseURL: 'https://api.teste.santolabs.com.br/api/dasboard/atendimento/'
})

const apiEbook = axios.create({
  baseURL: 'https://api.teste.santolabs.com.br/api/cursos/ebook/'
})

const apiSpreadsheet = axios.create({
  baseURL: 'https://api.teste.santolabs.com.br/api/v2/vtex/retiraja'
})

const apiTelesales = axios.create({
  baseURL: 'https://api.teste.santolabs.com.br/api/v2/telesales'
})

const apiOffers = axios.create({
  baseURL: 'https://api.teste.santolabs.com.br/api/v2/offers'
})

export { apiMillennium , apiLabs, apiManagerSku, apiEmbalei, apiAttedance, apiEbook, apiSpreadsheet, apiTelesales, apiOffers }
