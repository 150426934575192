import { Box, Container, FormControl, Grid, Typography } from "@material-ui/core"
import React, { useState, useEffect } from "react"
import { IconShell } from "../../../Components/Custom"
import Dashboard from "../../../Components/Dashboard"
import Denied from "../../../Components/Denied"
import { apiLabs } from "../../../Services/api"
import history from "../../../Services/history"
import { store } from "../../../store"
import styleDashShells from "./style"

import { ptBR } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import format from "date-fns/format"

const pricesShells = {
  mini: 66.99,
  p_leite: 66.99,
  m_leite: 66.99,
  g_leite: 66.99,
  m_amargo: 66.99,
  m_branco: 74.99,
  m_belga: 102.99,
}

const formatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const DashboardShells = (props) => {
  const { location: { idPage } } = props

  const [auth, setAuth] = useState(null)
  const [time, setTime] = useState()
  const [date, setDate] = useState(new Date())
  const [priceTotal, setPriceTotal] = useState(0)
  const [data, setData] = useState({
    g_leite: 0,
    m_amargo: 0,
    m_belga: 0,
    m_branco: 0,
    m_leite: 0,
    mini: 0,
    p_leite: 0,
    totalPago: 0,
    totalPedidosFeitos: 0,
    totalcaixas: 0,
  })

  const style = styleDashShells()

  async function verifyAuth() {
    const { profile } = store.getState().user
    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      return setAuth('authorized')
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }

  async function getShellsInfos() {
    try {
      const res = await apiLabs.get(`/separa/painel_cascas/pedidos${date !== '' ? `?date=${format(date, 'yyyy-MM-dd')}` : ''}`)
      setData({
        g_leite: res.data.g_leite === null ? 0 : res.data.g_leite,
        m_amargo: res.data.m_amargo === null ? 0 : res.data.m_amargo,
        m_belga: res.data.m_belga === null ? 0 : res.data.m_belga,
        m_branco: res.data.m_branco === null ? 0 : res.data.m_branco,
        m_leite: res.data.m_leite === null ? 0 : res.data.m_leite,
        mini: res.data.mini === null ? 0 : res.data.mini,
        p_leite: res.data.p_leite === null ? 0 : res.data.p_leite,
        totalPago: res.data.totalPago === null ? 0 : res.data.totalPago,
        totalPedidosFeitos: res.data.totalPedidosFeitos === null ? 0 : res.data.totalPedidosFeitos,
        totalcaixas: res.data.totalcaixas === null ? 0 : res.data.totalcaixas,
      })
      setPriceTotal(
        (res.data.g_leite * pricesShells.g_leite) +
        (res.data.m_amargo * pricesShells.m_amargo) +
        (res.data.m_belga * pricesShells.m_belga) +
        (res.data.m_branco * pricesShells.m_branco) +
        (res.data.m_leite * pricesShells.m_leite) +
        (res.data.mini * pricesShells.mini) +
        (res.data.p_leite * pricesShells.p_leite)
      )
    } catch (err) {
      console.log(err)
    }
  }

  function timeGetShells() {
    setTime(setInterval( async () => {
      const day = new Date()
      if (day.getHours() >= 7 && day.getHours() <= 19) {
        await getShellsInfos()
      }
    }, 10000))
  }

  function handleDate(e) {
    setDate(e)
  }

  useEffect(() => {
    if (auth === 'authorized') {
      getShellsInfos()
    }
  }, [auth])

  useEffect(() => {
    getShellsInfos()
    const dataSel = format(date ,'dd/MM/yyyy')
    const dataAtual = format(new Date ,'dd/MM/yyyy')

    if(dataSel === dataAtual){
      timeGetShells()
    } else {
      clearInterval(time)
    }
  }, [date])

  useEffect(() => {
    verifyAuth()
  }, [])

  return (
    <Dashboard title="Separa Cascas">
      {auth === 'denied' && <Denied />}
      {auth === 'authorized' &&
        <>
          <Container maxWidth="xl" className={style.root} >
            <Grid container spacing={2}>
              <Grid container item xs={12}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label="Date picker inline"
                      inputVariant={"outlined"}
                      value={date}
                      onChange={e => handleDate(e)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className={style.boxShell}>
                  <Typography align="left" className='title_box' >
                    <span className='title_box_icon'>
                      <IconShell />
                    </span>
                    <span className='title_box_text'>Total de caixas</span>
                  </Typography>

                  <Typography align="center" className='total_box'>
                    <span className='total_box_value'>{data.totalPago}</span>
                    <span className='total_box_text'>( pagos )</span>
                  </Typography>

                  <hr className='separate' />

                  <Typography align="right" className='price_box'>
                    <span className='price_box_value'>+ {formatter.format(priceTotal)}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className={style.boxShell}>
                  <Typography align="left" className='title_box' >
                    <span className='title_box_icon'>
                      <IconShell />
                    </span>
                    <span className='title_box_text'>Mini</span>
                  </Typography>

                  <Typography align="center" className='total_box'>
                    <span className='total_box_value'>{data.mini}</span>
                    <span className='total_box_text'>( pagos )</span>
                  </Typography>

                  <hr className='separate' />

                  <Typography align="right" className='price_box'>
                    <span className='price_box_value'>+ {formatter.format(pricesShells.mini * data.mini)}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className={style.boxShell}>
                  <Typography align="left" className='title_box' >
                    <span className='title_box_icon'>
                      <IconShell />
                    </span>
                    <span className='title_box_text'>P ao Leite</span>
                  </Typography>

                  <Typography align="center" className='total_box'>
                    <span className='total_box_value'>{data.p_leite}</span>
                    <span className='total_box_text'>( pagos )</span>
                  </Typography>

                  <hr className='separate' />

                  <Typography align="right" className='price_box'>
                    <span className='price_box_value'>+ {formatter.format(pricesShells.p_leite * data.p_leite)}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className={style.boxShell}>
                  <Typography align="left" className='title_box' >
                    <span className='title_box_icon'>
                      <IconShell />
                    </span>
                    <span className='title_box_text'>M ao Leite</span>
                  </Typography>

                  <Typography align="center" className='total_box'>
                    <span className='total_box_value'>{data.m_leite}</span>
                    <span className='total_box_text'>( pagos )</span>
                  </Typography>

                  <hr className='separate' />

                  <Typography align="right" className='price_box'>
                    <span className='price_box_value'>+ {formatter.format(pricesShells.m_leite * data.m_leite)}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className={style.boxShell}>
                  <Typography align="left" className='title_box' >
                    <span className='title_box_icon'>
                      <IconShell />
                    </span>
                    <span className='title_box_text'>G ao Leite</span>
                  </Typography>

                  <Typography align="center" className='total_box'>
                    <span className='total_box_value'>{data.g_leite}</span>
                    <span className='total_box_text'>( pagos )</span>
                  </Typography>

                  <hr className='separate' />

                  <Typography align="right" className='price_box'>
                    <span className='price_box_value'>+ {formatter.format(pricesShells.g_leite * data.g_leite)}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className={style.boxShell}>
                  <Typography align="left" className='title_box' >
                    <span className='title_box_icon'>
                      <IconShell />
                    </span>
                    <span className='title_box_text'>M Amargo</span>
                  </Typography>

                  <Typography align="center" className='total_box'>
                    <span className='total_box_value'>{data.m_amargo}</span>
                    <span className='total_box_text'>( pagos )</span>
                  </Typography>

                  <hr className='separate' />

                  <Typography align="right" className='price_box'>
                    <span className='price_box_value'>+ {formatter.format(pricesShells.m_amargo * data.m_amargo)}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className={style.boxShell}>
                  <Typography align="left" className='title_box' >
                    <span className='title_box_icon'>
                      <IconShell />
                    </span>
                    <span className='title_box_text'>M Branco</span>
                  </Typography>

                  <Typography align="center" className='total_box'>
                    <span className='total_box_value'>{data.m_branco}</span>
                    <span className='total_box_text'>( pagos )</span>
                  </Typography>

                  <hr className='separate' />

                  <Typography align="right" className='price_box'>
                    <span className='price_box_value'>+ {formatter.format(pricesShells.m_branco * data.m_branco)}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Box className={style.boxShell}>
                  <Typography align="left" className='title_box' >
                    <span className='title_box_icon'>
                      <IconShell />
                    </span>
                    <span className='title_box_text'>M Belga</span>
                  </Typography>

                  <Typography align="center" className='total_box'>
                    <span className='total_box_value'>{data.m_belga}</span>
                    <span className='total_box_text'>( pagos )</span>
                  </Typography>

                  <hr className='separate' />

                  <Typography align="right" className='price_box'>
                    <span className='price_box_value'>+ {formatter.format(pricesShells.m_belga * data.m_belga)}</span>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </>
      }
    </Dashboard >
  )

}

export default DashboardShells
