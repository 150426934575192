import { Container, FormControl, Grid, InputLabel, OutlinedInput } from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { SubmitButton, TitleTable } from '../../../Components/Custom';
import Dashboard from '../../../Components/Dashboard';
import { apiEmbalei, apiLabs } from '../../../Services/api';
import history from '../../../Services/history';
import { store } from '../../../store';

function MetaEmbalei(props) {

  const { location: { idPage } } = props

  const [goal, setGoal] = useState(0)
  const [auth, setAuth] = useState(null)

  async function verifyAuth() {
    const { profile } = store.getState().user
    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      return setAuth('authorized')
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }

  function handleValue(e) {
    setGoal(e.target.value.replace(/[^0-9]/g, ''))
  }

  async function getGoal(){
    const res = await apiEmbalei.get('meta')
    setGoal(res.data.meta)
  }

  function envGoal(e) {
    try {
      e.preventDefault()

      if (goal > 0 && goal !== '') {
        const res = apiEmbalei.put('meta', { meta: goal })
        toast.success(`Meta do dia ${format(new Date(), 'dd/MM/yyyy')} em diante atualizada para ${goal} itens por pedido`, {
          autoClose: 5000
        })
      } else {
        toast.warn(`Meta deve ser maior que 0`, {
          autoClose: 5000
        })
      }
    } catch (error) {
      toast.error('Falha ao atualizar META. Entre em contato com a equipe SantoLabs^.', {
        autoClose: 5000
      })
    }
  }

  useEffect(() => {
    verifyAuth()
    getGoal()
  }, [])

  return (
    <>
      <Dashboard title="Meta Embalei">

        <Container maxWidth='sm'>
          <form onSubmit={envGoal}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TitleTable>Meta de itens Embalados</TitleTable>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth variant='outlined' >
                  <InputLabel>Meta de itens por pedido</InputLabel>
                  <OutlinedInput
                    value={goal}
                    type='tel'
                    onChange={handleValue}
                    label="Meta de itens por pedido"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <SubmitButton fullWidth type='submit'>Atualizar meta</SubmitButton>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Dashboard>
    </>
  )
}

export default MetaEmbalei;
