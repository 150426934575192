import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
	fullWidth: {
		backgroundColor: "#000",
		maxWidth: 1970,
		padding: 0,
	},

	btnFullscreen: {
		position: 'fixed',
		background: '#b01010',
		color: '#fff',
		right: 25,
		bottom: 25,

		'&:hover': {
			background: '#960d0d',
			color: '#fff',
		}
	},

	containerDash: {
		backgroundColor: "#000",
		height: '100%',
		[theme.breakpoints.up('lg')]: {
			padding: 50,
			paddingTop: 10,
		},
	},

	boxSquare: {
		[theme.breakpoints.up('md')]: {
			// height: 449,

			'& > div': {
				height: '100%',
			}
		}
	},

	paddingBox: {

		[theme.breakpoints.down('sm')]: {
			padding: 10
		},


		[theme.breakpoints.up('md')]: {
			padding: 25
		},
	},

	count: {
		color: '#7f828f',
		fontSize: '2vw'
	},

	progressBar: {
		height: '100%',
		width: '100%',
		background: '#fff',
		borderRadius: 10,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		flexDirection: 'column',
		minHeight: 250,
		padding: 10,
	},

	progressBarTitle: {
		fontSize: '2vw',
		color: 'rgb(63, 212, 107)',
		paddingTop: 10,

		[theme.breakpoints.down('sm')]: {
			fontSize: '5.5vw',
		},
	},

	priceValue: {
		fontSize: 24,
		fontWeight: 'bold',
		paddingRight: 5,
	},

	inputGoal: {
		'& .MuiOutlinedInput-input': {
			padding: 2,
			width: '13vw',

			[theme.breakpoints.down('sm')]: {
					width: '30vw',
			},

		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
			outline: 'none'
		}
	}
}))

export default useStyle