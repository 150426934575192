import React, { useState, useEffect } from 'react';

import { Box, Button, Container, FormControl, Grid, Hidden, IconButton, InputLabel, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from '@material-ui/core'
import useStyle from './style';
import Dashboard from '../../Components/Dashboard';
import { Fullscreen } from '@material-ui/icons';
import history from '../../Services/history';
import { apiLabs, apiEbook } from '../../Services/api';
import { store } from '../../store';
import Denied from '../../Components/Denied';
import DeleteIcon from '@material-ui/icons/Delete';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import EditIcon from '@material-ui/icons/Edit';

import { toast } from 'react-toastify'

function ListEbook(props) {

    const { location: { idPage } } = props

    async function verifyAuth() {
        const { profile } = store.getState().user
        const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
        if (res.data.message === "User not authorized") {
            return setAuth('denied')
        }
        if (res.data.message === "User authorized") {
            return setAuth('authorized')
        }
        return history.push({ pathname: '/', state: { from: props.location } })
    }

    const [fullscreen, setFullscreen] = useState(false)
    const [auth, setAuth] = useState(null)

    const month = ['Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro']
    const quantatyLimit = ['25', '50', '100']
    const [limit, setLimit] = useState('')
    const [year, setYear] = useState({})
    const [date, setDate] = useState({
        ano: '',
        mes: ''
    })
    const [data, setData] = useState([])
    const [open, setOpen] = React.useState(false);
    const [dataUpdated, setDataUpdated] = useState({
        id: '',
        name_ebook: '',
        ano: '',
        mes: '',
        url_cover_image: '',
        url_ebook: ''
    })
    const [previewImageEbook, setpreviewImageEbook] = useState({
        imagem_ebook: '',
    })
    const [error, setError] = useState(false)
    const [openDelete, setDelete] = useState(false)
    const [deleteEbook, setDeleteEbook] = useState([])
    const [pagination, setPagination] = useState({
        previusPage: 1,
        nextPage: 1,
        lastPage: 0
    })
    const [currentPage, setCurrentpage] = useState(1)

    const ebookUpdated = async (id) => {
        const ebook = data.filter(ebook => ebook.id === id)
        setDataUpdated({
            id: ebook[0].id,
            name_ebook: ebook[0].name_ebook,
            ano: ebook[0].ano,
            mes: ebook[0].mes,
            url_cover_image: ebook[0].url_cover_image,
            url_ebook: ebook[0].url_ebook
        })
    }

    const getYear = async () => {
        const res = await apiEbook.post('ano')
        const yearFilted = res.data.reduce((acc, year) => {
            return acc.includes(year.ano) ? acc : [...acc, year.ano]
        }, [])
        setYear(yearFilted.sort((a, b) => a - b))
    }

    const getEbook = async () => {
        const res = await apiEbook.get(`filter_ebook?limit=${limit}`);
        if (res.data.ebook.rows === 0) {
            setError(true)
        } else {
            setError(false)
            setData(res.data.ebook.rows)
            setPagination({
                ...pagination,
                lastPage: res.data.pages
            })
        }
    }

    const filterEbook = async (query) => {
        let ebook;
        try {
            ebook = await apiEbook.get(query)
            const data = ebook.data.ebook.rows
            if (data.length === 0) {
                setError(true)
            } else {
                setError(false)
                setData(data)
                setPagination({
                    ...pagination,
                    lastPage: ebook.data.pages
                })
            }
        } catch (error) {
            toast.error(error)
        }
    }

    const handleOpen = (id) => {
        setOpen(true);
        ebookUpdated(id)
    };

    const handleClose = () => {
        setOpen(false);
        setpreviewImageEbook({ imagem_ebook: "" })
        setDataUpdated({
            id: '',
            ano: '',
            mes: '',
            url_cover_image: '',
            url_ebook: ''
        })
    };

    const handleCloseDelete = () => {
        setDelete(false)
    }

    function handleChange({ target }) {
        setDataUpdated({
            ...dataUpdated, [target.id]: target.value
        })
    }

    const handleSelect = ({ target }) => {
        setDate({
            ...date,
            [target.id]: target.value
        })
    }

    const handleLimit = ({ target }) => {
        setLimit(target.value)
    }

    const handlePreviusPage = () => {
        setPagination({
            ...pagination,
            previusPage: --pagination.previusPage,
            nextPage: --pagination.nextPage,
        })
        if (pagination.previusPage === 0) {
            setCurrentpage(1)
        } else {
            setCurrentpage(pagination.previusPage)
        }
        filterEbook(`/filter_ebook?mes=${date.mes}&ano=${date.ano}&page=${pagination.previusPage}&limit${limit}`)

    }

    const handleNextPage = () => {
        setPagination({
            ...pagination,
            nextPage: ++pagination.nextPage,
            previusPage: ++pagination.previusPage,
        })
        filterEbook(`filter_ebook?mes=${date.mes}&ano=${date.ano}&page=${pagination.nextPage}&limit${limit}`)
        setCurrentpage(pagination.nextPage)
    }

    const handleSearch = async () => {
        if (date.mes && date.ano) {
            filterEbook(`filter_ebook?mes=${date.mes}&ano=${date.ano}&limit=${limit}`)
        } else if (date.ano) {
            filterEbook(`filter_ebook?ano=${date.ano}&limit=${limit}`)
        } else if (date.mes) {
            filterEbook(`filter_ebook?mes=${date.mes}&limit=${limit}`)
        } else {
            if (date.mes === '' && date.ano === '') {
                getEbook()
            }
        }
    }

    const getEbookModalDelete = async (id) => {
        const ebook = await apiEbook.get(`${id}`)
        setDeleteEbook(ebook.data)
    }

    const ebookDelete = async (id) => {
        const ebook = data.filter(ebook => ebook.id !== id)
        setData(ebook)
        try {
            await apiEbook.delete(`${id}`)
            toast.success('Ebook deletado com sucesso')
            const ebook = data.filter(ebook => ebook.id !== id)
            setData(ebook)
            setDelete(false)
        } catch (error) {
            toast.error(error)
        }

    }

    const handleDelete = async (id) => {
        setDelete(true)
        getEbookModalDelete(id)
    }

    const uploadImage = e => {
        const file = e.target.files[0] ? e.target.files[0] : []
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = e => {
            setpreviewImageEbook({ imagem_ebook: reader.result })
        }
        setDataUpdated({ ...dataUpdated, [e.target.id]: file })
    }

    const uploadPdf = e => {
        const file = e.target.files[0] ? e.target.files[0] : []
        setDataUpdated({ ...dataUpdated, [e.target.id]: file })
    }

    const saveEbook = async (obj) => {
        const ebook = await apiEbook.put(`${dataUpdated.id}`, obj)
        setDataUpdated({
            id: ebook.data.id,
            name_ebook: ebook.data.name_ebook,
            ano: ebook.data.ano,
            mes: ebook.data.mes,
            url_cover_image: ebook.data.url_cover_image,
            url_ebook: ebook.data.url_ebook
        })
    }

    const handleSubmit = async (e) => {

        e.preventDefault()
        const { name_ebook, mes, ano, url_cover_image, url_ebook } = dataUpdated;
        let obj = new FormData()

        obj.append('name_ebook', name_ebook)
        obj.append('mes', mes)
        obj.append('ano', ano)
        obj.append('imagem-ebook', url_cover_image)
        obj.append('pdf-ebook', url_ebook)

        try {
            await saveEbook(obj)
            toast.success('Ebook alterado com sucesso')
            setOpen(false)
        } catch (error) {
            toast.error(error)
        }
    }

    useEffect(()=>{
        handleSearch()
    },[limit])

    useEffect(() => {
        handleSearch()
    }, [open === false])

    useEffect(() => {
        getYear()
    }, [])

    useEffect(() => {
        getEbook()
    }, [limit])

    useEffect(() => {
        getEbook()
    }, [])

    useEffect(() => {
        verifyAuth()
    }, [])

    const style = useStyle()

    return (
        <>
            <Dashboard title="Listar ebook" fullscreen={fullscreen}>
                {auth === 'denied' && <Denied />}
                {auth === 'authorized' &&
                    <>
                        <Container className={`${style.fullWidth}`} >
                            <Grid className={style.containerEbook}>
                                <Grid className={style.filterSearch}>
                                    <FormControl variant="outlined" className={style.form}>
                                        <InputLabel htmlFor="mes">Selecione um mês</InputLabel>
                                        <Select
                                            native
                                            value={date.mes}
                                            onChange={handleSelect}
                                            label="Selecione um mês"
                                            inputProps={{
                                                id: 'mes',
                                            }}
                                        >
                                            <option value=''></option>
                                            {month && month.map((month) => (
                                                <option key={month} aria-label="None" value={month}>{month}</option>
                                            ))}
                                        </Select>
                                    </FormControl>

                                    <FormControl variant="outlined" className={style.form}>
                                        <InputLabel htmlFor="ano">Selecione um ano</InputLabel>
                                        <Select
                                            native
                                            value={date.ano}
                                            onChange={handleSelect}
                                            label="Selecione um ano"
                                            inputProps={{
                                                name: 'ano',
                                                id: 'ano',
                                            }}
                                        >
                                            <option value=''></option>
                                            {year.length > 0 && year.map((year) => (
                                                <option key={year} value={year}>{year}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button variant="contained" onClick={handleSearch}>Buscar</Button>
                                </Grid>
                                <Grid className={style.containerTable}>
                                    <FormControl variant="outlined" className={style.selectQuantaty}>
                                        <InputLabel htmlFor="limit">Limite</InputLabel>
                                        <Select
                                            native
                                            value={limit}
                                            onChange={handleLimit}
                                            label="Selecione um limit"
                                            inputProps={{
                                                name: 'limit',
                                                id: 'limit',
                                            }}
                                        >
                                            <option value=''></option>
                                            {quantatyLimit !== '' && quantatyLimit.map((quantatyLimit) => (
                                                <option key={quantatyLimit} value={quantatyLimit}>{quantatyLimit}</option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TableContainer >
                                        {!error ?
                                            <Table className={style.table} >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center" className={style.textHeader}>Nome ebook</TableCell>
                                                        <TableCell align="center" className={style.textHeader}>Imagem do ebook</TableCell>
                                                        <TableCell align="center" className={style.textHeader}>Pdf Ebook</TableCell>
                                                        <TableCell align="center" className={style.textHeader}>Ano</TableCell>
                                                        <TableCell align="center" className={style.textHeader}>Mês</TableCell>
                                                        <TableCell align="center" className={style.textHeader}>Ações</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.map((row) => (
                                                        <TableRow key={row.id}>
                                                            <TableCell align="center" className={style.text}>{row.name_ebook}</TableCell>
                                                            <TableCell align="left" className={style.text}><span>{row.url_cover_image}</span></TableCell>
                                                            <TableCell align="left" className={style.text}><span>{row.url_ebook}</span></TableCell>
                                                            <TableCell align="left" className={style.text}>{row.ano}</TableCell>
                                                            <TableCell align="left" className={style.text}>{row.mes}</TableCell>
                                                            <TableCell align="left" className={style.flex}>
                                                                <EditIcon className={style.edit} onClick={e => handleOpen(row.id)} />
                                                                <DeleteIcon className={style.delete} onClick={e => handleDelete(row.id)} />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                            : <p className={style.error}>Nenhum ebook econtrado</p>}
                                    </TableContainer>
                                    <Grid className={style.pagination}>
                                        {pagination.previusPage > 1 && <Button id="previusPage" onClick={handlePreviusPage}>Anterior</Button>}
                                        <Typography className={style.currentPage}>{currentPage}</Typography>
                                        {currentPage < pagination.lastPage && <Button id="nextPage" onClick={handleNextPage}>proximo</Button>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                    </>
                }

                <div>
                    <Modal
                        className={style.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <div className={style.paper}>
                                <form onSubmit={e => handleSubmit(e)} encType="multipart/form-data">
                                    <Grid className={style.containeForm}>
                                        <Box className={`${style.form}`}>
                                            <TextField
                                                id="name_ebook"
                                                label="Name ebook"
                                                value={dataUpdated.name_ebook}
                                                onChange={handleChange}
                                                variant="outlined" />
                                        </Box>
                                        <Box className={style.form}>
                                            <TextField
                                                id="mes"
                                                label="Mês"
                                                value={dataUpdated.mes}
                                                onChange={handleChange}
                                                variant="outlined" />
                                            <TextField
                                                id="ano"
                                                label="Ano"
                                                value={dataUpdated.ano}
                                                onChange={handleChange}
                                                variant="outlined" />
                                        </Box>
                                    </Grid>
                                    <Grid className={style.formFiles}>
                                        <Box className={style.imageEbook}>
                                            <TextField
                                                accept="image/*"
                                                id="url_cover_image"
                                                label="Imagem Ebook"
                                                placeholder="imagem"
                                                onChange={e => uploadImage(e)}
                                                margin="normal"
                                                type="file"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                            <Box className={style.previewImg}>
                                                {previewImageEbook.imagem_ebook ?
                                                    <img src={previewImageEbook.imagem_ebook} alt="Ebook" /> :
                                                    <img src={dataUpdated.url_cover_image} alt="Ebook" />}
                                            </Box>
                                        </Box>
                                        <Box>
                                            <TextField
                                                id="url_ebook"
                                                label="Pdf Ebook"
                                                style={{ margin: 8 }}
                                                onChange={e => uploadPdf(e)}
                                                placeholder="pdf"
                                                fullWidth
                                                margin="normal"
                                                type="file"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="outlined"
                                            />
                                        </Box>

                                    </Grid>
                                    <Grid className={style.buttons}>
                                        <Button type="submit" >Alterar</Button>
                                        <Button onClick={handleClose}>Voltar</Button>
                                    </Grid>
                                </form>
                            </div>
                        </Fade>
                    </Modal>
                </div>
                <div>
                    <Modal
                        className={style.modalDelete}
                        open={openDelete}
                        onClose={handleCloseDelete}
                        closeAfterTransition
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openDelete}>
                            <div className={`${style.paperDelete} ${style.deleteModal} `}>
                                <h2>Realmente deseja excluir o ebook:</h2>
                                <h2>{`${deleteEbook.mes} de ${deleteEbook.ano}`}</h2>
                                <div className={style.buttonsDelete}>
                                    <button onClick={e => ebookDelete(deleteEbook.id)}>Deletar</button>
                                    <button onClick={handleCloseDelete}>Voltar</button>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                </div>
            </Dashboard>


        </>
    )
}

export default ListEbook;
