import { Box, Grid, Typography } from '@material-ui/core'
import useStyle from './style';

import logoLoja from '../../Assets/img/logo-loja.png'

import React from 'react'

const ModelPrint = React.forwardRef((props, ref) => (
    <div className={`${useStyle().containerPrint}`} ref={ref}>
        <Box className={`${useStyle().logo}`}>
            <img src={logoLoja} alt="loja santo antonio" />
        </Box>
        <Box className={`${useStyle().infoHeader}`}>
            <Typography>Estamos separando o seu pedido!</Typography>
            <Typography>1°- Faça o pagamento no caixa</Typography>
            <Typography>2°- Retire as cascas na casa ao lado (n° 712)</Typography>
            <Typography>Obrigado!</Typography>
        </Box>
        <Box className={`${useStyle().containerOrders}`}>
            <Box className={`${useStyle().boxOrder}`}>
                <Typography className={`${useStyle().numberOrder}`}>N° PEDIDO: </Typography>
                <Typography className={`${useStyle().numberOrder}`}>{props.numberOrder} </Typography>
            </Box>
            <Box className={`${useStyle().boxPdv}`}>
                <Typography className={`${useStyle().numberPdv}`}>N° PDV: </Typography>
                <Typography className={`${useStyle().numberPdv}`}>{props.numberPdv} </Typography>
            </Box>
        </Box>
        <Box className={`${useStyle().infoFooter}`}>
            <Typography>Acesse nosso site e aproveite as ofertas!</Typography>
            <Typography>www.lojasantoantonio.com.br</Typography>
        </Box>
    </div>
))

export default ModelPrint
