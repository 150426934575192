import { makeStyles } from '@material-ui/core'

const useStyleBox = makeStyles((theme) => ({
  root: {
    background: props => props.bg,
    boxShadow: '0px 0px 10px 0px #70707033',
    borderRadius: 10,
    position: 'relative',
    padding: 5,

    '& .MuiTypography-root, input': {
      lineHeight: 1,
      color: props => props.bg !== '#fff' ? '#fff' : props.color,
      textAlign: 'center'
    }
  },

  progress: {
    display: 'block',
    margin: '80px auto'
  },


  boxSquare: {

    [theme.breakpoints.down('sm')]: {
      minHeight: 150,
      height: '100%',
    },

    '& $title': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '4.5vw',
        paddingTop: 10
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '2vw',
        paddingTop: 20,
      }
    },

    '& $quantity': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '15vw',
        paddingTop: 30,
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '6vw',
        paddingTop: 80,
      }
    },

    '& $prevision': {

      marginLeft: 5,
      padding: '10px 0',

      [theme.breakpoints.down('sm')]: {
        fontSize: '4vw',

      },

      [theme.breakpoints.up('md')]: {
        fontSize: '2vw',

      }
    }

  },

  previsionBox: {
    [theme.breakpoints.up('md')]: {
      paddingTop: 50,

    }
  },

  boxGoal: {

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      minHeight: 150,
    },

    '& $title': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '5.5vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '50%',
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '2vw',
        paddingTop: 20,
      }
    },

    '& $quantity': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '13vw',
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '7vw',
        paddingTop: 30,
      }
    },

    '& $values': {
      [theme.breakpoints.down('sm')]: {
        width: '50%',
        padding: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
      }
    },

    '& $lack': {

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      [theme.breakpoints.up('md')]: {
        paddingTop: 30,
      },

      '& span:first-child': {
        [theme.breakpoints.down('sm')]: {
          fontSize: '4.5vw',
          paddingRight: 10,
        },

        [theme.breakpoints.up('md')]: {
          fontSize: '2vw',
          paddingRight: 15,
        }
      },

      '& span:last-child': {
        [theme.breakpoints.down('sm')]: {
          fontSize: '8vw',
        },

        [theme.breakpoints.up('md')]: {
          fontSize: '4vw',

        }
      }
    },

  },

  boxAnalisys: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
    },

    '& $boxAwaits': {
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        width: '50%',
        paddingTop: 10,
      },

      '&:first-child': {
        [theme.breakpoints.up('md')]: {
          paddingTop: 30,
        },
      },

      '&:nth-child(2)': {
        [theme.breakpoints.up('md')]: {
          paddingTop: 20,
        },
      }
    },

    '& $values': {
      [theme.breakpoints.down('sm')]: {
        padding: '25px 0',
      },

      [theme.breakpoints.up('md')]: {
        paddingTop: 15,
      },
    },

    '& $title': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '4vw',
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '1.2vw',
      },
    },

    '& $quantity': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '10vw',
        fontWeight: 800,
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '3vw',
      },
    },

    '& $prevision': {

      [theme.breakpoints.down('sm')]: {
        fontSize: '5vw',
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '2vw',
      },
    },

  },

  boxHistory: {
    width: '100%',
    background: props => props.bg,
    boxShadow: '0px 0px 10px 0px #70707033',
    borderRadius: 10,
    position: 'relative',
    padding: 10,
    backgroundColor: '#ffffff',


    '& p, span': {
      lineHeight: 1,
    },

    '& $title': {
      width: '100%',
      color: '#3fd46b',
      fontWeight: 800,
      textAlign: 'center',

      '& span:first-child': {
        paddingRight: 7
      },

      [theme.breakpoints.down('sm')]: {
        fontSize: '5.5vw',
        paddingTop: 15,
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '2vw',
        paddingTop: 20,
        paddingLeft: 30,
        paddingRight: 30,
      }

    },

    '& $boxHour, $boxDay': {
      '& $title': {
        color: '#282a31',
        [theme.breakpoints.down('sm')]: {
          fontSize: '4.5vw',
          paddingTop: 0,
        },

        [theme.breakpoints.up('md')]: {
          fontSize: '1.5vw',
        }
      },

      '& .label': {
        color: '#7f828f',
        [theme.breakpoints.down('sm')]: {
          fontSize: '5vw',
        },
      },

      [theme.breakpoints.up('md')]: {
        '& .infos': {

          '& .label': {
            [theme.breakpoints.up('md')]: {
              fontSize: '1vw',
            },

            '&:first-child': {
              [theme.breakpoints.up('md')]: {
                paddingTop: 20,
                paddingRight: 10
              },
            }
          },

          '& .label, $quantity': {
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
              paddingTop: 10,
            },
          }
        }
      },

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexWrap: 'wrap',

        '& .infos': {
          width: '50%',
          [theme.breakpoints.down('sm')]: {
            paddingTop: 10,
          },

          '& .label, $quantity': {
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
              paddingTop: 10,
            },
          }
        }
      }
    },

    '& $boxHour': {
      '& $quantity': {

        [theme.breakpoints.up('md')]: {
          paddingTop: 15,
        },

        '& span': {
          '&.positive': {
            color: '#3fd46b'
          },

          '&.negative': {
            color: '#f4393c'
          },

          '&:first-child': {
            fontWeight: 400,
            paddingRight: 7,

            [theme.breakpoints.down('sm')]: {
              fontSize: '5vw',
            },

            [theme.breakpoints.up('md')]: {
              fontSize: '1.5vw',
            }
          },

          '&:last-child': {
            [theme.breakpoints.down('sm')]: {
              fontSize: '10vw',
            },

            [theme.breakpoints.up('md')]: {
              fontSize: '5vw',
            }
          }
        }
      }
    },

    '& $boxDay': {
      '& $quantity': {
        [theme.breakpoints.down('sm')]: {
          fontSize: '10vw',
        },

        [theme.breakpoints.up('md')]: {
          fontSize: '5vw',
          paddingTop: 15,
        }
      }
    }

  },

  boxPrevision: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up('md')]: {
      paddingTop: 30,
    },

    '& .text, .value': {
      color: '#271e1e !important',
    },

    '& .text': {
      paddingRight: 10,

      [theme.breakpoints.down('sm')]: {
        fontSize: '5vw',
        fontWeight: 700,
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '1.5vw',
      },
    },

    '& .value': {
      fontWeight: 800,

      [theme.breakpoints.down('sm')]: {
        fontSize: '15vw',
      },

      [theme.breakpoints.up('md')]: {
        fontSize: '4vw',
      },
    },
  },

  lack: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  quantity: {
    fontWeight: 800,
  },

  boxHour: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
    },
  },
  boxDay: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 20,
    },
  },

  divider: {
    display: 'block',
    width: '100%',
    height: 5,
    background: '#f3f6f8',
    margin: '25px 0',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  inputGoal: {
    border: 'none',
    outline: 'none',
    background: 'transparent',
    width: '100%',
    textAlign: 'center',
  },

  title: {},
  values: {},
  boxAwaits: {},
  prevision: {},


  boxRanking: {

    width: '100%',
    height: '100%',
    paddingTop: 40,

    '& .place': {
      position: 'relative',
      padding: '10px 30px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '& span': {
        fontWeight: 'bold',
        lineHeight: 1,

        '&:first-child': {
          width: 70,
        },

        '& img': {
          maxWidth: '100%',
          height: 'auto'
        }
      },
      [theme.breakpoints.between("md","lg")]: {
        padding: '10px 10px',
      },

      [theme.breakpoints.down('xs')]: {
        padding: '10px 5px',
      },
    },

    '& .first_place': {
      fontSize: 54,
      top: -40,

      [theme.breakpoints.down('lg')]: {
        fontSize: 40,
      },

      [theme.breakpoints.down('md')]: {
        fontSize: 30,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 18,
      },
    },

    '& .second_place': {
      fontSize: 50,
      top: -20,

      [theme.breakpoints.down('lg')]: {
        fontSize: 36,
      },

      [theme.breakpoints.down('md')]: {
        fontSize: 24,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },

    '& .third_place': {
      fontSize: 46,

      [theme.breakpoints.down('lg')]: {
        fontSize: 32,
      },

      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      },

      [theme.breakpoints.down('xs')]: {
        fontSize: 14,
      },
    }
  },

  titleRanking: {
    color: '#3fd46b',

    [theme.breakpoints.down('sm')]: {
      fontSize: '4.5vw',
      paddingTop: 10
    },

    [theme.breakpoints.up('md')]: {
      fontSize: '2vw',
      paddingTop: 20,
    }
  },

  dateRanking: {
    fontWeight: 'bold'
  }

}))

export default useStyleBox
