import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import PropTypes from 'prop-types'
import { store } from '../store'


const AuthRouter = ({ isPrivate, idPage, location, ...other }) => {
	const { signed } = store.getState().auth

	if (isPrivate) {
		if (signed) {
			return <Route isPrivate={isPrivate} {...other} location={{ ...location, idPage: idPage }} />
		} else {
			return <Redirect to={{ pathname: '/', state: { from: location } }} />
		}
	}

	return <Route {...other} />;

}

AuthRouter.propTypes = {
	// component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
	isPrivate: PropTypes.bool,
}

export default AuthRouter