import React from 'react';

import { Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';

import { InputCustom } from '../Custom'

// import { ptBR } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

function InputsFilter({ type, handleFilter, value, index, condition, handleDate }) {

  const Condition = () => {
    return (
      <Grid item xs={5} sm={3} md={2}>
        <InputCustom variant="outlined" fullWidth>
          <InputLabel id={`${type}_filter`}>Condição</InputLabel>
          <Select
            labelId={`${type}_filter`}
            id="condition_filter"
            label="Condição"
            value={condition ? condition : '='}
            onChange={e => handleFilter(e, index)}
            name={'condition'}
          >
            <MenuItem value={'='}>Igual a</MenuItem>
            <MenuItem value={'!='}>Diferente de</MenuItem>
          </Select>
        </InputCustom>
      </Grid>
    )
  }

  if (type === 'registration_date') {
    return (
      <>
        <Condition />
        <Grid item xs={7} sm={4} md={3} lg={2}>
           <MuiPickersUtilsProvider utils={DateFnsUtils} > {/* locale={ptBR} */}
            <KeyboardDatePicker
              fullWidth
              disableToolbar
              className={'input_registration_date'}
              variant="inline"
              format="dd/MM/yyyy"
              id={`${type}_filter`}
              label="Data de cadastro"
              inputVariant={"outlined"}
              value={value}
              invalidDateMessage={'Data inválida'}
              onChange={e => handleDate(e, index)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </>

    )
  }

  if (type === 'sku_id' || type === 'status_vtex' || type === 'status_blue') {
    return (
      <Grid item xs={7} sm={4} md={5}>
        <InputCustom variant="outlined" fullWidth>
          <InputLabel htmlFor={`${type}_filter`}>Status</InputLabel>
          <Select
            labelId={`${type}_filter`}
            value={value}
            name={'value'}
            onChange={e => handleFilter(e, index)}
            label={'Status'}
          >
            <MenuItem value={'ativo'}>Ativo</MenuItem>
            <MenuItem value={'inativo'}>Inativo</MenuItem>
          </Select>
        </InputCustom>
      </Grid>
    )
  }

  if (type === 'image_url') {
    return (
      <Grid item xs={7} sm={4} md={2}>
        <InputCustom variant="outlined" fullWidth>
          <InputLabel htmlFor={`${type}_filter`}>Possui imagem</InputLabel>
          <Select
            labelId={`${type}_filter`}
            value={value}
            name={'value'}
            onChange={e => handleFilter(e, index)}
            label={'Possui imagem'}
          >
            <MenuItem value={'sim'}>Sim</MenuItem>
            <MenuItem value={'nao'}>Não</MenuItem>
            <MenuItem value={'foradelinha'}>Fora de linha</MenuItem>
          </Select>
        </InputCustom>
      </Grid>
    )
  }

  if (type === 'stock_vtex' || type === 'stock_blue') {
    return (
      <>
        <Grid item xs={5} sm={3} md={2}>
          <InputCustom variant="outlined" fullWidth>
            <InputLabel id={`${type}_filter_condition`}>Condição</InputLabel>
            <Select
              labelId={`${type}_filter_condition`}
              id={`${type}_filter_condition`}
              label="Condição"
              value={condition ? condition : '='}
              onChange={e => handleFilter(e, index)}
              name={'condition'}
            >
              <MenuItem value={'='}>Igual a</MenuItem>
              <MenuItem value={'!='}>Diferente de</MenuItem>
              <MenuItem value={'<>'}>Entre</MenuItem>
            </Select>
          </InputCustom>
        </Grid>

        <Grid item xs={7} sm={4} md={3}>
          <InputCustom variant="outlined" fullWidth>
            <InputLabel htmlFor="value_filter">Total</InputLabel>
            <OutlinedInput
              id={'value_filter'}
              value={value}
              name={'value'}
              onChange={e => handleFilter(e, index)}
              label={'Total'}
              type="tel"
              placeholder={condition === '<>' ? 'Exemplo 0-10' : ''}
            />
          </InputCustom>
        </Grid>

      </>
    )
  }

  return (
    <></>
  )
}

export default InputsFilter;
