import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({

    fullWidth: {
        backgroundColor: "#fafafa",
    },
   
    containerDash: {
        backgroundColor: "#fafafa",
        height: '100vh',
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '7px',
        width: '100%',
    },

    containerStatus: {
        width: '30%'
    },

    containerHeader: {
        height: '82px',
        position: 'sticky',
        top: 0,
        padding: '5px',
        backgroundColor: '#fafafa',
        marginBottom: 10,
        zIndex: 999,

        '& :nth-child(2)': {
            color: '#883600',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '& :nth-child(3)': {
            color: '#883600',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '& :nth-child(4)': {
            color: '#883600',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '& :nth-child(5)': {
            color: '#883600',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '& :nth-child(6)': {
            color: '#461c00',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '& :nth-child(7)': {
            color: '#feb773',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '& :nth-child(8)': {
            color: '#883600',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '& :nth-child(9)': {
            color: '#883600',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '& :nth-child(10)': {
            color: '#883600',
            fontSize: '20px',
            fontWeight: 'bold',
            textAlign: 'center',
        },

        '@media print': {
            display: 'none',
        }
    },

    orders: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '15%',

        '& :nth-child(1)': {
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#000',
        },
        '& :nth-child(2)': {
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#a9a5a5',
        },
    },

    containerCards: {
        height: '100%',
        zIndex: 1
    },

    noPrint: {
        display: "none",

        '@media print': {
            display: "block",
            width: '100%',
            height: '100hw'
        }
    },

    containerPrint: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
    },

    boxPrint: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    PrintOrderPdv: {
        display: "flex",
        alignItems: 'baseline',
        marginBottom: '-30px',

        '& :nth-child(1)': {
            fontSize: '40px',
            fontWeight: 'bold',
            marginRight: '10px',
        },

        '& :nth-child(2)': {
            fontSize: '80px',
            fontWeight: 'bold',
        }
    },

    PrintNumberOrder: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: "10px",

        '& :nth-child(1)': {
            fontSize: '30px',
            color: '#a9a5a5',
            fontWeight: 'bold',
            marginRight: '5px',

        },

        '& :nth-child(2)': {
            fontSize: '30px',
            color: '#a9a5a5',
            fontWeight: 'bold',
        }
    },

    PrintQuantaty: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px',
    },

    printTotal: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        borderTop: '1px dotted #000',
        borderBottom: '1px dotted #000',

        '& :nth-child(1)': {
            marginRight: "10px",
            fontSize: '30px',
        },

        '& :nth-child(2)': {
            marginRight: "10px",
            fontSize: '40px',
            fontWeight: 'bold'
        },

        '& :nth-child(3)': {
            fontSize: '30px'
        }
    },

    printImg: {
        width: '250px',
        marginBottom: '-20px',
        marginTop: "-40px",

        '& img': {
            display: "block",
            width: '100%',
        }
    }

}))

export default useStyle