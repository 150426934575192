import React, { useEffect, useState } from 'react'

import socketIOClient from 'socket.io-client';

import history from '../../Services/history';
import { store } from '../../store';
import { apiLabs } from '../../Services/api';
import Denied from '../../Components/Denied';
import Dashboard from '../../Components/Dashboard';
import useStyle from './style';
import { Box, Container, Grid, Hidden, IconButton, Typography } from '@material-ui/core';
import { Fullscreen } from '@material-ui/icons';

import {HandlingNow} from '../../Services/Api/apisExpedition/HandlingGoalNow'

import songtaadaahh from '../../sons/songtaadaahh.mp3'


function QuantatyOrder(props) {

    const { location: { idPage } } = props
    const [auth, setAuth] = useState(null)
    const [orderToday, setOrderToday] = useState(0)
    const [paymentBillet, setPaymentBillet] = useState(0)
    const [valueTotalBillet, setvalueTotalBillet] = useState(0)
    const [valueOrders, setvalueOrders] = useState(0)
    const [fullscreen, setFullscreen] = useState(false)

    const type_user = store.getState().user.profile.name

    const style = useStyle()
    const bugleSong = new Audio(songtaadaahh);

    async function verifyAuth() {
        const { profile } = store.getState().user
        const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
        if (res.data.message === "User not authorized") {
            return setAuth('denied')
        }
        if (res.data.message === "User authorized") {
            return setAuth('authorized')
        }
        return history.push({ pathname: '/', state: { from: props.location } })
    }

    const getValues = async () => {
        const res = await HandlingNow()

        if (res.data.length > 0) {
            setOrderToday(res.data[0].order_today)
            setPaymentBillet(res.data[0].boleto)
            setvalueOrders(res.data[0].v_total)
            setvalueTotalBillet(res.data[0].v_boleto)
        }
    }

    const getCentena = () => {
        let resto = orderToday.toString().substr(-2)
        const centena = parseInt(orderToday) + (100 - parseInt(resto))

        if(localStorage.getItem('centena') !== null){
            handleTotalWithSong()
        }

        localStorage.setItem('centena', centena)

    }

    const playSongBugle = (fileSong) => {
        fileSong.play()
    }

    useEffect(() => {
        if (auth === 'authorized') {
            const socket = socketIOClient('https://www.santolabs.com.br')
            socket.on('receivedMessage', (msg) => {
                if (msg === 'Order Today updated') {
                    getValues()
                }
            })
        }
    }, [auth])

    function requestFull() {
        document.documentElement.requestFullscreen()
    }

    window.addEventListener('resize', function () {
        if (window.screenTop !== 0) {
            setFullscreen(true)
        } else {
            setFullscreen(false)
        }
    })

    function handleTotalWithSong() {
        let centena = localStorage.getItem('centena')
        if ( parseInt(orderToday) >= parseInt(centena) ) {
            playSongBugle(bugleSong)
        }
    }

    useEffect(() => {
        if (parseInt(orderToday) !== 0) {
            getCentena()
        }
    }, [orderToday])

    useEffect(() => {
        verifyAuth()
    }, [])


    return (
        <>
            <Dashboard title="Quantidade de pedidos" fullscreen={fullscreen}>
                {auth === 'denied' && <Denied />}
                {auth === 'authorized' &&
                    <>
                        <Container className={`${style.fullWidth} ${style.containerDash}`}>
                            <Grid container>
                                <Grid container item xl={12} md={12} className={`${style.paddingBox} ${style.boxSquare} ${style.box}`}>
                                    <Box xl={12} md={12} className={`${style.boxOrder}`} >
                                        <Typography className={`${style.totals}`}>Pedidos </Typography>
                                        <Typography className={`${style.paragraphy}`}>{orderToday}</Typography>
                                        { type_user === 'master' && <Typography className={`${style.valueTotal}`}> R$: {parseInt(valueOrders).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Typography>}
                                    </Box>
                                    <Grid item xl={12} md={12} className={`${style.Boxcard}`}>
                                        <Box xl={6} md={6} className={`${style.cardBillet}`}>
                                            <Typography className={`${style.totalsbillet}`}>Boleto</Typography>
                                            <Typography className={`${style.totalcards}`}>{paymentBillet}</Typography>
                                            { type_user === 'master' && <Typography className={`${style.valuecards}`}>R$: {parseInt(valueTotalBillet).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</Typography>}
                                        </Box>
                                        <Box xl={6} md={6} className={`${style.cards}`}>
                                            <Typography className={`${style.totalsbillet}`}>Cartão</Typography>
                                            <Typography className={`${style.totalcards}`}>{orderToday - paymentBillet}</Typography>
                                            { type_user === 'master' && <Typography className={`${style.valuecards}`}> R$: {parseInt(valueOrders - valueTotalBillet).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') }</Typography>}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Container>
                        <Hidden mdDown>
                            {!fullscreen &&
                                <IconButton onClick={requestFull} className={style.btnFullscreen}>
                                    <Fullscreen />
                                </IconButton>
                            }
                        </Hidden>
                    </>

                }
            </Dashboard>
        </>
    )
}

export default QuantatyOrder
