import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useReactToPrint } from 'react-to-print'

import history from '../../Services/history';
import { store } from '../../store';
import { apiLabs } from '../../Services/api';
import getEggsShells from '../../Services/Api/apiEggShell/getEggShell';

import Denied from '../../Components/Denied';
import Dashboard from '../../Components/Dashboard';

import useStyle from './style';

import { Box, Container, Grid } from '@material-ui/core';
import SeparateShells from '../../Components/SeparateShells';
import ModalSeparetePrint from '../../Components/ModalSeparetePrint';

function EggShellSeparate(props) {

    const { location: { idPage } } = props
    const [auth, setAuth] = useState(null)
    const [fullscreen, setFullscreen] = useState(false)
    const [order, setOrder] = useState({
        orders: [],
    })
    const [print, setPrint] = useState([])
    const refModal = useRef()

    const style = useStyle()
    const modalRef = useReactToPrint({
        content: () => refModal.current
    })

    const totalShell = print.length > 0 &&
        Number(print[0].mini) +
        Number(print[0].m_leite) +
        Number(print[0].p_leite) +
        Number(print[0].g_leite) +
        Number(print[0].m_branco) +
        Number(print[0].m_amargo) +
        Number(print[0].m_belga);


    async function verifyAuth() {
        const { profile } = store.getState().user
        const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
        if (res.data.message === "User not authorized") {
            return setAuth('denied')
        }
        if (res.data.message === "User authorized") {
            return setAuth('authorized')
        }
        return history.push({ pathname: '/', state: { from: props.location } })
    }

    async function getAwaitEggsShells() {
        const res = await getEggsShells()
        const orders = res.data.filter(item => item.status !== 'Entregue' && item.status !== 'Cancelado' && { ...item })
        setOrder({ ...order, orders: orders })
    }

    function requestFull() {
        document.documentElement.requestFullscreen()
    }

    async function handlePrint(id) {

        const contentPrint = order.orders.filter((print) => print.id === id)
        if (contentPrint.length > 0) {
            setPrint(contentPrint)
        }

        if (contentPrint.length > 0) {
            setOrder({
                ...order,
                orders: order.orders.map(item =>
                    item.id === id ? { ...item, status: "Separado" } : item
                )
            })
            await apiLabs.put(`separa/painel_cascas/${id}`, { status: "Separado" })
            setPrint([])
        }
    }

    async function removeOrderDelivery() {
        setOrder({
            ...order,
            orders: order.orders.filter(order => order.status !== 'Entregue' && { ...order })
        })
    }

    async function handleConfirm(id) {
        setOrder({
            ...order,
            orders: order.orders.map(item => item.id === id ? { ...item, status: "Entregue" } : item).filter(item => item.status !== 'Entregue' && { ...item })
        })
        await apiLabs.put(`separa/painel_cascas/${id}`, { status: "Entregue" })
    }

    async function handleConfirmDelete(id) {
        setOrder({
            ...order,
            orders: order.orders.map(item => item.id === id ? { ...item, status: "Cancelado" } : item).filter(item => item.status !== 'Cancelado' && { ...item })
        })
        await apiLabs.put(`separa/painel_cascas/${id}`, { status: "Cancelado" })
    }

    window.addEventListener('resize', function () {
        if (window.screenTop !== 0) {
            setFullscreen(true)
        } else {
            setFullscreen(false)
        }
    })

    function getOrderTime() {
        setInterval(() => {
            getAwaitEggsShells()
        }, 3000)
    }
   
    useEffect(() => {
        if (order.orders.length > 0) {
            const orderAwaitShell = order.orders.filter((order) => order.status === 'Aguardando Separação')
            if (orderAwaitShell.length > parseInt(localStorage.getItem('orders'))) {
                if (parseInt(localStorage.getItem('orders')) > 0 || parseInt(localStorage.getItem('orders')) === 0 && orderAwaitShell.length === 1) {
                    const somPlay = document.getElementById('audio').play()
                    if (somPlay !== undefined) {
                        somPlay
                            .then(() => {
                                // console.log("audio played auto");
                            })
                            .catch(() => {
                                // console.log("playback prevented");
                            });
                    }
                }
                localStorage.setItem('orders', JSON.stringify(orderAwaitShell.length))
            } else if (orderAwaitShell.length === 0) {
                localStorage.setItem('orders', 0)
            }
        }
    }, [order.orders])

    useEffect(() => {
        getOrderTime()
    }, [])

    useEffect(() => {
        if (print.length !== 0) {
            modalRef()
        }

    }, [print])

    useEffect(() => {
        if (auth === 'authorized') {
            getAwaitEggsShells()
        }
    }, [auth])

    useEffect(() => {
        verifyAuth()
        removeOrderDelivery()
        const orderAwaitShell = order.orders.filter((order) => order.status === 'Aguardando Separação')
        localStorage.setItem('orders', orderAwaitShell.length)
    }, [])
    return (
        <>
            <Dashboard title="Separa Cascas" fullscreen={fullscreen}>
                {auth === 'denied' && <Denied />}
                {auth === 'authorized' &&
                    <>
                        <Container className={`${style.fullWidth} ${style.containerDash}`} maxWidth="xl">
                            <Box style={{ height: 0, width: 0 }}>
                                <audio
                                    style={{ display: 'none' }}
                                    id="audio"
                                    src='http://santolabs.com.br/static/media/som_novo.mp3'></audio>
                            </Box>
                            <Grid container justify="center" alignItems="center" className={`${style.containerHeader}`} >
                                <Grid item xs={1} container justify="center" alignItems="center" className={`${style.orders}`}>
                                    <Box>PDV</Box>
                                    <Box>Pedido</Box>
                                </Grid>
                                {/* <Grid item xs={1} >
                                    <Box>MINI</Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box>P Leite</Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box>M Leite</Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box>G Leite</Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box>M Amargo</Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box>  M Branco</Box>
                                </Grid>
                                <Grid item xs={1}>
                                    <Box>M Belga</Box>
                                </Grid> */}
                                <Grid item xs={1}>
                                    <Box>Status</Box>
                                </Grid>
                                <Grid item xs={3}>
                                    <Box>Ações</Box>
                                </Grid>
                            </Grid>
                            <Grid container justify="center" alignItems="center">

                                {order.orders.map((data) => (
                                    <Grid className={`${style.containerCards}`} item xs={12} key={data.id}>
                                        {data.status === 'Aguardando Separação' ?
                                            <SeparateShells
                                                data={data}
                                                status={data.status}
                                                handlePrint={handlePrint}
                                                handleConfirm={handleConfirm}
                                                handleConfirmDelete={handleConfirmDelete}
                                                setOrder={setOrder} />
                                            : ''
                                        }
                                    </Grid>
                                ))}

                                {order.orders.map((data) => (
                                    <Grid className={`${style.containerCards}`} item xs={12} key={data.id}>
                                        {data.status === 'Separado' ?
                                            <SeparateShells
                                                style={style}
                                                data={data}
                                                status={data.status}
                                                handlePrint={handlePrint}
                                                handleConfirm={handleConfirm}
                                                handleConfirmDelete={handleConfirmDelete}
                                                setOrder={setOrder} />
                                            : ''
                                        }
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid className={`${style.noPrint}`}>
                                {print.length > 0 &&
                                    <ModalSeparetePrint
                                        print={print}
                                        totalShell={totalShell}
                                        ref={refModal} />}
                            </Grid>

                        </Container>
                    </>
                }
            </Dashboard >
        </>
    )
}

export default EggShellSeparate;
