import React, { useState, memo, useEffect } from "react";
import Logo from "../../Assets/img/icon_santolabs.png";

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  Dehaze,
  Close,
  Home,
  LocalShipping,
  Settings,
  ExpandLess,
  ExpandMore,
  FormatListBulleted,
  Person,
  DashboardOutlined,
  PhoneInTalk,
  ExitToApp,
  PhoneIphone,
  Business,
  Speed,
  Cake,
  Search,
  BarChart,
  ShowChart,
  LineStyleRounded,
  Receipt,
} from "@material-ui/icons";
import { Box, Collapse, Hidden } from "@material-ui/core";
import { store } from "../../store";
import { useDispatch } from "react-redux";
import { signOut } from "../../store/modules/Auth/actions";
import { IconGoal, IconPackage, Santolabs } from "../Custom";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import iconPascoa from "../../Assets/img/feliz-pascoa.png";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import ContactsIcon from "@material-ui/icons/Contacts";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import BookmarksIcon from "@material-ui/icons/Bookmarks";
import BookIcon from "@material-ui/icons/Book";
import DoubleArrowOutlinedIcon from "@material-ui/icons/DoubleArrowOutlined";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  appName: {
    color: "#fff",
    position: "relative",
    right: 30,
    "& b": {
      color: "#e63246",
    },
  },

  iconLabs: {
    display: "none",
    width: "100%",
    textAlign: "center",
    position: "absolute",
    padding: 10,
    bottom: 0,
    "& img": {
      width: "34px",
      height: "auto",
    },
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",

    "& .MuiList-root": {
      paddingBottom: "59px !important",
    },

    "& .menu": {
      color: "#fff",
      "& svg": {
        color: "#fff",
      },

      "&.active": {
        background: "#ff314a",
      },
    },
  },
  drawerOpen: {
    width: drawerWidth,
    [theme.breakpoints.down("sm")]: {
      width: "100% !important",
    },

    overflow: "hidden",
    border: "none",
    background: "#0c0f18 !important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& .MuiList-root": {
      paddingLeft: 15,
      paddingRight: 15,
    },
    "& .menu": {
      borderRadius: 3,
    },
  },
  drawerClose: {
    overflow: "hidden",
    border: "none",
    background: "#0c0f18 !important",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    "& $iconLabs": {
      display: "block",
    },

    "& .MuiList-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },

    overflowX: "hidden",

    width: theme.spacing(7) + 1,
    [theme.breakpoints.down("sm")]: {
      width: "0 !important",
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    "& button": {
      color: "#fff",
    },
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    minHeight: "100vh",
  },

  iconPascoa: {
    width: 26,
    minWidth: 50,
    "& img": {
      width: "50%",
      display: "block",
    },
  },
}));

function Menu({
  menu,
  setMenu,
  openMenu,
  setOpenMenu,
  classes,
  title,
  signOutlogin,
}) {
  return (
    <>
      <div className={classes.toolbar}>
        {/* <span className={classes.appName}> <b>santo</b>Labs^</span> */}
        <IconButton onClick={() => setOpenMenu(!openMenu)} style={{ left: 4 }}>
          {openMenu ? <Close /> : <Dehaze />}
        </IconButton>
      </div>

      <List>
        <ListItem
          component="a"
          href="/home"
          className={`menu ${title === "Início" && "active"}`}
        >
          <ListItemIcon>
            {" "}
            <Home />{" "}
          </ListItemIcon>
          <ListItemText primary={"Início"} />
        </ListItem>

        <ListItem
          button
          onClick={(e) => {
            setMenu({ atendimento: !menu.atendimento });
            if (!openMenu) setOpenMenu(!openMenu);
          }}
          className={`menu`}
        >
          <ListItemIcon>
            <ContactPhoneIcon />
          </ListItemIcon>
          <ListItemText primary="Atendimento" />
          {menu.atendimento ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={menu.atendimento} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component="a"
              href="/atendimento/novo"
              className={`menu ${title === "Atendimento" && "active"}`}
            >
              <ListItemIcon>
                <PermPhoneMsgIcon />
              </ListItemIcon>
              <ListItemText primary={"Atendimento novo"} />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={(e) => {
            setMenu({ dashboard: !menu.dashboard });
            if (!openMenu) setOpenMenu(!openMenu);
          }}
          className={`menu`}
        >
          <ListItemIcon>
            <DashboardOutlined />
          </ListItemIcon>
          <ListItemText primary="Dashboards" />
          {menu.dashboard ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={menu.dashboard} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component="a"
              href="/dashboard/expedicao"
              className={`menu ${title === "Expedição" && "active"}`}
            >
              <ListItemIcon>
                <LocalShipping />
              </ListItemIcon>
              <ListItemText primary="Expedição" />
            </ListItem>
            <ListItem
              component="a"
              href="/dashboard/expedicao-v2"
              className={`menu ${title === "Expedição-v2" && "active"}`}
            >
              <ListItemIcon>
                <LocalShipping />
              </ListItemIcon>
              <ListItemText primary="Expedição-v2" />
            </ListItem>

            <ListItem
              component="a"
              href="/dashboard/carrinho-abandonado"
              className={`menu ${title === "Carrinho Abandonado" && "active"}`}
            >
              <ListItemIcon>
                <ShoppingBasketIcon />
              </ListItemIcon>
              <ListItemText primary="Carrinho Abandonado" />
            </ListItem>

            <ListItem
              component="a"
              href="/dashboard/metas-gerais"
              className={`menu ${title === "Metas Gerais" && "active"}`}
            >
              <ListItemIcon>
                <ShowChart />
              </ListItemIcon>
              <ListItemText primary="Metas Gerais" />
            </ListItem>

            <ListItem
              component="a"
              href="/dashboard/quantidade-de-pedidos"
              className={`menu ${
                title === "Quantidade de pedidos" && "active"
              }`}
            >
              <ListItemIcon>
                <AddShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Quantidade de pedidos" />
            </ListItem>

            <ListItem
              component="a"
              href="/dashboard/cascas"
              className={`menu ${title === "Dashboard Cascas" && "active"}`}
            >
              <Box className={classes.iconPascoa}>
                <img src={iconPascoa} alt="Pascoa" />
              </Box>
              <ListItemText primary="Cascas" />
            </ListItem>

            {/*

          {/* <ListItem component="a" href="/dashboard/sac" className={`menu ${title === 'dashboard sac' && 'active'}`}>
            <Box className={classes.iconPascoa}>
              <ListItemIcon>
                <ContactsIcon />
              </ListItemIcon>
            </Box>
            <ListItemText primary="Dashboard sac" />
          </ListItem> */}
            <ListItem
              component="a"
              href="/dashboard/sac-v2"
              className={`menu ${title === "dashboard sac-v2" && "active"}`}
            >
              <Box className={classes.iconPascoa}>
                <ListItemIcon>
                  <ContactsIcon />
                </ListItemIcon>
              </Box>
              <ListItemText primary="Dashboard sac-v2" />
            </ListItem>

            <ListItem
              component="a"
              href="/dashboard/televendas"
              className={`menu`}
            >
              <ListItemIcon>
                <LineStyleRounded />
              </ListItemIcon>
              <ListItemText primary="Televendas" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={(e) => {
            setMenu({ ebook: !menu.ebook });
            if (!openMenu) setOpenMenu(!openMenu);
          }}
          className={`menu`}
        >
          <ListItemIcon>
            <BookmarkBorderIcon />
          </ListItemIcon>
          <ListItemText primary="Ebook" />
          {menu.ebook ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={menu.ebook} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component="a"
              href="/ebook/novo"
              className={`menu ${title === "Ebook" && "active"}`}
            >
              <ListItemIcon>
                <BookIcon />
              </ListItemIcon>
              <ListItemText primary={"Ebook novo"} />
            </ListItem>
            <ListItem
              component="a"
              href="/ebook/listar"
              className={`menu ${title === "Listar ebook" && "active"}`}
            >
              <ListItemIcon>
                <BookmarksIcon />
              </ListItemIcon>
              <ListItemText primary={"Listar ebook"} />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          component="a"
          href="/gerenciamento-sku"
          className={`menu ${title === "SKUs" && "active"}`}
        >
          <ListItemIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="25"
              height="25"
              fill="#fff"
            >
              <g id="_06-cart" data-name="06-cart">
                <g id="linear_color-2" data-name="linear color">
                  <path d="M480,6H32A26.03,26.03,0,0,0,6,32V384a26.03,26.03,0,0,0,26,26H208.671l-6.806,54.431a238.492,238.492,0,0,0-47.412,23.249A10,10,0,0,0,160,506H352a10,10,0,0,0,5.547-18.32,238.492,238.492,0,0,0-47.412-23.249L303.329,410H480a26.03,26.03,0,0,0,26-26V32A26.03,26.03,0,0,0,480,6ZM32,26H480a6.006,6.006,0,0,1,6,6V310H26V32A6.006,6.006,0,0,1,32,26ZM208,486h-6.526c15.893-6.393,35.309-12,54.526-12,19.148,0,38.556,5.607,54.459,12H208ZM289.2,458.16a134.521,134.521,0,0,0-66.39,0L228.827,410h54.346ZM486,384a6.006,6.006,0,0,1-6,6H32a6.006,6.006,0,0,1-6-6V330H486Z" />
                  <path d="M240,370h32a10,10,0,0,0,0-20H240a10,10,0,0,0,0,20Z" />
                  <path d="M104,74h24.792l45.721,137.162A9.969,9.969,0,0,0,176,214h0a26.036,26.036,0,1,0,13.831,4h83.462c-.188,1.976-.293,3.976-.293,6a63,63,0,1,0,63-63c-1.987,0-3.949.1-5.89.284l15.377-46.121A10,10,0,0,0,336,102H208a10,10,0,0,0,0,20H322.125l-9.348,28.039c-.257-.02-.515-.039-.777-.039H224a10,10,0,0,0,0,20h79.589a63.409,63.409,0,0,0-24.951,28h-87.43L145.487,60.838A10,10,0,0,0,136,54H104a10,10,0,0,0,0,20Zm72,172a6,6,0,1,1,6-6A6.006,6.006,0,0,1,176,246Zm203-22a43,43,0,1,1-43-43A43.049,43.049,0,0,1,379,224Z" />
                  <path d="M315.528,232.944l16,8a10,10,0,0,0,12.792-3.4l16-24a10,10,0,0,0-16.64-11.094l-11.1,16.655-8.1-4.052a10,10,0,0,0-8.944,17.888Z" />
                </g>
              </g>
            </svg>
          </ListItemIcon>
          <ListItemText primary={"SKUs"} />
        </ListItem>

        <ListItem
          button
          onClick={(e) => {
            setMenu({ embalei: !menu.embalei });
            if (!openMenu) setOpenMenu(!openMenu);
          }}
          className={`menu`}
        >
          <ListItemIcon>
            <IconPackage />
          </ListItemIcon>
          <ListItemText primary="Embalei" />
          {menu.embalei ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={menu.embalei} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component="a"
              href="/embalei/pesquisa"
              className={`menu ${title === "Pesquisa Embalei" && "active"}`}
            >
              <ListItemIcon>
                {" "}
                <Search />{" "}
              </ListItemIcon>
              <ListItemText primary={"Pesquisar"} />
            </ListItem>
            <ListItem
              component="a"
              href="/embalei/ranking"
              className={`menu ${title === "Ranking Embalei" && "active"}`}
            >
              <ListItemIcon>
                <BarChart />
              </ListItemIcon>
              <ListItemText primary="Ranking" />
            </ListItem>

            <ListItem
              component="a"
              href="/embalei/meta"
              className={`menu ${title === "Meta Embalei" && "active"}`}
            >
              <ListItemIcon>
                <IconGoal />
              </ListItemIcon>
              <ListItemText primary="Meta" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={(e) => {
            setMenu({ cascas: !menu.cascas });
            if (!openMenu) setOpenMenu(!openMenu);
          }}
          className={`menu`}
        >
          <Box className={classes.iconPascoa}>
            <img src={iconPascoa} alt="Pascoa" />
          </Box>
          <ListItemText primary="Cascas" />
          {menu.separaCasas ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={menu.cascas} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component="a"
              href="/cascas/adicionar-cascas"
              className={`menu ${title === "Adicionar Cascas" && "active"}`}
            >
              <ListItemIcon>
                {" "}
                <EmojiObjectsIcon />{" "}
              </ListItemIcon>
              <ListItemText primary={"Adicionar Cascas"} />
            </ListItem>
            <ListItem
              component="a"
              href="/cascas/separar-cascas-v2"
              className={`menu ${title === "Separar Cascas" && "active"}`}
            >
              <ListItemIcon>
                <ShoppingBasketIcon />
              </ListItemIcon>
              <ListItemText primary="Separar Cascas" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={(e) => {
            setMenu({ apps: !menu.apps });
            if (!openMenu) setOpenMenu(!openMenu);
          }}
          className={`menu`}
        >
          <ListItemIcon>
            <PhoneIphone />
          </ListItemIcon>
          <ListItemText primary="Aplicações" />
          {menu.apps ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={menu.apps} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component="a"
              href="http://www.intranet.santolabs.com.br"
              target="_blank"
              className={`menu ${title === "Intranet" && "active"}`}
            >
              <ListItemIcon>
                <Business />
              </ListItemIcon>
              <ListItemText primary="Intranet" />
            </ListItem>

            <ListItem
              component="a"
              href="http://developer.romulopavan.com.br/"
              target="_blank"
              className={`menu ${title === "Separa" && "active"}`}
            >
              <ListItemIcon>
                <Speed />
              </ListItemIcon>
              <ListItemText primary="Separa" />
            </ListItem>

            <ListItem
              component="a"
              href="http://cursos.santolabs.com.br/"
              target="_blank"
              className={`menu ${title === "Cursos" && "active"}`}
            >
              <ListItemIcon>
                <Cake />
              </ListItemIcon>
              <ListItemText primary="Cursos" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem
          button
          onClick={(e) => {
            setMenu({ settings: !menu.settings });
            if (!openMenu) setOpenMenu(!openMenu);
          }}
          className={`menu`}
        >
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Configurações" />
          {menu.settings ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={menu.settings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem
              component="a"
              href="/configuracao/metas-gerais"
              className={`menu ${
                title === "Configuração Metas Gerais" && "active"
              }`}
            >
              <ListItemIcon>
                <ShowChart />
              </ListItemIcon>
              <ListItemText primary="Metas Gerais" />
            </ListItem>

            <ListItem
              component="a"
              href="/configuracao/menu"
              className={`menu ${title === "Menu" && "active"}`}
            >
              <ListItemIcon>
                <FormatListBulleted />
              </ListItemIcon>
              <ListItemText primary="Menu" />
            </ListItem>

            <ListItem
              component="a"
              href="/configuracao/usuarios"
              className={`menu ${title === "Usuários" && "active"}`}
            >
              <ListItemIcon>
                <Person />
              </ListItemIcon>
              <ListItemText primary="Usuários" />
            </ListItem>
          </List>
        </Collapse>

        <ListItem component="a" href="/retiraja" className={`menu`}>
          <ListItemIcon>
            <DoubleArrowOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Retira Já" />
        </ListItem>

        <ListItem component="a" href="/jornal-ofertas" className={`menu`}>
          <ListItemIcon>
            <Receipt />
          </ListItemIcon>
          <ListItemText primary="Jornal de Ofertas" />
        </ListItem>

        <ListItem button onClick={signOutlogin} className={`menu`}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>

      {openMenu && (
        <Santolabs
          style={{
            position: "absolute",
            top: 40,
            width: "100%",
            textAlign: "center",
          }}
        >
          <span>santo</span>
          <span>Labs</span>
          <span>^</span>
        </Santolabs>
      )}

      <div className={classes.iconLabs}>
        <img src={Logo} alt="Logo SantoLabs^" />
      </div>
    </>
  );
}

function Dashboard(props) {
  const { children, title, fullscreen } = props;

  const dispatch = useDispatch();

  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);
  const [menu, setMenu] = useState({});

  function signOutlogin() {
    dispatch(signOut());
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={"left"}
          open={openMenu}
          onClose={() => setOpenMenu(!openMenu)}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openMenu,
            [classes.drawerClose]: !openMenu,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: openMenu,
              [classes.drawerClose]: !openMenu,
            }),
          }}
        >
          <Menu
            menu={menu}
            setMenu={setMenu}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            classes={classes}
            title={title}
            signOutlogin={signOutlogin}
          />
        </Drawer>
      </Hidden>

      <Hidden smDown>
        <Drawer
          style={{ display: fullscreen && "none" }}
          variant={"permanent"}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: openMenu,
            [classes.drawerClose]: !openMenu,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: openMenu,
              [classes.drawerClose]: !openMenu,
            }),
          }}
        >
          <Menu
            menu={menu}
            setMenu={setMenu}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            classes={classes}
            title={title}
            signOutlogin={signOutlogin}
          />
        </Drawer>
      </Hidden>

      <main className={classes.content}>
        <Hidden mdUp>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <IconButton onClick={() => setOpenMenu(!openMenu)}>
              <Dehaze />
            </IconButton>
          </Box>
        </Hidden>
        {children}
      </main>
    </div>
  );
}

export default memo(Dashboard);
