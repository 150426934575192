import React, { useState, useEffect } from 'react';
import Dashboard from '../../Components/Dashboard';

import { Box, Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { Add, Delete, Edit } from '@material-ui/icons';
import { IconDelete, IconUpdate, SubmitButton, TableCellHead, TitleTable } from '../../Components/Custom';
import { apiLabs } from '../../Services/api';
import { store } from '../../store';
import Denied from '../../Components/Denied';
import history from '../../Services/history';

function Users(props) {

	const { location: { idPage } } = props

	async function verifyAuth() {
		const { profile } = store.getState().user
		const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
		if (res.data.message === "User not authorized") {
			return setAuth('denied')
		}
		if (res.data.message === "User authorized") {
			return setAuth('authorized')
		}
		return history.push({ pathname: '/', state: { from: props.location } })
	}

	const [auth, setAuth] = useState(null)

	const [users, setUsers] = useState([])

	async function getUsers() {
		const res = await apiLabs.get('user')
		setUsers(res.data.users)
	}

	useEffect(() => {
		if (auth === 'authorized') {
			getUsers()
		}
	}, [auth])

	useEffect(() => {
		verifyAuth()
	}, [])

	return (
		<Dashboard title="Configuração de Usuário">

			{auth === 'denied' && <Denied />}

			{auth === 'authorized' &&
				<Container maxWidth="md">
					<Box display="flex" justifyContent="space-between" paddingBottom={3} paddingTop={3} >
						<TitleTable>Lista de Usuários</TitleTable>

						<SubmitButton component="a" href="/configuracao/usuarios/novo" startIcon={<Add />} size="large">
							Novo Usuário
				</SubmitButton>
					</Box>

					<Paper style={{ borderRadius: 5, overflow: 'hidden' }}>
						<TableContainer>
							<Table stickyHeader >
								<TableHead>
									<TableRow>
										<TableCellHead>ID</TableCellHead>
										<TableCellHead>Nome</TableCellHead>
										<TableCellHead align="center">Opções</TableCellHead>
									</TableRow>
								</TableHead>

								<TableBody>
									{users.map(user => (
										<TableRow key={`user-${user.id}`} >
											<TableCell width={80}>{user.id}</TableCell>
											<TableCell>{user.user}</TableCell>
											<TableCell width={150}>
												<IconUpdate component="a" href={`/configuracao/usuarios/editar/${user.id}`}>
													<Edit />
												</IconUpdate>

												<IconDelete>
													<Delete />
												</IconDelete>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Paper>

				</Container>
			}
		</Dashboard>
	);
}

export default Users;