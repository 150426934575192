import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
	paddingBox: {

		[theme.breakpoints.down('sm')]: {
			padding: 10
		},


		[theme.breakpoints.up('md')]: {
			padding: 25
		},
	},
	inputMargin:{
marginRight: 5
	},
	div:{
		alignItems: 'center',
		display: 'flex',
		flexWrap:'wrap',

		width: '100%',
		'& div':{
			
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'space-between',
				width: '100%'
				},
			display: 'flex',
		} 
		
	},
	ButtonFilter:{
		height: 'max-content',
		marginLeft:'20px',

		[theme.breakpoints.down('sm')]: {
			marginTop:'20px',
			marginLeft:'0px',
			width: '100%'
		},

		background:'orange',
		color:'#fff',
		'&:hover': {
			background: 'orange',
			color:'#fff',
		  }
	},
	boxSquare: {
		[theme.breakpoints.up('md')]: {
			// height: 449,

			'& > div': {
				height: '100%',
			}
		}
	},

}))

export default useStyle