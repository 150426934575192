import React, { useState, useEffect } from 'react';

import { Box, Container, Grid, Hidden, IconButton, TextField, Typography } from '@material-ui/core'
import useStyle from './style';
import Dashboard from '../../Components/Dashboard';
import { Fullscreen } from '@material-ui/icons';
import history from '../../Services/history';
import { apiLabs, apiAttedance } from '../../Services/api';
import { store } from '../../store';
import Denied from '../../Components/Denied';
import { format, parseISO } from "date-fns"


function Sac(props) {

    const { location: { idPage } } = props

    async function verifyAuth() {
        const { profile } = store.getState().user
        const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
        if (res.data.message === "User not authorized") {
            return setAuth('denied')
        }
        if (res.data.message === "User authorized") {
            return setAuth('authorized')
        }
        return history.push({ pathname: '/', state: { from: props.location } })
    }

    const [fullscreen, setFullscreen] = useState(false)
    const [auth, setAuth] = useState(null)
    const [totalDay, setTotalDay] = useState({})

    const date = new Date()
    const Year = date.getFullYear()
    const Month = date.getMonth() <= 9 ? `0${date.getMonth() + 1}` : date.getDate() >= 10 && date.getMonth() + 1
    const Day = date.getDate() <= 9 ? `0${date.getDate() - 1}` : date.getDate() >= 10 && date.getDate() - 1
    const dateFormated = format(parseISO(`${Year}-${Month}-${Day}`), 'yyyy-MM-dd')

    const getAttedance = async () => {
        try {
            const res = await apiAttedance.get('atendimento_ontem')
            if (res.data.message === 'Nenhum atendimento encontrado') {
                clearTotalDay()
                return
            }
            setTotalDay({
                ...totalDay,
                ...res.data
            })

        } catch (error) {
            console.log(error)
        }
    }


    async function handleChangeDate(e) {
        const date = e.target.value
        try {
            const res = await apiAttedance.get(`${date}`)
            if (res.data.message === 'Nenhum atendimento encontrado') {
                clearTotalDay()
                return
            }
            setTotalDay({
                ...totalDay,
                ...res.data
            })

        } catch (error) {
            console.log(error)
        }
    }

    function clearTotalDay() {
        setTotalDay({
            emails_qtd: 0,
            emails_pendent_date: 0,
            emails_pendent_time: 0,
            emails_in_progress: 0,
            emails_finished: 0,
            whatsface_qtd: 0,
            whatsface_pedent_date: 0,
            whatsface_in_progress: 0,
            whatsface_finished: 0,
            whatsface_pedent_time: 0,
            orders_analisys: 0,
            orders_analyzed: 0,
            phones_answers: 0,
            phones_lost: 0
        })
    }

    useEffect(() => {
        getAttedance()
    }, [])

    useEffect(() => {
        verifyAuth()
    }, [])

    function requestFull() {
        document.documentElement.requestFullscreen()
    }

    window.addEventListener('resize', function () {
        if (window.screenTop !== 0) {
            setFullscreen(true)
        } else {
            setFullscreen(false)
        }
    })

    const style = useStyle()

    return (
        <>
            <Dashboard title="Sac" fullscreen={fullscreen}>
                {auth === 'denied' && <Denied />}
                {auth === 'authorized' &&
                    <>
                        <Container className={`${style.fullWidth}`} >
                            <Grid className={`${style.filter}`}>
                                <TextField
                                    id="date"
                                    label="Data"
                                    type="date"
                                    defaultValue={dateFormated}
                                    onChange={handleChangeDate}
                                    className={style.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid className={`${style.attendanceContainer}`}>


                                <Box className={`${style.box}`}>
                                    <Box className={`${style.boxContent}`}>
                                        <Typography className={`${style.title}`}>Quantidade de Emails</Typography>
                                        {totalDay.emails_pendent_time ?
                                            <p
                                            style={{
                                                color: "rgb(127, 130, 143)",
                                                fontSize: 17,
                                                paddingTop: 5 }} >Último horário {totalDay && totalDay.emails_pendent_time}</p>
                                            : ''}
                                        <Typography className={`${style.content}`}>{totalDay ? totalDay.emails_qtd : 0}</Typography>
                                    </Box>
                                    <Box className={`${style.boxContent}`}>
                                        <Typography className={`${style.title}`}>Emails finalizados </Typography>
                                        <Typography className={`${style.content}`}>{totalDay ? totalDay.emails_finished : 0}</Typography>
                                    </Box>
                                    <span></span>
                                    <Box className={`${style.boxContent}`}>
                                        <Typography className={`${style.title}`}>Emails em progresso </Typography>
                                        <Typography className={`${style.content}`}>{totalDay ? totalDay.emails_in_progress : 0}</Typography>
                                    </Box>
                                </Box>
                                <Box className={`${style.box} ${style.colorBoxBackgroundGreen}`}>
                                    <Box className={`${style.boxContent} ${style.boxGreen}`}>
                                        <Typography className={`${style.colorTextWhite}`}>Whatsapp/Facebook </Typography>
                                        {totalDay.whatsface_pedent_time ?
                                            <p
                                            style={{ color: "#fff",
                                            fontSize: 17,
                                            paddingTop: 5 }} >Último horário {totalDay ? totalDay.whatsface_pedent_time : 0}</p>
                                            : ''}
                                        <Typography className={`${style.contentTextWhite}`}>{totalDay ? totalDay.whatsface_qtd : 0}</Typography>
                                    </Box>
                                    <Box className={`${style.boxContent} ${style.boxGreen}`}>
                                        <Typography className={`${style.colorTextWhite}`}>Whatsapp/Facebook <br /> Finalizados </Typography>
                                        <Typography className={`${style.contentTextWhite}`}>{totalDay ? totalDay.whatsface_finished : 0}</Typography>
                                    </Box>
                                    <span></span>
                                    <Box className={`${style.boxContent} ${style.boxGreen}`}>
                                        <Typography className={`${style.colorTextWhite}`}>Whatsapp/Facebook <br /> em progresso </Typography>
                                        <Typography className={`${style.contentTextWhite}`}>{totalDay ? totalDay.whatsface_in_progress : 0}</Typography>
                                    </Box>
                                </Box>
                                <Box className={`${style.box}`}>
                                    <Box className={`${style.boxContent}`}>
                                        <Typography className={`${style.title}`}>Telefone atendidos </Typography>
                                        <Typography className={`${style.content}`}>{totalDay ? totalDay.phones_answers : 0}</Typography>
                                    </Box>
                                    <Box className={`${style.boxContent}`}>
                                        <Typography className={`${style.title}`}>Telefone Perdidos </Typography>
                                        <Typography className={`${style.content}`}>{totalDay ? totalDay.phones_lost : 0}</Typography>
                                    </Box>
                                    <span></span>
                                    <Box className={`${style.boxContent}`}>
                                        <Typography className={`${style.title}`}>Pedidos em analise </Typography>
                                        <Typography className={`${style.content}`}>{totalDay ? totalDay.orders_analisys : 0}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Container>

                        <Hidden mdDown>
                            {!fullscreen &&
                                <IconButton onClick={requestFull} className={style.btnFullscreen}>
                                    <Fullscreen />
                                </IconButton>
                            }
                        </Hidden>

                    </>
                }

            </Dashboard>


        </>
    )
}

export default Sac;
