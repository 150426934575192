import React from 'react';

import { Container, Typography } from '@material-ui/core';

function Denied() {
	return(
		<>
			<Container maxWidth="md">
				<h2> Acesso Negado </h2>
				<Typography>
					Você não tem permissão para acessar esta página.
				</Typography>
			</Container>
		</>
	);
}

export default Denied;