import { Container, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import IntlCurrencyInput from "react-intl-currency-input"
import { SubmitButton } from '../../../Components/Custom';
import Dashboard from '../../../Components/Dashboard';
import PropTypes from 'prop-types'

import styleConfigFullGoals from './styles';
import { apiLabs } from '../../../Services/api';
import { toast } from 'react-toastify';
import { store } from '../../../store';
import history from '../../../Services/history';
import Denied from '../../../Components/Denied';

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

function CashInput(props) {
  const { inputRef, ...other } = props;
  return (
    <IntlCurrencyInput
      currency="BRL"
      config={currencyConfig}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...other}
    />
  )
}

CashInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function ConfigFullGoals(props) {

  const [openModal, setOpenModal] = useState(false)
  const { profile } = store.getState().user

  const { location: { idPage } } = props
  const [auth, setAuth] = useState('authorized')
  // const [user, setUser] = useState({
  //   name: '',
  //   local: '',
  //   showSite: false,
  //   showStore: false,
  //   showTotal: false
  // })

  const style = styleConfigFullGoals()

  const [data, setData] = useState({
    days: '',
    goal_site: 0,
    goal_store: 0
  })

  function handleValue(e) {
    const { name, value } = e.target

    console.log(e.target.value)
    setData({ ...data, [name]: value })
  }

  function handleMoney(e, value) {
    setData({
      ...data,
      [e.target.name]: value
    })
  }

  async function envData(e) {
    e.preventDefault()
    console.log(data.goal_site, data.goal_store)
    try {
      const res = await apiLabs.put('/metas/meta_geral', {
        meta_site: data.goal_site,
        meta_loja: data.goal_store,
        mes: data.days
      })

      toast.success(res.data.message)

    } catch (error) {
      toast.warning(`Falha a atualizar a meta: ${error}`)
    }
  }

  async function getGoals() {
    try {
      const res = await apiLabs.get('/metas/meta_geral')
      console.log(res)
      setData({
        days: res.data[0].mes,
        goal_site: res.data[0].meta_site,
        goal_store: res.data[0].meta_loja
      })
    } catch (error) {
      console.log(error)
    }
  }

  async function verifyAuth() {

    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      setAuth('authorized')
      getGoals()
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }

  useEffect(() => {
    verifyAuth()
  }, [])

  return (
    <Dashboard title='Configurações Metas Gerais'>
      {auth === 'denied' &&
        <Denied />
      }

      {auth === 'authorized' &&
        <Container maxWidth='xs'>

          <Typography className={style.titleForm}>Metas Gerais</Typography>

          <form onSubmit={envData}>
            <Grid container spacing={2}>

              <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel>Total de dias</InputLabel>
                  <OutlinedInput
                    value={data.days}
                    name='days'
                    onChange={e => handleValue(e)}
                    label='Total de dias'
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel shrink>Faturamento Mensal Site</InputLabel>
                  <OutlinedInput
                    value={data.goal_site}
                    name='goal_site'
                    onChange={handleMoney}
                    label='Faturamento Mensal Site'
                    inputComponent={CashInput}
                    notched
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl variant='outlined' fullWidth>
                  <InputLabel shrink>Faturamento Mensal Loja</InputLabel>
                  <OutlinedInput
                    name="goal_store"
                    value={data.goal_store}
                    onChange={handleMoney}
                    label='Faturamento Mensal Loja'
                    inputComponent={CashInput}
                    notched
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <SubmitButton type='submit' fullWidth>salvar</SubmitButton>
              </Grid>

            </Grid>
          </form>

        </Container>
      }

    </Dashboard>
  )
}

export default ConfigFullGoals;
