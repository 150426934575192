import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({

    boxPrint: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },

    printImg: {
        width: '400px',
        marginBottom: '-20px',
    
        '& img': {
            display: "block",
            maxWidth: '100%',
            height: 'auto',
        }
    },

    PrintOrderPdv: {
        display: "flex",
        alignItems: 'baseline',
        marginBottom: '-30px',
    
        '& :nth-child(1)': {
            fontSize: '50px',
            fontWeight: 'bold',
            marginRight: '10px',
        },
    
        '& :nth-child(2)': {
            fontSize: '90px',
            fontWeight: 'bold',
        }
    },

    PrintNumberOrder: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: "10px",
    
        '& :nth-child(1)': {
            fontSize: '40px',
            color: '#a9a5a5',
            fontWeight: 'bold',
            marginRight: '5px',
    
        },
    
        '& :nth-child(2)': {
            fontSize: '40px',
            color: '#a9a5a5',
            fontWeight: 'bold',
        }
    },

    PrintQuantaty: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '10px',
    },
    
    small: {
        display: 'flex',
    
        '& :nth-child(1)': {
            fontSize: '50px',
            marginRight: '10px',
        },
    
        '& :nth-child(2)': {
            fontSize: '50px',
        }
    },
    
    printTotal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexDirection: 'column',
        borderTop: '1px dotted #000',
        borderBottom: '1px dotted #000',
        height: '160px',
    },

    total:{
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'center',
        marginRight: '20px',
        marginBottom: '-20px',  
        borderBottom: '1px dotted',
    
        '& :nth-child(1)': {
            marginRight: "10px",
            fontSize: '40px',
        },
    
        '& :nth-child(2)': {
            marginRight: "10px",
            fontSize: '60px',
            fontWeight: 'bold'
        },
    
        '& :nth-child(3)': {
            fontSize: '30px',
        }
    },

    payment: props =>({
        display: 'flex',
        alignItems:'center',
        justifyContent: 'center',
        marginTop : '20px',

        '& :nth-child(1)': {
            fontSize: props.fontPayment,
            textAlign: 'center'
        },
    }),

    imgPayment:{
        width:'45px',

        '& :nth-child(1)': {
            width: '100%',
            display: 'block',
            marginLeft: '10px',
        },
    }

}))

export default useStyle