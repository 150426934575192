import React from 'react';
import Dashboard from '../../Components/Dashboard';

function Home() {
	return (
		<>
			<Dashboard title="Início">
				
			</Dashboard>

		</>
	)
}

export default Home;