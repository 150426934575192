import { apiMillennium } from '../../api'

const Goal = async (type, value) => {
	let res;

	if(type === 'get'){
		res = await apiMillennium.get('/meta')
	}

	if(type === 'put'){
		res = await apiMillennium.put('/meta', { meta_faturados: value })
	}

	return res
}

export default Goal;