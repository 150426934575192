import React, { useEffect, useState, useRef, useMemo, forwardRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import axios from "axios";

import history from '../../Services/history';
import { store } from '../../store';
import { apiLabs } from '../../Services/api';
import getEggsShells from '../../Services/Api/apiEggShell/getEggShell';


import Denied from '../../Components/Denied';
import Dashboard from '../../Components/Dashboard';

import styleMain from "./style";

import { Box, Button, Container, Dialog, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

function NewEggSeparateShell(props) {

    const { location: { idPage } } = props
    const [auth, setAuth] = useState(null)
    const [fullscreen, setFullscreen] = useState(false)
    const refModal = useRef()
    const modalRef = useReactToPrint({
        content: () => refModal.current
    })
    const style = styleMain()
    const printOrder = useRef()
    const [getOrder, setOrder] = useState({
        type: '',
        data: []
    })
    const [modal, setModal] = useState(false)
    const [data, setData] = useState({
        orders: []
    })

    const openWindowPrint = useReactToPrint({
        content: () => printOrder.current,
    })

    const date = new Date()

    async function verifyAuth() {
        const { profile } = store.getState().user
        const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
        if (res.data.message === "User not authorized") {
            return setAuth('denied')
        }
        if (res.data.message === "User authorized") {
            return setAuth('authorized')
        }
        return history.push({ pathname: '/', state: { from: props.location } })
    }

    function requestFull() {
        document.documentElement.requestFullscreen()
    }

    window.addEventListener('resize', function () {
        if (window.screenTop !== 0) {
            setFullscreen(true)
        } else {
            setFullscreen(false)
        }
    })

    async function getOrders() {
        const res = await getEggsShells()

        const awaitSeparate = res.data.filter(o => o.status === 'Aguardando Separacao')
        const oldSeparate = localStorage.getItem('orders')


        if (awaitSeparate.length > 0) {
            if (oldSeparate === '') {
                localStorage.setItem('orders', '0')
            }
            if (awaitSeparate.length > parseInt(localStorage.getItem('orders'))) {
                console.log('som')
                const somPlay = document.getElementById('audio').play()
                if (somPlay !== undefined) {
                    somPlay
                        .then(() => {
                            // console.log("audio played auto");
                        })
                        .catch(() => {
                            // console.log("playback prevented");
                        });
                }

            }
            localStorage.setItem('orders', awaitSeparate.length)
        } else {
            localStorage.setItem('orders', '0')
        }

        setData({
            ...data,
            orders: res.data
        })
    }

    async function handlePrint(id) {
        const obj = data.orders
        const updatedOrder = obj.filter(o => o.id === id)[0]


        updatedOrder.total = parseInt(updatedOrder.mini) + parseInt(updatedOrder.p_leite) + parseInt(updatedOrder.m_leite) +
            parseInt(updatedOrder.g_leite) + parseInt(updatedOrder.m_amargo) + parseInt(updatedOrder.m_branco) + parseInt(updatedOrder.m_belga);
        setOrder({ type: 'print', data: updatedOrder })

        if (updatedOrder.status === 'Aguardando Separacao') {
            obj.splice(obj.indexOf(updatedOrder), 1)
            updatedOrder.status = 'Separado'
            try {
                await apiLabs.put(`separa/painel_cascas/${updatedOrder.pedido}`, { status: "Separado" })
            } catch (error) {

            }
            obj.push(updatedOrder)
            setData({ ...data, orders: obj })
        }
    }

    function handleModal() {
        setModal(!modal)
    }

    function handleAction(id, type) {
        const order = data.orders.filter(o => o.id === id)[0]

        setOrder({
            type: type,
            data: order,
        })
    }

    async function orderDeliverCancel(order, type) {
        const obj = data.orders
        if (type === 'cancel') {
            if (order.status_pgt !== 'Pago' && order.status === 'Separado' || order.status === 'Aguardando Separacao') {
                obj.splice(obj.indexOf(order), 1)
                setData({ ...data, orders: obj })
                try {
                    const res = await apiLabs.put(`separa/painel_cascas/${order.pedido}`, { status: "Cancelado" })
                    handleModal()
                } catch (error) {

                }
            }
        }

        if (type === 'deliver') {
            if (order.status_pgt === 'Pago') {
                obj.splice(obj.indexOf(order), 1)
                setData({ ...data, orders: obj })
                try {
                    const res = await apiLabs.put(`separa/painel_cascas/${order.pedido}`, { status: "Entregue" })
                    handleModal()
                } catch (error) {

                }
            }
        }
        if (type === 'dismantle') {
            if (order.status === 'Desmanchar') {
                obj.splice(obj.indexOf(order), 1)
                setData({ ...data, orders: obj })
                try {
                    const res = await apiLabs.put(`separa/painel_cascas/${order.pedido}`, { status: "Cancelado" })
                    handleModal()
                } catch (error) {

                }
            }
        }


    }

    function time() {
        setInterval(() => {
            getOrders()
        }, 2000)
    }

    useEffect(() => {
        if (getOrder.type === 'cancel' || getOrder.type === 'deliver' || getOrder.type === 'dismantle') {
            handleModal()
        }
        if (getOrder.type === 'print') {
            openWindowPrint()
        }
    }, [getOrder])

    useEffect(() => {
        if (auth === 'authorized') {
            getOrders()
            time()
        }
    }, [auth])

    useEffect(() => {
        verifyAuth()
    }, [])
    return (
        <>
            <Dashboard title="Separa Cascas" fullscreen={fullscreen}>
                {auth === 'denied' && <Denied />}
                {auth === 'authorized' &&
                    <Container maxWidth="xl" className={style.root}>
                        <Box style={{ height: 0, width: 0 }}>
                            <audio
                                style={{ display: 'none' }}
                                id="audio"
                                src='http://santolabs.com.br/static/media/som_novo.mp3'></audio>
                        </Box>
                        <Table stickyHeader className={style.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={`${style.titleColumn} brown`}>
                                        <Box display='flex' flexDirection='column' alignItems='center' className='box-titles'>
                                            <span>Pedido</span>
                                            <span>PDV</span>
                                        </Box>
                                    </TableCell>

                                    {/* <TableCell className={`${style.titleColumn} brown`}>
                                        <span>MINI</span>
                                    </TableCell>

                                    <TableCell className={`${style.titleColumn} brown`}>
                                        <span>P Leite</span>
                                    </TableCell>

                                    <TableCell className={`${style.titleColumn} brown`}>
                                        <span>M Leite</span>
                                    </TableCell>

                                    <TableCell className={`${style.titleColumn} brown`}>
                                        <span>G Leite</span>
                                    </TableCell>

                                    <TableCell className={`${style.titleColumn} dark-brown`}>
                                        <span>M Amargo</span>
                                    </TableCell>

                                    <TableCell className={`${style.titleColumn} orange`}>
                                        <span>M Branco</span>
                                    </TableCell>

                                    <TableCell className={`${style.titleColumn} brown`}>
                                        <span>M Belga</span>
                                    </TableCell> */}

                                    <TableCell className={`${style.titleColumn} brown`}>
                                        <span>Status</span>
                                    </TableCell>

                                    <TableCell className={`${style.titleColumn} brown`}>
                                        <span>Ações</span>
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.orders.map((o) => (
                                    <TableRow className={`${style.rowOrder} ${o.status === 'Aguardando Separacao' ? style.boxOrderSucess : o.status === 'Desmanchar' ? style.boxOrderCancel : ''}`} key={o.id} >
                                        <TableCell className={style.columnId}>
                                            <Box display='flex' flexDirection='column' alignItems='center' className='box-infos-id'>
                                                <span>{o.pedido}</span>
                                                <span>{o.pdv}</span>
                                                <span>{o.data_hora_emissao.split(' ')[0]}</span>
                                                <span>{o.data_hora_emissao.split(' ')[1]}</span>
                                            </Box>
                                        </TableCell>
                                        {/* <TableCell className={style.numberShells}>{o.mini > 0 ? o.mini : '-'}</TableCell>
                                        <TableCell className={style.numberShells}>{o.p_leite > 0 ? o.p_leite : '-'}</TableCell>
                                        <TableCell className={style.numberShells}>{o.m_leite > 0 ? o.m_leite : '-'}</TableCell>
                                        <TableCell className={style.numberShells}>{o.g_leite > 0 ? o.g_leite : '-'}</TableCell>
                                        <TableCell className={style.numberShells}>{o.m_amargo > 0 ? o.m_amargo : '-'}</TableCell>
                                        <TableCell className={style.numberShells}>{o.m_branco > 0 ? o.m_branco : '-'}</TableCell>
                                        <TableCell className={style.numberShells}>{o.m_belga > 0 ? o.m_belga : '-'}</TableCell> */}
                                        <TableCell className={style.labelStatus}>
                                            {o.status === 'Desmanchar' && <span>Desmanchar <br /> pedido </span> ||
                                            o.status_pgt !== '0' && <span>Pago</span> ||
                                            o.status_pgt === '0' && <span>Pagamento<br />pendente</span>
                                            }
                                        </TableCell>
                                        <TableCell className={style.actionsOrder}>
                                            <Box display='flex' justifyContent='center'>
                                                {o.status !== 'Desmanchar' &&
                                                    <>
                                                        <Button className={`button-action print ${o.status === 'Separado' && 'active'}`} onClick={e => handlePrint(o.id)}>Imprimir</Button>
                                                        <Button className='button-action deliver' disabled={o.status_pgt !== 'Pago' && true} onClick={e => handleAction(o.id, 'deliver')} >entregar</Button>
                                                    </>
                                                }
                                                {o.status === 'Desmanchar' && <Button className={`button-action  dismantle`} onClick={e => handleAction(o.id, 'dismantle')}>Cancelar</Button>}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>


                        <Dialog
                            open={modal}
                            onClose={handleModal}
                        >
                            <ContentModalDeliverCancel getOrder={getOrder} orderDeliverCancel={orderDeliverCancel} handleModal={handleModal} />
                        </Dialog>

                        <Box display="none">
                            <OrderForPrinter ref={printOrder} data={getOrder.data} />
                        </Box>

                    </Container>
                }
            </Dashboard >
        </>
    )
}

const ContentModalDeliverCancel = ({ getOrder, orderDeliverCancel, handleModal }) => {

    const { data, type } = getOrder
    const style = styleMain()

    return (
        <Box className={`${style.root} ${style.boxDeliverCancel}`}>
            <Typography className={`pdv-modal`}>
                <span className={`fs-20 bold`}>Nº PDV: </span>
                <span className={`pdv bold`}>{data.pdv}</span>
            </Typography>
            <Typography className={`order-modal`}>
                <span>Nº Pedido: </span>
                <span>{data.pedido}</span>
            </Typography>
            <Box display='flex' flexDirection='column' alignItems='center' className={`box-shells-modal`}>

                <Typography className={`fs-20 bold brown`}>MINI: {data.mini > 0 ? data.mini : '-'}</Typography>
                <Typography className={`fs-20 bold brown`}>PEQUENO: {data.p_leite > 0 ? data.p_leite : '-'}</Typography>
                <Typography className={`fs-20 bold brown`}>M LEITE: {data.m_leite > 0 ? data.m_leite : '-'}</Typography>
                <Typography className={`fs-20 bold brown`}>GRANDE: {data.g_leite > 0 ? data.g_leite : '-'}</Typography>
                <Typography className={`fs-20 bold dark-brown`}>M AMARGO: {data.m_amargo > 0 ? data.m_amargo : '-'}</Typography>
                <Typography className={`fs-20 bold orange`}>M BRANCO: {data.m_branco > 0 ? data.m_branco : '-'}</Typography>
                <Typography className={`fs-20 bold brown`}>M BELGA: {data.m_belga > 0 ? data.m_belga : '-'}</Typography>

            </Box>
            <Typography className={`fs-20`}>{type === 'cancel' && 'Realmente deseja cancelar este pedido ?' || type === 'deliver' && 'Confirma a entrega do pedido' || type === 'dismantle' && 'Realmente deseja cancelar este pedido ?'  }</Typography>
            <Typography className={`fs-20 bold`}>Nº PDV: {data.pdv} {type === 'deliver' && 'ao cliente ?'}</Typography>
            <Box>
                <Button onClick={handleModal} className={`btn-action back`}>voltar</Button>
                <Button onClick={e => orderDeliverCancel(data, type)} className={`btn-action execute-${type}`}>{type === 'deliver' ? 'ENTREGAR' : 'CANCELAR PEDIDO'}</Button>
            </Box>
        </Box>
    )
}

const OrderForPrinter = forwardRef((props, ref) => {
    const style = styleMain()
    const { data } = props
    return (
        <Box ref={ref} className={`${style.root} ${style.boxDeliverCancel} for-printer`}>
            <Typography className='logo'>
                <img src="http://santolabs.com.br/static/media/logo-loja.8203fc66.png" alt="" />
            </Typography>
            <Typography className={`pdv-modal`}>
                <span className={`fs-25 bold`}>Nº Pedido: </span>
                <span className={`pdv bold`}>{data.pedido}</span>
            </Typography>

            <Typography className={`order-modal fs-25`}>
                <span>Nº PDV:  </span>
                <span>{data.pdv}</span>
            </Typography>
            {data.items &&(
                <>
                {JSON.parse(data.items).map((item)=>{
                   return (
                    <>
                    <hr />
                    <br/>
                    <div><b>Nome: </b>{item.name} </div>
                    <div><b>Gtin: </b> {item.gtin}</div>
                    <div><b>Quantidade: </b> {item.qtd}</div>
                    <br/>
                    </>
                   )
                })}
                </>
            )

            }
            

           

            {/* <Typography>
                <span className={`fs-20 brown`}>Total de : </span> <span className={`fs-25 brown bold`}>{data.total}</span> <span className={`fs-20 brown`}>caixas</span>
            </Typography> */}

           

            {/* <Typography className={`label-status-pgt brown ${data.status_pgt === 'Pago' && 'paid'}`}>
                {data.status_pgt === '0' ? 'PENDENTE' : 'PAGO'}
            </Typography> */}

        </Box>
    )
})

export default NewEggSeparateShell;
