const { makeStyles } = require("@material-ui/core");

function getGradient(porcent) {
  if (porcent > 100) {
    porcent = 100
  }
  let porcentBad = (100 * 25) / porcent
  let porcentAverage = 100
  let porcentGood = 0
  if (porcent > 70) {
    porcentGood = 100
    porcentAverage = 70 + (100 - porcent)
  }
  return `linear-gradient(to right,rgb(244 66 60) ${porcentBad}%, #ffa500 ${porcentAverage}% ,rgb(28, 192, 56) ${porcentGood}%)`

}

const styleFullGoals = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  containerBoxGoal: {
    height: '33.333%',
    padding: 10,
  },

  titleBoxGoal: {
    fontSize: 72,
    width: '40%',

    [theme.breakpoints.down('md')]: {
      fontSize: 50
    },

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center'
    }

  },

  boxGoal: {
    width: '60%',
    height: 'max-content',
    background: '#fff',
    padding: '15px 20px',
    borderRadius: 15,
    boxShadow: '-1px 1px 6px -2px #ccc',

    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },

    '& .line_progress': {
      width: '100%',
      margin: '10px 0',

      '& .labels': {
        fontSize: 45,
        fontWeight: 'bold',
        transition: 'linear .2s'
      }
    }

  },

  boxSite: {
    '& .label_site_month': {
      color: props => props.site.month <= 25 ? 'rgb(244 66 60)' : props.site.month >= 75 ? 'rgb(28, 192, 56)' : '#ffa500'
    },

    '& .label_site_day': {
      color: props => props.site.day <= 25 ? 'rgb(244 66 60)' : props.site.day >= 75 ? 'rgb(28, 192, 56)' : '#ffa500'
    }
  },

  boxStore: {
    '& .label_store_month': {
      color: props => props.store.month <= 25 ? 'rgb(244 66 60)' : props.store.month >= 75 ? 'rgb(28, 192, 56)' : '#ffa500'
    },

    '& .label_store_day': {
      color: props => props.store.day <= 25 ? 'rgb(244 66 60)' : props.store.day >= 75 ? 'rgb(28, 192, 56)' : '#ffa500'
    }
  },

  boxTotal: {
    '& .label_total_month': {
      color: props => props.total.month <= 25 ? 'rgb(244 66 60)' : props.total.month >= 75 ? 'rgb(28, 192, 56)' : '#ffa500'
    },

    '& .label_total_day': {
      color: props => props.total.day <= 25 ? 'rgb(244 66 60)' : props.total.day >= 75 ? 'rgb(28, 192, 56)' : '#ffa500'
    }
  },

  boxProgress: {
    width: '100%',
    height: 35,
    borderRadius: 10,
    border: '2px solid #000',
    background: '#fff',

    '&.site-month': {
      '& .MuiLinearProgress-bar': {
        background: props => props.site.month && getGradient(props.site.month)
      }
    },

    '&.site-day': {
      '& .MuiLinearProgress-bar': {
        background: props => props.site.day && getGradient(props.site.day)
      }
    },

    '&.store-month': {
      '& .MuiLinearProgress-bar': {
        background: props => props.store.month && getGradient(props.store.month)
      }
    },

    '&.store-day': {
      '& .MuiLinearProgress-bar': {
        background: props => props.store.day && getGradient(props.store.day)
      }
    },

    '&.total-month': {
      '& .MuiLinearProgress-bar': {
        background: props => props.total.month && getGradient(props.total.month)
      }
    },

    '&.total-day': {
      '& .MuiLinearProgress-bar': {
        background: props => props.total.day && getGradient(props.total.day)
      }
    },

  },

  btnModalAddValue: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    zIndex: 99,
    background: 'rgb(255, 49, 74)',

    '&:hover': {
      background: 'rgb(255, 49, 74)',
    }
  },

  modalValue: {
    '& .MuiDialog-container.MuiDialog-scrollPaper': {
      '& .MuiPaper-root': {
        width: 400,
      }
    }
  },

  btnEnvValue: {
    width: 120,
    height: 40,
    color: '#fff'
  }
}))

export default styleFullGoals
