import React from 'react';
import { useLocation } from 'react-router-dom'
import { Box, Typography } from '@material-ui/core';
import useStyleBox from '../style';
import { memo } from 'react';

function BoxHistory(props) {
	const { hourWeek, hourYesterday, totalYesterday, porcentHour, porcentWeek, totalWeek, hourNow } = props

	const style = useStyleBox();
	const locationUrl = useLocation().pathname
	return (
		<Box className={style.boxHistory}>
			<Typography className={style.title}>Histórico Faturados</Typography>

			<Box className={style.boxHour}>
				<Typography className={style.title}>
					<span>Hora a Hora</span>
					<span>(até {hourNow}h)</span>
				</Typography>

				<Box className="infos">
					<Typography className="label">Ontem</Typography>
					<Box className={style.quantity} display='flex' justifyContent="center" alignItems="center">
						<span className={porcentHour < 0 || porcentHour === -0 ? 'positive' : 'negative'}>
							({porcentHour < 0 || porcentHour === -0 ? '+' : '-'}{Math.abs(porcentHour)}%)
						</span>
						<span>{hourYesterday}</span>
					</Box>
				</Box>
				<Box className="infos">
					<Typography className="label">Há uma semana</Typography>
					<Box className={style.quantity} display='flex' justifyContent="center" alignItems="center">
						<span className={porcentWeek < 0 || porcentWeek === -0 ? 'positive' : 'negative'}>
							({porcentWeek < 0 ? '+' : '-'}{Math.abs(porcentWeek)}%)
						</span>
						<span>{hourWeek}</span>
					</Box>
				</Box>
			</Box>
			{locationUrl === '/dashboard/expedicao' &&
				<>
					<span className={style.divider} />

					<Box className={style.boxDay}>
						<Typography className={style.title}>Total do dia</Typography>
						<Box className="infos">
							<Typography className="label">Ontem</Typography>
							<Typography className={style.quantity}>{totalYesterday}</Typography>
						</Box>

						<Box className="infos">
							<Typography className="label">Há uma semana</Typography>
							<Typography className={style.quantity}>{totalWeek}</Typography>
						</Box>
					</Box>
				</>
			}

		</Box>
	)
}

export default memo(BoxHistory)