import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({

    paper: {
        position: 'absolute',
        height: 525,
        width: 535,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
        border: 'none',
        outline: 'none',
        borderRadius: '5px',
    },


    containerContent: {
        textAlign: 'center',
        borderRadius: '5px',
        background: "#fff",
        marginBottom: '15px',
    },

    contentInfo: {
        fontSize: '36px',
        fontWeight: 'bold',
        color: '#000',
    },

    status: props => ({
        fontSize: props.payOrderOrNotPayOrder,
        fontWeight: 'bold',
    }),

    orders: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#000',
        borderRadius: '5px 0 0 5px',

        '& :nth-child(1)': {
            fontSize: '36px',
            fontWeight: 'bold',
            color: '#fff',
        },
        '& :nth-child(2)': {
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#a9a5a5',
        },
        '& :nth-child(3)': {
            fontSize: '10px',
            fontWeight: 'bold',
            color: '#fff',
            maxWidth: '55px',
            marginBottom: '5px'
        },
    },

    buttons:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& button': {
            height: '57px',
            fontSize: '18px',
            marginRight: "5px",
        },

        '&.MuiButtonBase-root.Mui-disabled': {
            cursor: "no-drop"
        },


        

    },

    printOut: props => ({
        height: '57px',
        backgroundColor: props.backgroundColoPrintOut,
        border: props.border,
        color: props.colorPrintOut,
        fontSize: '20px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: props.backgroundColorHoverPrintOut,
        }
    }),

    delivery: props => ({
        height: '57px',
        backgroundColor: props.backgroundColorDelivery,
        color: props.colorDelivery,
        fontSize: '20px',
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: props.backgroundColorHoverDelivery,
        }
    }),

    delete: props=>({
        color: props.buttonIconColor,
        '&:hover': {
            color: '#b01010',
            cursor: 'pointer'
        }
    }),

    boxModal: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
    },

    orderPdv: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: '-10px',

        '& :nth-child(1)': {
            fontSize: '20px',
            fontWeight: 'bold',
            marginRight: '5px'
        },
        '& :nth-child(2)': {
            fontSize: '40px',
            fontWeight: 'bold'
        },
    },

    numberOrder: {
        display: 'flex',
        alignItems: 'baseline',

        '& :nth-child(1)': {
            marginRight: '5px',
        },

        '& :nth-child(1), & :nth-child(1), ': {
            font: '14px',
            color: "#a9a5a5"
        }
    },

    modalQuantaty: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    small: {
        display: 'flex',

        '& :nth-child(1)': {
            marginRight: '5px'
        },
        '& :nth-child(1), & :nth-child(2)': {
            fontSize: '20px',
            fontWeight: 'bold'
        }
    },

    colorRed: {
        color: '#883600',
    },

    colorBrown: {
        color: '#461c00',
    },

    colorCoral: {
        color: '#ffae5f'
    },

    confirmOrder: {
        display: "flex",
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        '& :nth-child(1)': {
            fontSize: '20px',
        },
    },

    confirmOrderCliente: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'center',

        '& :nth-child(1)': {
            fontWeight: 'bold',
            marginRight: '5px',
        },

        '& :nth-child(1), & :nth-child(2)': {
            fontSize: '20px',
            fontWeight: 'bold'
        }
    },

    buttonBackOrConfirm: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '& button': {
            height: '57px',
            width: '136px',
            color: '#fff',
        },
    },

    buttonBack:{
        background :'#f39c12',
        marginRight:'20px',

        '&:hover':{
            background: '#f38c23'
        }
    },

    buttonBackOrConfirmDelete: {

        '& button': {
            height: '57px',
            width: '136px',
            color: '#fff',
            fontWeight: 'bold',
        }
    },

    buttonBackDelete: {
        background: "#f39c12",
        marginRight: '20px',
        '&:hover': {
            backgroundColor: '#d78f1c',
        }
    },

    buttonConfirm: {
        background: "#2ecc71",
        '&:hover': {
            backgroundColor: '#2cae63',
        }
    },

    buttonDeleteConfirm: {
        background: "#b01010",
        
        '&:hover': {
            backgroundColor: '#da4444',
        }
    },

    boxOrderSucess: {
        boxShadow: '0 0 0 0 rgba(255, 121, 63, 1)',
        animation: `$pulse 0.8s infinite`,
    },

    '@keyframes pulse': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255, 121, 63, 0.7)',
        },

        '70%': {
            boxShadow: '0 0 0 10px rgba(255, 121, 63, 0)',
        },

        '100%': {
            boxShadow: '0 0 0 0 rgba(255, 121, 63, 0)',
        }
    },

    

}))

export default useStyles