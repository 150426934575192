import { css } from 'styled-components'
import NunitoRegular from '../fonts/nunito/Nunito-Regular.ttf'
import NunitoBold from '../fonts/nunito/Nunito-Bold.ttf'
import NunitoExtraBold from '../fonts/nunito/Nunito-ExtraBold.ttf'
import Courier from '../fonts/courier/courier-new.ttf'
import NunitoBlack from '../fonts/nunito/Nunito-Black.ttf'

export const MainFonts = css`
	@font-face{
		font-family: 'nunito';
    font-weight: 400;
    src: url('${NunitoRegular}');
	}

	@font-face{
		font-family: 'nunito-bold';
    font-weight: 800;
    src: url('${NunitoBold}');
	}

	@font-face{
		font-family: 'nunito-ex-bold';
    font-weight: 900;
    src: url('${NunitoExtraBold}');
	}

	@font-face{
		font-family: 'courier-new';
    font-weight: 400;
    src: url('${Courier}');
	}

	@font-face{
	font-family: 'Nunito-Black';
    font-weight: 400;
    src: url('${NunitoBlack}');
	}
`;