import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({

  fullWidth: {
    backgroundColor: "#fafafa",
    maxWidth: 1970,
    padding: 0,
  },

  btnFullscreen: {
    position: 'fixed',
    background: '#b01010',
    color: '#fff',
    right: 25,
    bottom: 25,

    '&:hover': {
      background: '#960d0d',
      color: '#fff',
    }
  },

  container: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },

  containerGrafics: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-around',
    },
  },

  containerToday: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',

  },

  today: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    height: 445,
    borderRadius: 5,
    boxShadow: '1px 1px 5px 1px #dfdada ',
    background: '#faf2f2',
    margin: 10,
  },

  chart: {
    width: '100%',
  },

}))

export default useStyle
