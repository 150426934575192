
import React from 'react'

import './styles.css';

const ProgressBar = (props) => {

    const colorText = document.querySelector('[data-color="color--text"]')

    if (colorText) {
        const goalPercentLess50 = props?.goalPercent.toFixed(0) >= 0 && props?.goalPercent.toFixed(0) <= 50
        const goalPercentLess50AndMore70 = props?.goalPercent.toFixed(0) > 50 && props?.goalPercent.toFixed(0) <= 70

        if (goalPercentLess50) {
            colorText.style.color = 'rgb(244 66 60)'
        } else if (goalPercentLess50AndMore70) {
            colorText.style.color = '#ffa500'
        } else {
            colorText.style.color = 'rgb(28, 192, 56)'
        }
    }

    return (
        <div className="progressContainer">
            <span data-color="color--text">{`${props?.goalPercent.toFixed(2)}%`}</span>
            <div className="pbarContainer">
                <div className="pbar" style={{ '--value': props?.goalPercent }}></div>
            </div>
        </div>
    )
}

export default ProgressBar
