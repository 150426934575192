import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import LatoBold from "../../Assets/fonts/lato/Lato-Bold.ttf";
import LatoBlack from "../../Assets/fonts/lato/Lato-Black.ttf";
import WithoutImage from "../../Assets/img/without-image.png";

Font.register({
  family: "Lato",
  fonts: [
    { src: LatoBlack },
    { src: LatoBold, fontStyle: "bold", fontWeight: 700 },
  ],
});

Font.registerHyphenationCallback((word) => [word]);

const styles = StyleSheet.create({
  page: {
    display: "flex",
    backgroundColor: "#fff",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: "100 0",
  },
  section: {
    margin: "10 0",
    flexDirection: "row",
    alignItems: "center",
    width: "33%",
  },
  image: {
    zIndex: -1,
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
    height: 40
  },
  title: {
    fontSize: 7 ,
    fontFamily: "Lato",
    fontStyle: "bold",
    marginBottom: 5,
  },
  priceWrapper: {
    position: "relative",
    top: 10,
    display: "flex",
    flexDirection: "row",
    textAlign: "justify",
    alignItems: "center",
  },
  un: {
    fontSize: 10,
    fontFamily: "Lato",
    fontStyle: "bold",
    color: "#b01010",
  },
  price: {
    fontSize: 26,
    fontFamily: "Lato",
    color: "#b01010",
  },
});

const PdfDocument = ({ pdfData }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {pdfData.map((element) => {
          return (
            <View
              key={`${element.product}${new Date()}`}
              style={styles.section}
            >
              {element.imgUrl ? (
                <Image style={styles.image} src={element.imgUrl} />
              ) : (
                <Image style={styles.image} src={WithoutImage} />
              )}
              <View style={styles.textWrapper}>
                <View>
                  <Text
                    style={{
                      ...styles.title,
                      textIndent: -2,
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      position:"relative",
                      top: -20
                    }}
                    wrap={false}
                  >
                    {element.product}
                  </Text>

                  {element.priceNormal !== undefined ? (
                    <Text style={styles.title}>
                      De R$ {String(element.priceNormal).replaceAll(".", ",")}{" "}
                      por:
                    </Text>
                  ) : (
                    <Text style={styles.title}>A partir de:</Text>
                  )}
                </View>

                <View style={styles.priceWrapper}>

                  <Text style={{ ...styles.un, lineHeight: 2 }}>R$</Text>
                  <Text style={styles.price}>
                    {String(element.priceOffer).replaceAll(".", ",")}
                  </Text>
                  <Text style={{ ...styles.un, lineHeight: -2 }}>
                    {" "}
                    {element.amount !== undefined ? element.amount : ""}
                    UN
                  </Text>
                </View>
              </View>
            </View>
          );
        })}
      </Page>
    </Document>
  );
};

export default PdfDocument;
