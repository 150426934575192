import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { useReactToPrint } from 'react-to-print'

import history from '../../Services/history';
import { store } from '../../store';
import { apiLabs } from '../../Services/api';
import postEggsShell from '../../Services/Api/apiEggShell/postEggShell';



import Denied from '../../Components/Denied';
import Dashboard from '../../Components/Dashboard';

import { toast } from 'react-toastify'

import useStyle from './style';

import { Box, Button, Container, Grid, Hidden, IconButton, TextField, Modal, Typography } from '@material-ui/core';
import { Fullscreen } from '@material-ui/icons';
import ModelPrint from '../../Components/ModelPrint';



function EggShellOrder(props) {

  const { location: { idPage } } = props
  const [auth, setAuth] = useState(null)
  const [fullscreen, setFullscreen] = useState(false)
  const [order, setOrder] = useState({
    numberPdv: '',
    numberOrder: ''
  })
  const [orderCancel, setOrderCancel] = useState({
    numberOrder: ''
  })
  const [open, setOpen] = React.useState(false);

  const refModal = useRef()

  const style = useStyle()

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  async function verifyAuth() {
    const { profile } = store.getState().user
    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      return setAuth('authorized')
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }

  async function handleChangeInputOrder({ target }) {
    if (target.value.length <= 5) {
      setOrder({ ...order, numberOrder: target.value })
      if (target.value.length === 5) {
        const input = document.getElementById('number_pdv')
        input.focus()
      }
    }
  }

  function handleChangeInputPdv({ target }) {
    if (target.value.length <= 5) {
      setOrder({ ...order, numberPdv: target.value })
      if (target.value.length === 5) {
        const button = document.getElementById('print--out')
        button.focus()
      }
    }
  }

  function handleCancelOrder({ target }) {
    setOrderCancel({
      ...order, numberOrder: target.value
    })
  }

  const modalRef = useReactToPrint({
    onAfterPrint: () => document.getElementById('number_order').focus(),
    content: () => refModal.current
  })

  async function handlePrintOut(event) {

    const obj = {
      pedido: order.numberOrder,
      pdv: order.numberPdv
    }
    if (obj.pdv !== '' && obj.pedido !== '') {
      const res = await postEggsShell(obj)
      if (res.data.Error === 'Pedido não encontrado') {
        toast.error('Pedido não encontrado')
      } else if (res.data.Error === 'Não existe cascas de ovos nesse pedido') {
        toast.error('Não existe cascas de ovos nesse pedido')
      } else if (res.data.Error === 'Pedido não contém itens') {
        toast.error('Pedido não contém itens')
      } else if (res.data.Error === 'Pedido já cadastrado') {
        toast.error('Pedido já cadastrado')
      } else {
        modalRef()
        setOrder({
          numberPdv: '',
          numberOrder: ''
        })
      }
    } else {
      toast.error('numero do pedido ou numero do pdv não pode esta vazios')
    }

  }

  async function cancelOrder() {
    const res = await apiLabs.put(`separa/painel_cascas/${orderCancel.numberOrder}`, { status: "Desmanchar" })

    if (!res.message) {
      toast.success('Pedido enviado para Cancelar')
      setOrderCancel({
        numberOrder: ''
      })
      setOpen(false);
    } else {
      toast.error('Não foi possivel cancelar o pedido, pois já consta como pago')
    }
  }

  function requestFull() {
    document.documentElement.requestFullscreen()
  }

  window.addEventListener('resize', function () {
    if (window.screenTop !== 0) {
      setFullscreen(true)
    } else {
      setFullscreen(false)
    }
  })

  useEffect(() => {
    if (auth === 'authorized') {

    }
  }, [auth])

  useEffect(() => {
    verifyAuth()

  }, [])

  const ContentModalCancel = {
    body: (e) => {
      if (e === "block") {
        return (
          <Grid className={`${style.paper}`}>
            <Grid className={`${style.boxModalCancel}`}>
              <Box className={`${style.modalContent}`}>
                <Box className={`${style.logoCancel}`}>
                  <img src="http://santolabs.com.br/static/media/logo-loja.8203fc66.png" alt="" />
                </Box>
                <Box className={`${style.content}`}>
                  <Typography>Numero do pedido:</Typography>
                  <TextField
                    className={`${style.inputCancel}`}
                    value={orderCancel.numberOrder}
                    onChange={handleCancelOrder}
                    variant="outlined"
                  />
                </Box>
                <Box className={`${style.containerButton}`}>
                  <Button onClick={handleClose} className={`${style.buttonBack}`}>Voltar</Button>
                  <Button onClick={cancelOrder} className={`${style.buttonConfirmCancel}`}>Cancelar Pedido</Button>
                </Box>
              </Box>

            </Grid>
          </Grid>
        )
      }
      else {
        return null
      }
    },

  }


  return (
    <>
      <Dashboard title="Separa Cascas" fullscreen={fullscreen}>
        {auth === 'denied' && <Denied />}
        {auth === 'authorized' &&
          <>
            <Container className={`${style.fullWidth} ${style.containerDash}`}>

              <Grid container item xl={12} md={12} className={`${style.container}`}>
                <Grid container item xl={12} md={12} className={`${style.boxContainer}`}>
                  <Box className={`${style.noPrint}`}>
                    <ModelPrint numberOrder={order.numberOrder} numberPdv={order.numberPdv} ref={refModal} />
                  </Box>
                  <Box className={`${style.containerOrders}`}>
                    <Box className={`${style.buttonCancel}`}>
                      <Button onClick={e => handleOpen()}>Cancelar Pedido</Button>
                    </Box>
                    <Box className={`${style.boxOrder}`}>
                      <Typography className={`${style.numberOrder}`}>N° PEDIDO: </Typography>
                      <TextField
                        autoFocus
                        id='number_order'
                        className={`${style.inputOrder}`}
                        variant="outlined"
                        value={order.numberOrder}
                        onChange={handleChangeInputOrder}
                      />
                    </Box>
                    <Box className={`${style.boxPdv}`}>
                      <Typography className={`${style.numberPdv}`}>N° PDV: </Typography>
                      <TextField
                        id="number_pdv"
                        className={`${style.inputPdv}`}
                        variant="outlined"
                        value={order.numberPdv}
                        onChange={handleChangeInputPdv} />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Button
                onClick={handlePrintOut}
                id="print--out"
                className={`${style.printOutButton}`}
              >Imprimir</Button>
            </Container>
            <Hidden mdDown>
              {!fullscreen &&
                <IconButton onClick={requestFull} className={style.btnFullscreen}>
                  <Fullscreen />
                </IconButton>
              }
            </Hidden>
            <Modal
              open={open}
              onClose={handleClose}
            >
              {ContentModalCancel.body('block')}
            </Modal>
          </>

        }

      </Dashboard>

    </>
  )
}

export default EggShellOrder;
