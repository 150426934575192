import { createGlobalStyle } from "styled-components"
import { MainFonts } from "./fonts"

import 'react-toastify/dist/ReactToastify.css'

const GlobalStyles = createGlobalStyle`
	${MainFonts}

	* {
		font-family: 'nunito', sans-serif !important;
	}

	.boxGoalSucess {
			background: rgba(255, 121, 63, 1);
			box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
			animation: pulse-orange .8s infinite;
	}
	
	@keyframes pulse-orange {
			0% {
					transform: scale(0.85);
					box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
			}
			
			70% {
					transform: scale(1);
					box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
			}
			
			100% {
					transform: scale(0.85);
					box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
			}
	} 
`

export default GlobalStyles