import React, { useEffect, useState } from "react";
import Dashboard from "../../Components/Dashboard";
import { store } from "../../store";
import useStyle from "./style";
import Denied from "../../Components/Denied";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { apiLabs, apiOffers } from "../../Services/api";
import PdfDocument from "../../Components/PdfDocument";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
function Telesales(props) {
  const {
    location: { idPage },
  } = props;

  const [auth, setAuth] = useState(null);

  const [data, setData] = useState({ file: "" });

  const [offers, setOffers] = useState();

  const [wasRequested, setWasRequested] = useState(false);

  const style = useStyle();

  async function verifyAuth() {
    const { profile } = store.getState().user;
    const res = await apiLabs.post("valid_key", {
      id: profile.id,
      menu_id: idPage,
    });
    if (res.data.message === "User not authorized") {
      return setAuth("denied");
    }
    if (res.data.message === "User authorized") {
      return setAuth("authorized");
    }
  }

  const uploadSpreadsheet = (e) => {
    const file = e.target.files[0] ? e.target.files[0] : [];
    if (file.length !== 0) {
      if (!file.name.includes("xlsx")) {
        e.target.value = "";
        setData({
          file: "",
        });
        toast.error("Formato inválido, por favor selecione um formato válido");
        return;
      }
    }
    setData({
      ...data,
      [e.target.id]: file,
    });
  };

  const clearFields = () => {
    setData({ file: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = new FormData();
    const fields = data.file !== "";

    if (fields) {
      e.target.file.value = "";
    }

    obj.append("file", data.file);

    try {
      if (fields) {
        const response = await apiOffers.post("/", obj);
        setOffers(response.data);
        toast.success("Planilha enviada com sucesso");
      } else {
        toast.error(
          "Preencha o campo Planilha Jornal de Ofertas com uma planilha válida"
        );
      }
    } catch (error) {
      toast.error(
        "Preencha o campo Planilha Jornal de Ofertas com uma planilha válida"
      );
      console.error(error);
    } finally {
      clearFields();
      setWasRequested(true);
    }
  };

  useEffect(() => {
    verifyAuth();
  }, []);

  return (
    <>
      <Dashboard title="Jornal de Ofertas">
        {auth === "denied" && <Denied />}
        {auth === "authorized" && (
          <Container
            className={`${style.containerPage} ${style.containerEbook}`}
          >
            <Box>
              <form
                onSubmit={(e) => handleSubmit(e)}
                encType="multipart/form-data"
                style={{ maxWidth: 900, width: "100%", padding: 25 }}
              >
                <Grid className={style.ebookForm}>
                  <Box className={`${style.formInput}`}>
                    <Typography className={style.title}>
                      Planilha Jornal de Ofertas
                    </Typography>
                    <TextField
                      accept=".xlsx"
                      id="file"
                      label="Planilha Jornal de Ofertas"
                      placeholder="Planilha Jornal de Ofertas"
                      onChange={(e) => uploadSpreadsheet(e, 0)}
                      margin="normal"
                      type="file"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      name="file"
                    />
                  </Box>
                </Grid>
                <Grid className={style.button}>
                  <Button type="submit">Enviar</Button>
                </Grid>
              </form>
            </Box>
            <Box>
              {wasRequested && offers !== undefined && (
                <>
                  <PDFViewer
                    style={{ marginBottom: 10 }}
                    height={500}
                    width={400}
                  >
                    <PdfDocument pdfData={offers} />
                  </PDFViewer>
                  <PDFDownloadLink document={<PdfDocument pdfData={offers} />}>
                    {({ loading }) =>
                      loading ? (
                        <Grid className={style.button}>
                          <Button>Carregando...</Button>
                        </Grid>
                      ) : (
                        <Grid className={style.button}>
                          <Button>Baixar</Button>
                        </Grid>
                      )
                    }
                  </PDFDownloadLink>
                </>
              )}
            </Box>
          </Container>
        )}
      </Dashboard>
    </>
  );
}

export default Telesales;
