import React, { useState, useEffect } from 'react';

import { Box, Button, Container, Grid, Hidden, IconButton, TextField, Typography } from '@material-ui/core'
import useStyle from './style';
import Dashboard from '../../Components/Dashboard';
import { Fullscreen } from '@material-ui/icons';
import history from '../../Services/history';
import { apiLabs, apiEbook } from '../../Services/api';
import { store } from '../../store';
import Denied from '../../Components/Denied';

import { toast } from 'react-toastify'

function Ebook(props) {

  const { location: { idPage } } = props

  async function verifyAuth() {
    const { profile } = store.getState().user
    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      return setAuth('authorized')
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }
  const [fullscreen, setFullscreen] = useState(false)
  const [auth, setAuth] = useState(null)
  const [data, setData] = useState({
    name_ebook: '',
    mes: '',
    ano: '',
    url_cover_image: '',
    url_pdf: ''
  })
  const [previewImageEbook, setPreviewImageEbook] = useState({
    image_ebook: ''
  })

  const uploadImageEbook = (e) => {
    const file = e.target.files[0] ? e.target.files[0] : []
    const reader = new FileReader()

    if (file.length !== 0) {
      if (file.type.includes("/png") || file.type.includes('/jpg') || file.type.includes('/jpeg')) {
        reader.readAsDataURL(file)
        reader.onloadend = e => {
          setPreviewImageEbook({ image_ebook: reader.result })
        }
        setData({ ...data, [e.target.id]: file })
      } else {
        e.target.value = ''
        setPreviewImageEbook({ image_ebook: '' })
        toast.error('Formato inválido, por favor selecione um formato válido')
        return
      }
    }


  }

  const uploadPdfEbook = e => {
    const file = e.target.files[0] ? e.target.files[0] : []
    if (file.length !== 0) {
      if (!file.name.includes('pdf')) {
        e.target.value = ''
        setData({
          url_pdf: ''
        })
        toast.error('Formato inválido, por favor selecione um formato válido')
        return
      }
    }
    setData({
      ...data,
      [e.target.id]: file
    })
  }

  const handleChange = e => {
    const { id, value } = e.target
    setData({
      ...data,
      [id]: value
    })
  }

  const clearFields = () => {
    setData({
      name_ebook: '',
      mes: '',
      ano: '',
      url_cover_image: '',
      url_pdf: ''
    })
    setPreviewImageEbook({ image_ebook: '' })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    let obj = new FormData()
    const fields =
      data.name_ebook !== '' &&
      data.ano !== '' &&
      data.mes !== '' &&
      data.url_cover_image !== '' &&
      data.url_pdf !== ''

    if (fields) {
      e.target.url_cover_image.value = ''
      e.target.url_pdf.value = ''
    }

    obj.append('name_ebook', data.name_ebook)
    obj.append('mes', data.mes)
    obj.append('ano', data.ano)
    obj.append('imagem-ebook', data.url_cover_image)
    obj.append('pdf-ebook', data.url_pdf)
    try {
      if (fields) {
        await apiEbook.post('/', obj)
        toast.success('Ebook salvo com sucesso')
        clearFields()
      } else {
        toast.error('Preencha os campos')
      }

    } catch (error) {
      console.error(error)
    }

  }
  useEffect(() => {
    verifyAuth()
  }, [])


  function requestFull() {
    document.documentElement.requestFullscreen()
  }

  window.addEventListener('resize', function () {
    if (window.screenTop !== 0) {
      setFullscreen(true)
    } else {
      setFullscreen(false)
    }
  })

  const style = useStyle()

  return (
    <>
      <Dashboard title="Ebook" fullscreen={fullscreen}>
        {auth === 'denied' && <Denied />}
        {auth === 'authorized' &&
          <>
            <Container className={`${style.fullWidth} ${style.containerEbook}`} >
              <form onSubmit={e => handleSubmit(e)} encType="multipart/form-data" style={{ maxWidth: 900, width: '100%', padding: 25 }}>
                <Grid className={style.ebookForm}>
                  <Typography className={style.title}>Nome do ebook</Typography>
                  <Box className={style.formInput}>
                    <TextField
                      id="name_ebook"
                      label="Nome do ebook"
                      variant="outlined"
                      value={data.name_ebook}
                      onChange={e => handleChange(e)}

                    />
                  </Box>
                  <Box className={`${style.formInput} `}>
                    <Typography className={style.title}>Data do ebook</Typography>
                    <Grid className={style.date}>
                      <Box>
                        <TextField
                          id="mes"
                          label="Mes do ebook"
                          variant="outlined"
                          value={data.mes}
                          onChange={e => handleChange(e)}
                        />
                      </Box>
                      <Box>
                        <TextField
                          id="ano"
                          label="Ano do ebook"
                          variant="outlined"
                          value={data.ano}
                          onChange={e => handleChange(e)}
                        />
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
                <Grid className={style.ebookForm}>
                  <Box className={`${style.formInput}`}>
                    <Typography className={style.title}>Arquivos do ebook</Typography>
                    <Box className={style.ebookImage}>
                      <TextField
                        accept="image/*"
                        id="url_cover_image"
                        label="Imagem Ebook"
                        placeholder="imagem"
                        onChange={e => uploadImageEbook(e)}
                        margin="normal"
                        type="file"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        variant="outlined"
                      />
                      {previewImageEbook.image_ebook &&
                        <Box className={style.image}>
                          <img src={previewImageEbook.image_ebook} alt="Ebook" />
                        </Box>
                      }
                    </Box>
                    <TextField
                      accept="application/pdf"
                      id="url_pdf"
                      label="Pdf Ebook"
                      placeholder="pdf"
                      onChange={e => uploadPdfEbook(e)}
                      margin="normal"
                      type="file"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                    />
                  </Box>
                </Grid>
                <Grid className={style.button}>
                  <Button type="submit">Salvar</Button>
                </Grid>
              </form>
            </Container>

            <Hidden mdDown>
              {!fullscreen &&
                <IconButton onClick={requestFull} className={style.btnFullscreen}>
                  <Fullscreen />
                </IconButton>
              }
            </Hidden>

          </>
        }

      </Dashboard>


    </>
  )
}

export default Ebook;
