import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, InputLabel, LinearProgress, OutlinedInput, Typography } from '@material-ui/core';
import IntlCurrencyInput from "react-intl-currency-input"
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import Dashboard from '../../../Components/Dashboard';
import Denied from '../../../Components/Denied';
import { apiLabs } from '../../../Services/api';
import history from '../../../Services/history';
import { store } from '../../../store';
import styleFullGoals from './style';
import PropTypes from 'prop-types'
import { BackButton, SubmitButton } from '../../../Components/Custom';
import { toast } from 'react-toastify';


const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

function CashInput(props) {
  const { inputRef, ...other } = props;
  return (
    <IntlCurrencyInput
      currency="BRL"
      config={currencyConfig}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      {...other}
    />
  )
}

CashInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
};


function FullGoals(props) {
  
  const [openModal, setOpenModal] = useState(false)
  const { profile } = store.getState().user

  const { location: { idPage } } = props
  const [auth, setAuth] = useState('authorized')
  const [user, setUser] = useState({
    name: '',
    local: '',
    showSite: false,
    showStore: false,
    showTotal: false
  })
  const [datas, setDatas] = useState({
    site: {
      month: 0,
      day: 0
    },

    store: {
      month: 0,
      day: 0
    },

    total: {
      month: 0,
      day: 0
    }
  })
  const [value, setValue] = useState('')

  const style = styleFullGoals(datas)



  async function verifyAuth() {

    const res = await apiLabs.post('valid_key', { id: profile.id, menu_id: idPage })
    if (res.data.message === "User not authorized") {
      return setAuth('denied')
    }
    if (res.data.message === "User authorized") {
      let local, showStore, showSite, showTotal;

      if (profile.name === 'master') {
        local = 'master'
        showStore = true
        showSite = true
        showTotal = true

      } else if (profile.name === 'gustavo') {
        local = 'site'
        showStore = false
        showSite = true
        showTotal = false
      } else if (profile.name === 'ricardo') {
        local = 'loja'
        showStore = true
        showSite = false
        showTotal = false
      }

      setUser({
        name: profile.name,
        local,
        showStore,
        showSite,
        showTotal,
      })
      setAuth('authorized')
      getGoals()
      setInterval(() => {
        getGoals()
      }, 10000)
      return
    }
    return history.push({ pathname: '/', state: { from: props.location } })
  }

  async function getGoals() {
    try {
      const res = await apiLabs.get('/metas/meta_diaria')

      const obj = res.data[0]

      let valueDaySite = parseFloat(obj.meta_site) / parseInt(obj.mes)
      let valueDayStore = parseFloat(obj.meta_loja) / parseInt(obj.mes)

      let site_month = (100 * parseFloat(obj.valorMesSite[0].valorSite)) / parseFloat(obj.meta_site)
      let store_month = (100 * parseFloat(obj.valorMesLoja[0].valorLoja)) / parseFloat(obj.meta_loja)

      let site_day = (100 * parseFloat(obj.valor_site)) / valueDaySite
      let store_day = (100 * parseFloat(obj.valor_loja)) / valueDayStore

      let total_month = (site_month + store_month) / 2
      let total_day = (site_day + store_day) / 2

      setDatas({
        site: {
          month: site_month,
          day: site_day
        },

        store: {
          month: store_month,
          day: store_day
        },

        total: {
          month: total_month,
          day: total_day,
          valorLoja: parseFloat(obj.valor_loja),
          valorSite: parseFloat(obj.valor_site),
        },
      })

    } catch (error) {
      console.log(error)
    }
  }

  async function envValue() {
    try {

      let obj

      if (user.local === 'site') {
        obj = {
          valor_site: value
        }
      } else {
        obj = {
          valor_loja: value
        }
      }
      await apiLabs.put('/metas/meta_diaria', obj)
      toast.success(`Valor adicionado a ${user.local} com sucesso!`)
      getGoals()
      setOpenModal(!openModal)
    } catch (error) {
      toast.warning(`Falha em adicionar o valor para ${user.local}: ERROR - ${error}`)
    }
  }

  function handleValue(e, value) {
    setValue(value)
  }

  function handleModal(){
    if (profile.name === 'ricardo') {
      setValue(datas.total.valorLoja)
    } else {
      setValue(datas.total.valorSite)
    }
    setOpenModal(!openModal)
  }

  useEffect(() => {
    verifyAuth()
  }, [])

  return (
    <Dashboard title="Dashboard metas gerais">
      {auth === 'denied' &&
        <Denied />
      }

      {auth === 'authorized' &&
        <Container className={style.root}>

          {user.local !== 'master' &&
            <Fab color="primary" aria-label="add" className={style.btnModalAddValue} onClick={handleModal}>
              <AddIcon />
            </Fab>
          }

          {user.showSite &&
            <Box display='flex' alignItems='center' className={`${style.containerBoxGoal} ${style.boxSite}`} flexWrap='wrap'>
              <Typography className={style.titleBoxGoal} >Meta Site</Typography>

              <Box className={style.boxGoal} display='flex' flexDirection='column' justifyContent='center'>
                <Box display='flex' flexWrap='wrap' justifyContent='space-between' className='line_progress'>
                  <Typography className='labels'>Mês</Typography>
                  <Typography className='labels label_site_month'>{Math.round(datas.site.month)}%</Typography>

                  <LinearProgress className={`${style.boxProgress} site-month`} variant="determinate" value={datas.site.month > 100 ? 100 : datas.site.month} />
                </Box>

                <Box display='flex' flexWrap='wrap' justifyContent='space-between' className='line_progress'>
                  <Typography className='labels'>Dia</Typography>
                  <Typography className='labels label_site_day'>{Math.round(datas.site.day)}%</Typography>
                  <LinearProgress className={`${style.boxProgress} site-day`} variant="determinate" value={datas.site.day > 100 ? 100 : datas.site.day} />
                </Box>
              </Box>
            </Box>
          }

          {user.showStore &&
            <Box display='flex' alignItems='center' className={`${style.containerBoxGoal} ${style.boxStore}`} flexWrap='wrap'>
              <Typography className={style.titleBoxGoal}>Meta Loja</Typography>

              <Box className={style.boxGoal} display='flex' flexDirection='column' justifyContent='center'>
                <Box display='flex' flexWrap='wrap' justifyContent='space-between' className='line_progress'>
                  <Typography className='labels '>Mês</Typography>
                  <Typography className='labels label_store_month'>{Math.round(datas.store.month)}%</Typography>
                  <LinearProgress className={`${style.boxProgress} store-month`} variant="determinate" value={datas.store.month > 100 ? 100 : datas.store.month} />
                </Box>

                <Box display='flex' flexWrap='wrap' justifyContent='space-between' className='line_progress'>
                  <Typography className='labels'>Dia</Typography>
                  <Typography className='labels label_store_day'>{Math.round(datas.store.day)}%</Typography>
                  <LinearProgress className={`${style.boxProgress} store-day`} variant="determinate" value={datas.store.day > 100 ? 100 : datas.store.day} />
                </Box>
              </Box>

            </Box>
          }

          {user.showTotal &&
            <Box display='flex' alignItems='center' className={`${style.containerBoxGoal} ${style.boxTotal}`} flexWrap='wrap'>
              <Typography className={style.titleBoxGoal}>Meta Total</Typography>

              <Box className={style.boxGoal} display='flex' flexDirection='column' justifyContent='center'>
                <Box display='flex' flexWrap='wrap' justifyContent='space-between' className='line_progress'>
                  <Typography className='labels'>Mês</Typography>
                  <Typography className='labels label_total_month'>{Math.round(datas.total.month)}%</Typography>
                  <LinearProgress className={`${style.boxProgress} total-month`} variant="determinate" value={datas.total.month > 100 ? 100 : datas.total.month} />
                </Box>

                <Box display='flex' flexWrap='wrap' justifyContent='space-between' className='line_progress'>
                  <Typography className='labels'>Dia</Typography>
                  <Typography className='labels label_total_day'>{Math.round(datas.total.day)}%</Typography>
                  <LinearProgress className={`${style.boxProgress} total-day`} variant="determinate" value={datas.total.day > 100 ? 100 : datas.total.day} />
                </Box>
              </Box>
            </Box>
          }

          {user.local !== 'master' &&
            <Dialog
              open={openModal}
              keepMounted
              onClose={handleModal}
              className={style.modalValue}
            >
              <DialogTitle id="alert-dialog-slide-title">Adicionar valor para {user.local}</DialogTitle>
              <DialogContent>

                <FormControl fullWidth variant='outlined'>
                  <InputLabel shrink>Total de venda {user.local}</InputLabel>
                  <OutlinedInput
                    value={parseFloat(value)}
                    onChange={handleValue}
                    inputComponent={CashInput}
                    label={`Total de venda ${user.local}`}
                    notched
                  />
                </FormControl>

              </DialogContent>
              <DialogActions>
                <BackButton onClick={handleModal}>fechar</BackButton>
                <SubmitButton className={style.btnEnvValue} onClick={envValue} color="primary">Enviar</SubmitButton>
              </DialogActions>
            </Dialog>
          }

        </Container>
      }


    </Dashboard>
  )
}

export default FullGoals;
