import { makeStyles } from "@material-ui/core";

const styleDashShells = makeStyles((theme) => ({
  root: {
    paddingTop: 15,
  },

  boxShell: {

    background: '#fff',
    borderRadius: 10,
    padding: 25,
    boxShadow: '1px 1px 8px 3px #cecece',

    '& .title_box': {

      display: 'flex',
      alignItems: 'center',

      '&_icon': {
        '& svg': {
          fill: '#8B4513',
          width: 60,
          height: 60,

          [theme.breakpoints.only('md')]: {
            width: '5vw',
          },

          [theme.breakpoints.down('sm')]: {
            width: 40,
            height: 40,
          },
        },
      },

      '&_text': {
        color: '#8B4513',
        fontSize: 30,
        marginLeft: 15,
        fontWeight: 'bold',

        [theme.breakpoints.up('lg')]: {
          fontSize: '1.7vw',
        },

        [theme.breakpoints.up('xl')]: {
          fontSize: 30,
        },

        [theme.breakpoints.only('md')]: {
          fontSize: '2vw',
        },

        [theme.breakpoints.down('sm')]: {
          fontSize: 22,
        },
      },


    },

    '& .total_box': {
      '&_value': {
        fontWeight: 'bold',
        fontSize: 120,
        lineHeight: '1',

        [theme.breakpoints.up('lg')]: {
          fontSize: '6vw',
        },

        [theme.breakpoints.up('xl')]: {
          fontSize: 120,
        },

        [theme.breakpoints.only('md')]: {
          fontSize: 70,
        },

        [theme.breakpoints.down('sm')]: {
          fontSize: 75,
        },
      },

      '&_text': {
        fontSize: 22,
        fontStyle: 'italic',
        fontWeight: '500',
      }
    },

    '& .separate': {
      borderColor: '#cecece24'
    },

    '& .price_box': {

      lineHeight: '1',
      fontStyle: 'italic',
      color: '#2ca53dcf',
      fontSize: 25,
      paddingTop: 15,

      [theme.breakpoints.down('md')]: {
        fontSize: 20,
      }
    }


  }
}))

export default styleDashShells
