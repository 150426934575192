import { makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  containerPage: {
    backgroundColor: "#fafafa",
    maxWidth: 1970,
    padding: 0,
  },

  btnFullscreen: {
    position: "fixed",
    background: "#b01010",
    color: "#fff",
    right: 25,
    bottom: 25,

    "&:hover": {
      background: "#960d0d",
      color: "#fff",
    },
  },

  containerEbook: {
    maxWidth: 1230,
    height: "100%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingTop: "2rem",
  },

  ebookForm: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  date: {
    display: "flex",
    width: "100%",
    marginTop: 10,

    "& :nth-child(1)": {
      margin: "0 10px 0 0",
      width: "100%",

      [theme.breakpoints.down("sm")]: {
        margin: "0 0 10px 0",
      },
    },

    "& :nth-child(2)": {
      width: "100%",
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },

  formInput: {
    width: "100%",
    margin: "10px 0",

    "& .MuiFormControl-root.MuiTextField-root": {
      width: "100%",
      fontSize: 20,
    },
  },

  ebookImage: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  image: {
    width: 90,
    margin: 10,

    "& img": {
      width: "100%",
      display: "block",
    },
  },

  button: {
    width: "100%",

    "& button": {
      width: "100%",
      background: "#04b849",
      padding: "10px 0",
      fontSize: 20,
      color: "#fff",
      fontWeight: "bold",
      transaction: "ease",

      "&:hover": {
        background: "#008634",
      },
    },
  },

  title: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
}));

export default useStyle;
