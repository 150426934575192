import { makeStyles } from "@material-ui/core";

const styleMain = makeStyles((theme) => ({
    root: {
        minHeight: 300,
        '& .fs-20': {
            fontSize: 20,
        },

        '& .fs-25': {
            fontSize: 25,
        },

        '& .bold': {
            fontWeight: 'bold'
        },

        '& .dark-brown': {
            color: '#461c00'
        },
        '& .brown': {
            color: '#883600'
        },
        '& .orange': {
            color: '#feb773'
        },

        '& img': {
            maxWidth: '100%',
            height: 'auto',
        },
    },

    table: {
        borderSpacing: '0 25px',
    },

    titleColumn: {
        border: 'none',
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: 1,
        padding: 10,
        '& .box-titles': {
            '& span:first-child': {
                color: '#000'
            },

            '& span:last-child': {
                color: '#a9a5a5'
            }
        },
    },

    rowOrder: {
        background: '#fff',
        '& td': {
            border: 'none',
        }
    },

    separator: {
        background: 'transparent',
        height: 15,
    },

    columnId: {
        background: '#000',
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,

        '& .box-infos-id': {
            '& span': {
                color: '#fff',
                lineHeight: 1,
                fontWeight: 'bold',

                '&:first-child': {
                    fontSize: 36,
                },

                '&:nth-child(2)': {
                    color: '#a9a5a5',
                    fontSize: 20,
                    margin: '5px 0'
                },

                '&:nth-child(3), &:last-child': {
                    fontSize: 11,
                },

            }
        }
    },

    numberShells: {
        color: '#000',
        fontSize: 36,
        fontWeight: 'bold',
        textAlign: 'center',
    },

    labelStatus: {
        color: '#000',
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
    },

    actionsOrder: {
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,

        '& .button-action': {
            height: 57,
            fontSize: 18,
            fontWeight: 'bold',
            margin: '0 2.5px',

            '&.print': {
                background: '#f39c12',
                color: '#fff',

                '&.active': {
                    background: '#fff',
                    color: '#2ecc71',
                    border: '3px solid #2ecc71',
                }
            },

            '&.deliver': {
                background: '#2ecc71',
                color: '#fff',

                '&:disabled': {
                    background: '#f4f4f4',
                    color: '#cecece',
                }
            },

            '&.cancel': {
                color: '#f75252',
                fontSize: 24,
                cursor: 'pointer',

                '&:hover': {
                    color: '#b01010',
                },
                '&.active': {
                    background: '#fff',
                    color: '#0b1010',
                    border: '3px solid #2ecc71',
                }

            },
            '&.dismantle': {
                color: '#fff',
                fontSize: 24,
                cursor: 'pointer',
                background: '#d81717',
                fontWeight: 'bold',
                width: '100%',

                '&:hover': {
                    background: '#f14a4a',
                },
            },
        }
    },

    boxDeliverCancel: {
        padding: 40,
        textAlign: 'center',
        width: 525,
        boxSizing: 'border-box',

        '&.for-printer': {
            width: '100% !important',
            padding: '0px 20px',
            background: '#fff',

            '& hr': {
                color: '#000'
            },

            '& .logo': {
                width: '65%',
                margin: '0 auto',
            },

            '& .pdv-modal': {
                '& .pdv': {
                    fontSize: 55,
                }
            },

            '& .order-modal': {
                color: '#883600',
            },

            '& .label-status-pgt': {
                background: '#000',
                color: '#fff',
                padding: '15px 20px',
                margin: '15px auto',
                borderRadius: 5,
                width: 'max-content',
                display: 'flex',
                alignItems: 'center',
                fontSize: 35,
                fontWeight: 'bold',
            }
        },

        '& p': {
            margin: 0,
            lineHeight: 1,
            margin: '4px 0'
        },

        '& .pdv-modal': {
            '& .pdv': {
                fontSize: 40,
            }
        },

        '& .order-modal': {
            color: '#a9a5a5',
        },

        '& .box-shells-modal': {
            padding: '15px 0',
        },

        '& .btn-action': {
            height: 57,
            padding: 15,
            color: '#fff',
            fontSize: 18,
            margin: 5,

            '&.back': {
                background: '#f39c12',
            },

            '&.execute': {
                background: 'orange',
                '&-cancel': {
                    background: '#d81717',
                },

                '&-deliver': {
                    background: '#2ecc71',
                },
                '&-dismantle': {
                    background: '#d81717',
                },

                '&-deliver': {
                    background: '#2ecc71',
                },
            },
        }
    },

    boxOrderSucess: {
        boxShadow: '0 0 0 0 rgba(255, 121, 63, 1)',
        animation: `$pulse 0.8s infinite`,
    },

    '@keyframes pulse': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255, 121, 63, 0.7)',
        },

        '70%': {
            boxShadow: '0 0 0 10px rgba(255, 121, 63, 0)',
        },

        '100%': {
            boxShadow: '0 0 0 0 rgba(255, 121, 63, 0)',
        }
    },


    boxOrderCancel: {
        boxShadow: '0 0 0 0 rgba(255,  0,0, 1)',
        animation: `$pulseCancel 0.8s infinite`,
    },

    '@keyframes pulseCancel': {
        '0%': {
            boxShadow: '0 0 0 0 rgba(255,  0,0, 0.7)',
        },

        '70%': {
            boxShadow: '0 0 0 10px rgba(255, 0,0, 0)',
        },

        '100%': {
            boxShadow: '0 0 0 0 rgba(255,  0,0, 0)',
        }
    }
}))

export default styleMain