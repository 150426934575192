import { Box, Button, Modal, Typography } from '@material-ui/core';
import useStyle from './style';
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { apiAttedance } from '../../Services/api';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format, sub } from 'date-fns';

const Chart = (props) => {
  const { series, categories, title, charttype } = props

  const style = useStyle()

  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState('')
  const [atendimento, setAtendimento] = useState([])
  const [mes, setMes] = useState([])
  const [de, setDe] = useState(sub(new Date(), { days: 30 }))
  const [ate, setAte] = useState(format(new Date(), 'MM-dd-yyyy'))
  const [categorie, setCategorie] = useState([])

  const getAttendanceOnMonth = async (type) => {
    try {
      const atendimento = await apiAttedance.get(`atendimento_one_month/${type}`);
      setAtendimento(atendimento.data)
    } catch (error) {
      console.log(error)
    }
  }

  const SearchAttendance = async () => {

    const params = {
      de,
      ate,
      type: charttype
    }

    try {
      const res = await apiAttedance.get('search', { params })
      setAtendimento(res.data)
    } catch (error) {
      return console.log(error)
    }
  }

  function getModalStyle() {
    const top = 50
    const left = 50

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }

  const handleOpen = () => {
    setOpen(true);
    setType(title)
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDe = (e) => {
    const date = format(new Date(e), 'MM-dd-yyyy')
    setDe(date)
  }

  const handleAte = (e) => {
    const date = format(new Date(e), 'MM-dd-yyyy')
    setAte(date)
  }

  const options = {
    chart: {
      height: 350,
      type: 'area',
      offsetX: 0,
      offsetY: 0,
      width: '100%',

      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },

        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          }
        },
        autoSelected: 'zoom'
      },
    },

    grid: {
      show: false,
    },

    dataLabels: {
      enabled: false,
    },

    noData: {
      text: "Não foram encontrado dados",
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000',
        fontSize: '45px',
        fontFamily: 'Roboto'
      }
    },

    stroke: {
      curve: 'smooth'
    },

    xaxis: {
      categories: categories,
      tickAmount: undefined,
      tickPlacement: 'between',
      min: undefined,
      max: undefined,
      range: undefined,
      floating: false,
      decimalsInFloat: undefined,
      overwriteCategories: undefined,
      position: 'bottom',
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: [],
          fontSize: '16px',
          fontFamily: 'nunito, Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
        format: undefined,
        formatter: (value) => {
          const valuesData = value.split(' ')
          const firstString = valuesData[0]
          const lastString = valuesData[valuesData.length - 1]
          const date = `${firstString} ${lastString.replace('/', ':')}`
          const dateFormated = date.replaceAll('as', '00:00')
          return value !== undefined && format(new Date(dateFormated), 'dd/MM/yy HH:mm')
        },
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        height: 6,
        offsetX: 0,
        offsetY: 0
      },

      title: {
        text: undefined,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '15px',
          fontFamily: 'nunito, Arial, sans-serif',
          fontWeight: 600,
          cssClass: 'apexcharts-xaxis-title',
        },
      },
      crosshairs: {
        show: true,
        width: 1,
        position: 'back',
        opacity: 0.9,
        stroke: {
          color: '#b6b6b6',
          width: 0,
          dashArray: 0,
        },
        fill: {
          type: 'solid',
          color: '#B1B9C4',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 1,
          opacity: 0.4,
        },
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: 0,
          fontFamily: 0,
        },
      },

    },

    yaxis: {
      labels: {
        show: false,
      }
    },

    legend: {
      show: true,
      showForSingleSeries: false,
      showForNullSeries: true,
      showForZeroSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      floating: false,
      fontSize: '18px',
      fontFamily: 'Roboto, Arial',
      fontWeight: 400,

      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        strokeColor: '#fff',
        radius: 15,
        offsetX: 0,
        offsetY: 0
      },
      itemMargin: {
        horizontal: 10,
        vertical: 5
      },
    }
  };

  const optionsCharModal = {
    chart: {
      height: 350,
      type: 'area',
      offsetX: 0,
      offsetY: 0,

      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },

        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          }
        },
        autoSelected: 'zoom'
      },
    },

    grid: {
      show: false,
    },

    dataLabels: {
      enabled: false
    },

    noData: {
      text: "Não foram encontrado dados",
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: '#000',
        fontSize: '45px',
        fontFamily: 'Roboto'
      }
    },

    stroke: {
      curve: 'smooth'
    },

    xaxis: {
      categories: categorie,
      tickAmount: undefined,
      tickPlacement: 'between',
      min: undefined,
      max: undefined,
      range: undefined,
      floating: false,
      decimalsInFloat: undefined,
      overwriteCategories: undefined,
      position: 'bottom',
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: [],
          fontSize: '15px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
        format: undefined,
        formatter: (value) => {
          const valuesData = value?.split(' ')
          const firstString = valuesData[0]
          const lastString = valuesData[valuesData.length - 1]
          const date = `${firstString} ${lastString.replace('/', ':')}`
          const dateFormated = date.replaceAll('as', '00:00')
          return value !== undefined && format(new Date(dateFormated), 'dd/MM/yy HH:mm')
        },
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        height: 1,
        width: '100%',
        offsetX: 0,
        offsetY: 0
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        height: 6,
        offsetX: 0,
        offsetY: 0
      },

      title: {
        text: undefined,
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: '15px',
          fontFamily: 'nunito, Arial, sans-serif',
          fontWeight: 600,
          cssClass: 'apexcharts-xaxis-title',
        },
      },
      crosshairs: {
        show: true,
        width: 1,
        position: 'back',
        opacity: 0.9,
        stroke: {
          color: '#b6b6b6',
          width: 0,
          dashArray: 0,
        },
        fill: {
          type: 'solid',
          color: '#B1B9C4',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 1,
          opacity: 0.4,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: 0,
          fontFamily: 0,
        },
      },
    },

    yaxis: {
      labels: {
        show: false,
      }
    },

    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
    },
  };

  useEffect(() => {
    getAttendanceOnMonth(charttype)
  }, [open, charttype])

  useEffect(() => {
    if (charttype === 'email') {
      setMes([
        {
          name: 'Quantidade de emails',
          data: atendimento.emailsQTD
        },
        {
          name: 'Emails finalizados',
          data: atendimento.emailsFinalizados
        },
        {
          name: 'Emails em progresso',
          data: atendimento.emailsEmProgresso
        }
      ])
    }
  }, [charttype])

  useEffect(() => {
    if (charttype === 'email') {
      setMes([
        {
          name: 'Quantidade de emails',
          data: atendimento.emailsQTD
        },
        {
          name: 'Emails finalizados',
          data: atendimento.emailsFinalizados
        },
        {
          name: 'Emails em progresso',
          data: atendimento.emailsEmProgresso
        }
      ])
      setCategorie(atendimento.emailsDate)
    }

  }, [atendimento])

  useEffect(() => {

    if (charttype === 'whatsface') {

      setMes([
        {
          name: 'Quantidade de whats/face',
          data: atendimento.whatsFace
        },
        {
          name: 'Whatsface finalizados',
          data: atendimento.whatsFaceFinalizados,
        },
        {
          name: 'Whatsface em progresso',
          data: atendimento.whatsFaceEmProgresso
        }
      ])
      setCategorie(atendimento.whatsFaceDate)
    }

  }, [open, charttype])

  useEffect(() => {

    if (charttype === 'whatsface') {

      setMes([
        {
          name: 'Quantidade de whats/face',
          data: atendimento.whatsFace
        },
        {
          name: 'Whatsface finalizados',
          data: atendimento.whatsFaceFinalizados,
        },
        {
          name: 'Whatsface em progresso',
          data: atendimento.whatsFaceEmProgresso
        }
      ])
      setCategorie(atendimento.whatsFaceDate)

    }

  }, [atendimento])

  useEffect(() => {
    if (charttype === 'pedidos') {
      setMes([
        {
          name: "Pedidos analizados",
          data: atendimento.pedidosAnalizados
        },
        {
          name: 'Pedidos em Analize',
          data: atendimento.pedidosEmAnalise
        }
      ])
      setCategorie(atendimento.emailsDate)
    }

  }, [open, charttype])

  useEffect(() => {
    if (charttype === 'pedidos') {
      setMes([
        {
          name: "Pedidos analizados",
          data: atendimento.pedidosAnalizados
        },
        {
          name: 'Pedidos em Analize',
          data: atendimento.pedidosEmAnalise
        }
      ])
      setCategorie(atendimento.emailsDate)
    }

  }, [atendimento, charttype])

  useEffect(() => {
    if (charttype === 'telefones') {
      setMes([
        {
          name: 'Telefone Pedidos',
          data: atendimento.telefonesPerdidos
        },
        {
          name: 'Telefone Atendidos',
          data: atendimento.telefonesAtendidos
        }
      ])
      setCategorie(atendimento.emailsDate)
    }

  }, [open, charttype])

  useEffect(() => {
    if (charttype === 'telefones') {
      setMes([
        {
          name: 'Telefone Pedidos',
          data: atendimento.telefonesPerdidos
        },
        {
          name: 'Telefone Atendidos',
          data: atendimento.telefonesAtendidos
        }
      ])
      setCategorie(atendimento.emailsDate)
    }

  }, [atendimento])

  return (
    <Box className={style.grafic}>
      <Box className={style.title}>
        <Typography>{title}</Typography>
      </Box>
      <Box className={style.chart}>
        <ReactApexChart
          options={options}
          series={series}
          type="area"
          height={320}
        />
      </Box>
      <Button
        charttype={charttype}
        onClick={handleOpen}
      >
        Ver +
      </Button>
      <div>
        <Modal
          open={open}
        >
          <div
            style={modalStyle}
            className={`${style.paper} ${style.modal}`}>
            <Box className={style.date}>
              <Box className={style.search}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    name="de"
                    label="Data"
                    value={de}
                    onChange={handleDe}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    name="ate"
                    label="Data"
                    value={ate}
                    onChange={handleAte}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                <Button onClick={SearchAttendance}>
                  Buscar
                </Button>
              </Box>
              <Box className={style.close}>
                <Button onClick={handleClose}>Fechar</Button>
              </Box>
            </Box>
            <Box className={style.chart}>
              <ReactApexChart
                options={optionsCharModal}
                series={mes}
                type="area"
                height={350}
              />

            </Box>
          </div>
        </Modal>
      </div>
    </Box>
  )
}

export default Chart
