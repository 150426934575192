import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  fullWidth: {
    backgroundColor: "#fafafa",
    maxWidth: 1970,
    padding: 0,
  },

  btnFullscreen: {
    position: 'fixed',
    background: '#b01010',
    color: '#fff',
    right: 25,
    bottom: 25,

    '&:hover': {
      background: '#960d0d',
      color: '#fff',
    }
  },

  containerAttendance: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    flexDirection: 'column',
  },

  title: {
    fontSize: '4vw',
    paddingTop: 20,
    fontWeight: 'bold',

  },

  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: 550,
    maxWidth: 620,
    height: '100%',

  },

  contentTitle: {
    fontSize: 30,
    fontWeight: 'bold',

  },

  contentBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',

    '& .MuiFormControl-root': {
      margin: 10,
      width: 290,
    },

    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',

      '& .MuiFormControl-root': {
        width: 290,
      },
    }

  },

  button: {
    width: '97%',
    background: '#F2CC39',
    padding: 10,
    margin: 10,
    color: '#fff',

    '& .MuiButton-label': {
      fontSize: 20,
      fontWeight: 'bold',
    },

    '&.MuiButton-root:hover': {
      background: "#12c61d"
    }

  },

  date: {
    '&.MuiTextField-root': {
      margin: '10px 5px 10px 10px',
    },

    '& .MuiInputBase-root MuiOutlinedInput-root': {
      maxWidth: 177,
      width: '100%'
    }
  },

  time: {
    maxWidth: 110,
    width: '100%',

    '&.MuiFormControl-root': {
      margin: '10px 10px 10px 5px',
    },

    '& .MuiInputBase-root MuiOutlinedInput-root': {
      width: 90,
    },

    '& input[type="date" i]::-webkit-calendar-picker-indicator': {
      marginLeft: 0,
    }
  }



}))

export default useStyle
