import React from 'react';
import { Router } from 'react-router-dom';
import Routes from './Routes';
import { CssBaseline } from '@material-ui/core';
import GlobalStyles from './Assets/css/GlobalStyles';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';
import { ToastContainer } from 'react-toastify';

import history from './Services/history';
import { ConnectedRouter } from 'connected-react-router';


function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ConnectedRouter history={history}>
            <GlobalStyles />
            <CssBaseline />
            <ToastContainer autoClose={3000} />
            <Routes />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
