import React, { useEffect, useState } from 'react'
import { Box, Typography } from "@material-ui/core";
import useStyleBox from "../style";

import goldCup from '../../../Assets/img/gold-cup.png'
import silverCup from '../../../Assets/img/silver-cup.png'
import bronzeCup from '../../../Assets/img/bronze-cup.png'
import { apiEmbalei } from '../../../Services/api';
import format from 'date-fns/format';

function BoxRanking() {

  const style = useStyleBox()

  const [date, setDate] = useState(null)
  const [ranking, setRanking] = useState([])

  function getDate() {
    let ontem = new Date()
    ontem.setDate(ontem.getDate() - 1)
    if (ontem.getDay() === 0) {
      return setDate(ontem.setDate(ontem.getDate() - 2))
    }
    return setDate(ontem)
  }

  const getRanking = async (date) => {
    try {
      const res = await apiEmbalei.get(`ranking?data=${format(new Date(date), 'dd/MM/yyyy')}`)
      setRanking(res.data.splice(0, 3))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (date !== null) {
      getRanking(date)
    }
  }, [date])

  useEffect(() => {
    getDate()
  }, [])

  return (
    <>
      <Typography className={style.titleRanking}>Ranking Embalados</Typography>
      <Box display='flex' alignItems='center' justifyContent='center' className={style.boxRanking}>

        <Typography className='place second_place'>
          <span>
            <img src={silverCup} alt="" />
          </span>
          <span>{ranking[1] ? ranking[1].name : ''}</span>
          <span>{ranking[1] ? ranking[1].meta : ''}</span>
        </Typography>

        <Typography className='place first_place'>
          <span>
            <img src={goldCup} alt="" />
          </span>
          <span>{ranking[0] ? ranking[0].name : ''}</span>
          <span>{ranking[0] ? ranking[0].meta : ''}</span>
        </Typography>

        <Typography className='place third_place'>
          <span>
            <img src={bronzeCup} alt="" />
          </span>
          <span>{ranking[2] ? ranking[2].name : ''}</span>
          <span>{ranking[2] ? ranking[2].meta : ''}</span>
        </Typography>

      </Box>

      <Typography className={style.dateRanking}>data do ranking {date !== null && format(new Date(date), 'dd/MM/yyyy ')}</Typography>
    </>
  )
}

export default BoxRanking;
