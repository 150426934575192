import { apiMillennium } from '../../api'

const HandlingNow = async () => {
	const res = await apiMillennium.get('/faturados-agora')
	return res
}
const HandlingGoalBilling = async (metaFaturamento) => {
	const res = await apiMillennium.put('/update-faturados', { meta_faturamento: metaFaturamento })
	return res
}

export { HandlingNow, HandlingGoalBilling }