import { all, takeLatest, call, put } from 'redux-saga/effects';
import { signInSuccess, signFailure, signUpFailure, signUpSuccess, signInRequest } from './actions';
import { apiAttedance, apiEbook, apiLabs, apiManagerSku, apiMillennium } from '../../../Services/api';
import history from '../../../Services/history';
import { toast } from "react-toastify";

import { push } from 'connected-react-router';

export function* signIn({ payload }) {
  try {
    const { user, password } = payload;
    const res = yield call(apiLabs.post, 'appkey', {
      user,
      password,
    });
    const { token, id, menus, name } = res.data;
    yield put(signInSuccess(token, { id, menus, name } ));

    if(token) {
      apiLabs.defaults.headers.Authorization = `Bearer ${token}`
      apiMillennium.defaults.headers.Authorization = `Bearer ${token}`
      apiManagerSku.defaults.headers.Authorization = `Bearer ${token}`
      apiAttedance.defaults.headers.Authorization = `Bearer ${token}`
      apiEbook.defaults.headers.Authorization = `Bearer ${token}`
    }

    toast.success(`Seja bem vindo ${name}`)

    if(history.location.state !== undefined){
      return yield put(push(history.location.state.from.pathname))
    }

    yield put(push('/home'))

  } catch (err) {
    yield put(signFailure())
    toast.error('Usuário ou Senha inválido.')
  }
}

export function* signUp({ payload }) {
  try {
    const { user, email, password } = payload;
    const res = yield call(apiLabs.post, 'users', { user, email, password })

    const { id } = res.data

    if(id){
      yield put(signUpSuccess())
    }

     yield put(signInRequest(user, password))

  } catch (error) {
    yield put(signUpFailure())
  }
}

export function setToken({ payload }){
  if(!payload) return;
  const { token } = payload.auth;
  if(token) {
    apiLabs.defaults.headers.Authorization = `Bearer ${token}`
    apiMillennium.defaults.headers.Authorization = `Bearer ${token}`
    apiManagerSku.defaults.headers.Authorization = `Bearer ${token}`
    apiAttedance.defaults.headers.Authorization = `Bearer ${token}`
    apiEbook.defaults.headers.Authorization = `Bearer ${token}`
  }
}

export function signOut(){
  history.push('/')
}

export function signInExpired(){
  history.push('/')
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
  takeLatest('@auth/SIGN_IN_EXPIRED', signInExpired),
]);
