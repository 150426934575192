import React from 'react'

import Route from './Auth'
import { Redirect, Switch } from 'react-router-dom'

import Home from '../Pages/Home'
import Expedition from '../Pages/Expedition'
import ExpeditionV2 from '../Pages/ExpedtionV2'
import AbandonedCart from '../Pages/AbandonedCart'
import QuantatyOrders from '../Pages/QuantatyOrders'

import Menu from '../Pages/Menu'
import Users from '../Pages/Users'
import PageFormUser from '../Pages/Users/form'
import Login from '../Pages/Login'
import EggShellOrder from '../Pages/EggShellOrders'
import EggShellSeparate from '../Pages/EggShellSeparete'
import NewEggSeparateShell from '../Pages/NewEggSeparateShell'
import DashboardShells from '../Pages/Dashboards/Shells'
import ManagerSku from '../Pages/ManagerSkus'
import Ranking from '../Pages/Embalei/Ranking'
import Search from '../Pages/Embalei/Search'
import MetaEmbalei from '../Pages/Embalei/MetaEmbalei'
import Attendance from '../Pages/Attendance'
import Sac from '../Pages/Sac'
import SacV2 from '../Pages/SacV2'
import Ebook from '../Pages/Ebook'
import ListEbook from '../Pages/ListEbook'
import FullGoals from '../Pages/Dashboards/FullGoals'
import ConfigFullGoals from '../Pages/Settings/FullGoals'
import RetiraJa from '../Pages/RetiraJa'
import Telesales from '../Pages/Telesales'
import OffersNewspaper from '../Pages/OffersNewspaper'

const Routes = () => {
  return (
    <Switch>
      <Route exact path={'/home'} component={Home} isPrivate />
      <Route exact path={'/atendimento/novo'} idPage={16} component={Attendance} isPrivate />
      <Route exact path={'/ebook/novo'} idPage={20} component={Ebook} isPrivate />
      <Route exact path={'/ebook/listar'} idPage={21} component={ListEbook} isPrivate />
      <Route exact path={'/cascas/adicionar-cascas'} idPage={11} component={EggShellOrder} isPrivate />
      <Route exact path={'/cascas/separar-cascas'} idPage={10} component={NewEggSeparateShell} isPrivate />
      <Route exact path={'/cascas/separar-cascas-v2'} idPage={13} component={NewEggSeparateShell} isPrivate />
      <Route exact path={'/dashboard/expedicao'} idPage={1} component={Expedition} isPrivate />
      <Route exact path={'/dashboard/expedicao-v2'} idPage={15} component={ExpeditionV2} isPrivate />
      <Route exact path={'/dashboard/carrinho-abandonado'} idPage={8} isPrivate component={AbandonedCart} />
      <Route exact path={'/dashboard/quantidade-de-pedidos'} idPage={9} isPrivate component={QuantatyOrders} />
      <Route exact path={'/dashboard/sac'} idPage={2} isPrivate component={Sac} />
      <Route exact path={'/dashboard/sac-v2'} idPage={24} isPrivate component={SacV2} />

      <Route exact path={'/dashboard/metas-gerais'} idPage={22} isPrivate component={FullGoals} />
      <Route exact path={'/configuracao/metas-gerais'} idPage={23} isPrivate component={ConfigFullGoals} />

      <Route exact path={'/dashboard/cascas'} idPage={14} isPrivate component={DashboardShells} />
      <Route exact path={'/gerenciamento-sku'} idPage={12} isPrivate component={ManagerSku} />

      <Route exact path={'/embalei/pesquisa'} idPage={17} isPrivate component={Search} />
      <Route exact path={'/embalei/ranking'} idPage={18} isPrivate component={Ranking} />
      <Route exact path={'/embalei/meta'} idPage={19} isPrivate component={MetaEmbalei} />

      <Route exact path={'/configuracao/menu'} idPage={3} component={Menu} isPrivate />

      <Route exact path={'/configuracao/usuarios'} idPage={4} component={Users} isPrivate />
      <Route exact path={'/configuracao/usuarios/novo'} idPage={5} component={PageFormUser} isPrivate />
      <Route exact path={'/configuracao/usuarios/editar/:id'} idPage={6} component={PageFormUser} isPrivate />
      <Route exact path={'/retiraja'} idPage={26} component={RetiraJa} isPrivate />
      <Route exact path={'/dashboard/televendas'} idPage={27} component={Telesales} isPrivate />
      <Route exact path={'/jornal-ofertas'} idPage={28} component={OffersNewspaper} isPrivate />
      <Route exact path={'/'} component={Login} />

      <Redirect to='/' />
    </Switch>
  )
}

export default Routes
