import { makeStyles } from "@material-ui/core";

const stylePagesEmbalei = makeStyles((theme) => ({
  root: {},

  titlePage: {
    fontFamily: "",
    fontSize: 25,
    fontWeight: "bold",
    margin: "15px 0",
  },

  boxFilter: {
    "& .input-search": {
      width: 300,
    },

    "& > button": {
      marginTop: 17,
      marginLeft: 10,

      "&.no-margin": {
        marginTop: 0,
      },
    },
  },

  dateRanking: {},

  tableEmbalei: {
    width: "100%",
    border: "1px solid #ccc",

    "& th, td": {
      padding: 10,
      fontSize: 18,
      border: "1px solid #ccc",
    },
  },

  totalOrders: {
    fontSize: 18,
    paddingBottom: 10,
    fontWeight: "bold",
  },

  exportExcel: {
    background: "#388e3c",

    "&:hover": {
      background: "#388e3cdb",
    },
  },
}));

export default stylePagesEmbalei;
