import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme)=>({

    fullWidth: {
		backgroundColor:"#000",
		maxWidth: 1970,
		padding: 0,
    },
    btnFullscreen: {
		position: 'fixed',
		background: '#b01010',
		color: '#fff',
		right: 25,
		bottom: 25,

		'&:hover': {
			background: '#960d0d',
			color: '#fff',
		}
	},

	containerDash: {
		backgroundColor:"#000",
		height: '100vh',
		[theme.breakpoints.up('lg')]: {
			
		},
		
	},
  
	
	boxSquare: {
		[theme.breakpoints.up('md')]: {
			// height: 449,

			'& > div': {
				height: '100%',
			}
		},
		
	},
    box:{
	  display:'flex',
	  height: '100%',
	  justifyContent: 'center',
	  flexGrow: '1',

	  [theme.breakpoints.down('md')]: {
		flexDirection: 'column',
	},

	},
	boxOrder:{
		textAlign: 'center',	
	},
	Boxcard:{
		display: 'flex',
		justifyContent: 'space-between',
		alignItems:'center',
		flexGrow: '1',
		marginTop: '30px',

		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			height: '100%',
			background: '#000'
		},
	},
	cardBillet:{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent:'center',
		flexGrow: '1',
	},
	cards:{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent:'center',
		flexGrow: '1'
	},
    totals:{
        fontSize: '8vh',
		color:'#ffffff',
		lineHeight: '1',
		marginTop: '20px',
		
	},
	valueTotal:{
		fontSize: '5vh',
		color: '#ffffff'
	},
	totalsbillet:{
		fontSize: '7vh',
		color: '#ffffff'
	},
	totalcards:{
		fontSize: '12vh',
		color: "#ffffff"
	},
	valuecards:{
		fontSize: '5vh',
		color: '#ffffff'
	},
	paragraphy:{
		fontSize: '25vh',
		color:'#ffffff',

		[theme.breakpoints.down('sm')]: {
			fontSize: '15vh'
		},
	}
}))

export default useStyle