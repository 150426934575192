import { Box, Button, Container, Grid, Typography } from "@material-ui/core";
import TableToExcel from "@linways/table-to-excel";

import React, { useEffect, useState } from "react";
import Dashboard from "../../../Components/Dashboard";
import Denied from "../../../Components/Denied";
import { apiEmbalei, apiLabs } from "../../../Services/api";
import history from "../../../Services/history";
import { store } from "../../../store";
import stylePagesEmbalei from "../style";

import "date-fns";
import { ptBR } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { SubmitButton } from "../../../Components/Custom";
import format from "date-fns/format";
import { toast } from "react-toastify";

function Ranking(props) {
  const style = stylePagesEmbalei();

  const {
    location: { idPage },
  } = props;

  const [operator, setOperator] = useState([]);
  const [totalOrders, setTotalOrders] = useState("");

  const [date, setDate] = useState(new Date());

  const [auth, setAuth] = useState(null);

  async function verifyAuth() {
    const { profile } = store.getState().user;
    const res = await apiLabs.post("valid_key", {
      id: profile.id,
      menu_id: idPage,
    });
    if (res.data.message === "User not authorized") {
      return setAuth("denied");
    }
    if (res.data.message === "User authorized") {
      return setAuth("authorized");
    }
    return history.push({ pathname: "/", state: { from: props.location } });
  }

  const handleDate = (date) => {
    setDate(date);
  };

  async function getOrders() {
    try {
      console.log(format(new Date(date), "dd/MM/yyyy"));
      const res = await apiEmbalei.get(
        `ranking?data=${format(new Date(date), "dd/MM/yyyy")}`
      );
      const total = res.data.reduce(
        (accumulator, order) => accumulator + order.total_pedidos,
        0
      );
      setTotalOrders(total);
      setOperator(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    verifyAuth();
  }, []);

  function ExportToExcel() {
    const table = document.querySelector("[data-js='export-table-excel']");
    const tableBody = document.querySelector("[data-js='table-body-excel']");

    if (tableBody.children.length > 0) {
      TableToExcel.convert(table, {
        name: `raking-${format(new Date(date), "dd/MM/yyyy")}.xlsx`,
        sheet: {
          name: "Sheet 1",
        },
      });
      toast.success(`Dados exportados com sucesso!`, {
        autoClose: 5000,
      });
    } else {
      toast.warn(`Não há dados para exportar!`, {
        autoClose: 5000,
      });
    }
  }

  return (
    <Dashboard title="Ranking Embalei">
      {auth === "denied" && <Denied />}
      {auth === "authorized" && (
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid container alignContent="center" alignItems="flex-end" xs={12}>
              <Grid xs={10}>
                <Typography className={style.titlePage}>
                  Ranking Geral
                </Typography>

                <Box className={style.boxFilter}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <KeyboardDatePicker
                      className={style.dateRanking}
                      inputVariant="outlined"
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-ranking"
                      onChange={handleDate}
                      value={date}
                      label="Empacotados do dia"
                      invalidDateMessage="Data inválida"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>

                  <SubmitButton onClick={getOrders}>Filtrar</SubmitButton>
                </Box>
              </Grid>
              <Grid xs={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={ExportToExcel}
                  className={style.exportExcel}
                >
                  EXPORTAR EXCEL
                </Button>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography className={style.totalOrders}>
                Total de pedidos: {totalOrders}
              </Typography>
              <table
                className={style.tableEmbalei}
                border={0}
                cellSpacing={0}
                data-js="export-table-excel"
              >
                <thead>
                  <tr>
                    <th align="left">Colocação</th>
                    <th align="left">Pedidos</th>
                    <th align="left">Média</th>
                    <th align="left">Total de itens</th>
                    <th align="left">Operador</th>
                    <th align="left">Data</th>
                  </tr>
                </thead>

                <tbody data-js="table-body-excel">
                  {operator.map((o, i) => (
                    <tr key={`operator-${i}`}>
                      <td>{++i}º Lugar</td>
                      <td>{o.total_pedidos}</td>
                      <td>{o.meta}</td>
                      <td>{o.total_itens}</td>
                      <td>{o.name}</td>
                      <td>{o.beep_date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Grid>
          </Grid>
        </Container>
      )}
    </Dashboard>
  );
}

export default Ranking;
