import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({

    paper: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: 'rgba(0,0,0,0,0.5)',
        padding: theme.spacing(2, 4, 3),
        border: 'none',
        outline: 'none',
        borderRadius: '5px',
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
    },
    boxModalCancel: {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        flexDirection: 'column',
        width: '400px',
        height: '350px',
        background: '#fff',
        borderRadius: '5px',
    },
    modalContent: {
        height: '100%',
        maxWidth: '300px',
    },

    logoCancel: {
        width: '250px',
        margin: '0 auto',

        '& img': {
            width: '100%',
            display: 'block',
        }
    },
    content: {
        marginBottom: '50px',
        '& .MuiTypography-body1': {
            fontSize: '25px',
            fontWeight: 'bold',
            marginBottom: '5px',
        },

        '& .PrivateNotchedOutline-root-50': {
            border: 'none',
            boxShadow: '0px 0px 5px 0px',
            height: '70px',
        }
    },

    inputCancel: {
        width: '100%',
        height: '50px',
        fontSize: '20px',

        '& .MuiInputBase-root':{
            fontSize: '40px',
        },

        '& .PrivateNotchedOutline-root-53':{
            border: 'none',
            boxShadow: '0px 0px 7px -1px',
        }
    },

    containerButton: {
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",

        '& button': {
            color: '#fff',
            fontWeight: 'bold',
            margin: '10px'
        },


    },

    buttonBack: {
        background: '#e4d35e',
        padding: '10px 20px',

        '&.MuiButton-root:hover':{
            background: '#ab9f4d'
        }
    },

    buttonConfirmCancel: {
        background: '#b01010',
        padding: '10px 5px',

        '&.MuiButton-root:hover':{
            background: '#ff4444'
        }
    },

    fullWidth: {
        backgroundColor: "#fafafa",
        maxWidth: 1970,
        padding: 0,
    },

    btnFullscreen: {
        position: 'fixed',
        background: '#b01010',
        color: '#fff',
        right: 25,
        bottom: 25,

        '&:hover': {
            background: '#960d0d',
            color: '#fff',
        },

        '@media print': {
            display: 'none'
        }
    },

    containerDash: {
        backgroundColor: "#fafafa",
        height: '100vh',

        [theme.breakpoints.down('sm')]: {
            height: 0,
        }
    },

    container: {
        height: '90%',
        display: 'flex',
        justifyContent: 'center',
    },

    printOutButton: {
        position: 'fixed',
        width: '100%',
        height: '78px',
        fontSize: '18px',
        color: "#fff",
        bottom: 0,
        background: "#3fd46b",

        '&:hover': {
            background: '#3fd46b'
        },

        '@media print': {
            display: 'none'
        }
    },

    boxContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '1160px',
        margin: '10px',
    },

    noPrint: {
        display: 'none',

        '@media print': {
            display: 'block',
        },

    },

    renderPrint: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    logo: {
        width: '360px',
        position: 'relative',
        top: '-80px',
        margin: '-30px',
        '& img': {
            width: '100%',
            display: 'block'
        }
    },

    boxPdv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '60px',

        '& .MuiOutlinedInput-input': {
            height: '208px',
            maxWidth: '800px',
            fontSize: '200px',
            margin: '0 auto',
            textAlign: 'center',

            [theme.breakpoints.down('sm')]: {
                height: "150px",
                fontSize: '120px',
            }
        },

        '& .MuiOutlinedInput-root': {
            boxShadow: '0 5px 8px 5px #dadada',
        },

        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            marginBottom: '30px',
        }

    },

    numberPdv: {
        fontSize: '81.16px',
        marginRight: '32px',

        [theme.breakpoints.down('sm')]: {
            fontSize: "60.16px",
            marginRight: 0,
        }
    },

    boxOrder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '50px',

        '& ::after': {
            content: ':'
        },

        '& .MuiOutlinedInput-input': {
            height: '119px',
            maxWidth: '572px',
            fontSize: '142.65px',
            margin: '0 auto',
            textAlign: 'center',

            [theme.breakpoints.down('sm')]: {
                height: "114px",
                fontSize: '75px',
            }
        },

        '& .MuiOutlinedInput-root': {
            boxShadow: '0 5px 8px 5px #dadada',

            '@media print': {
                boxShadow: 'none'
            }
        },

        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        },

        [theme.breakpoints.down('sm')]: {
            flexDirection: "column"
        }
    },

    numberOrder: {
        fontSize: '60.57px',
        marginRight: '22px',

        [theme.breakpoints.down('sm')]: {
            fontSize: '40.57px',
        }
    },

    buttonCancel: {
        display: 'flex',
        justifyContent: 'flex-end',

        '& button': {
            padding: '10px 5px',
            background: '#e75656',
            color: '#fff',
            fontWeight: 'bold',
        },
        '& :hover': {
            background: '#ca2d2d',
        }
    }
}))

export default useStyle